import React from 'react'
import { Box } from '@mui/material'

import Map from 'components/Map'
import MapSider from './MapSider'
import useIsMobile from 'hooks/useIsMobile'
import useIsTab from 'hooks/useIsTab'

const MapDashboard = ({
  children,
  className,
  showSider = true,
  showControls = true,

  geoJson,
  isMapLoading = false,
  CogList = [],
  rasterList = [],
  initialBounds
}) => {

  const isMobile = useIsMobile()
  const isTab = useIsTab()

  return (
    <Box className={`w-full h-full relative overflow-hidden ${className}`}>

      {
        showSider ? (
          <>
            {isMobile || isTab ?
              (
                <MapSider className={`lg:hidden w-full overflow-scroll h-full absolute`}>
                  {children}
                </MapSider>
              ) : (
                <MapSider className={`hidden lg:block h-full absolute`}>
                  {children}
                </MapSider>
              )
            }
          </>
        ) : null
      }

      <Box className="w-full h-full">
        <Map
          showControls={showControls}
          geoJson={geoJson}
          isLoading={isMapLoading}
          CogList={CogList}
          rasterList={rasterList}
          initialBounds={initialBounds}
        />
      </Box>
    </Box>
  )
}

export default MapDashboard