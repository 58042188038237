import { Box } from '@mui/material';
import DraggableList from 'components/DraggableList';
import { selectEventsAddedToMap } from 'components/MapDashboard/Events/eventSlice';
import React, { useRef } from 'react'
import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';

const Legend = ({ legendData }) => {
  const map = useMap();
  const legendRef = useRef()

  const eventsAddedToMap = useSelector(selectEventsAddedToMap)

  legendRef.current?.addEventListener('mouseout', (e) => {
    map._handlers.forEach(function (handler) {
      handler.enable();
    });
  })
  legendRef.current?.addEventListener('mouseover', (e) => {
    map._handlers.forEach(function (handler) {
      handler.disable();
    });
  })

  return (
    <Box ref={legendRef} className='absolute right-6 bottom-6 z-[600] flex flex-col space-y-2 bg-white'>
      {eventsAddedToMap ? <DraggableList eventsAddedToMap={eventsAddedToMap} /> : null}
      {
        legendData ? (
          <Box className="p-2">
            <p><strong>Location :</strong> {legendData?.block_name}</p>
            <p><strong>Area(in Ha) :</strong> {legendData?.area}</p>
            <p><strong>Min :</strong> {legendData?.min}</p>
            <p><strong>Max :</strong> {legendData?.max}</p>
            <p><strong>Mean :</strong> {legendData?.mean}</p>
          </Box>
        ) : null
      }
    </Box>
  )
}

export default Legend