import React, { useRef } from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { useTrail, animated } from "@react-spring/web";
import MapImg from "assets/images/bg/pi-bg1.jpg";
import AcidRain from "assets/images/icon/Acid-rain-light.gif";
import Environment from "assets/images/icon/Environment.gif";
import EnvironmentalAudit from "assets/images/icon/Environmental-audit-light-1.gif";
import LossBiodiversity from "assets/images/icon/Loss-of-biodiversity-light.gif";
const FeaturedProduct = () => {
  return (
    <div className="featuredSection  relative w-full bg-[#f2f2f2] pt-[80px] pb-[80px]">
      <div className="container VerticalSlide ">
        <div className="bg-[#fff] text-black py-8">
          <div className="mx-auto flex flex-col items-start md:flex-row my-12 md:my-18">
            <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8">
              <p className="text-black text-1xl text-bold uppercase tracking-loose">
                Events – Use Cases
              </p>
              <p className="text-3xl md:text-3xl mb-2">
                Scalable AI solutions a top location data
              </p>
              <p className="text-sm md:text-base text-gray-600 mb-4">
                Go through all the steps to know the exact process of the fest.
              </p>
              <img className="" src={MapImg} />

              <a
                href="#"
                className="mt-10 bg-transparent mr-auto hover:bg-green-300 text-black hover:text-black rounded shadow hover:shadow-lg py-2 px-4 border border-green-300 hover:border-transparent"
              >
                Explore Now
              </a>
            </div>
            <div className="ml-0 md:ml-12 lg:w-2/3 sticky">
              <div className=" mx-auto w-full h-full">
                <div className="relative wrap overflow-hidden p-10 h-full">
                  {/* <div
                    className="border-2-2 border-geen-555 absolute h-full border"
                    style={{
                      right: "50%",
                      border: "2px double #fff",
                      borderRadius: "1%",
                    }}
                  ></div> */}
                  <div
                    className="border-2-2 absolute h-full"
                    style={{
                      right: "50%",
                      border: "1px double #fff",
                      borderRadius: "1%",
                    }}
                  ></div>
                  <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div className="order-1 w-5/12">
                      {" "}
                      <img className="" src={Environment} />
                    </div>
                    <div className="order-1 w-5/12 px-1 py-4 text-left">
                      <h4 className="mb-3 font-bold text-lg md:text-2xl text-black">
                        Climate Smart Events
                      </h4>
                      <ul className="text-[#888] list-disc">
                        <li> Scores – Performance Metrics</li>
                        <li> Yield Ecosystem Analytics</li>
                        <li> Price Forecasting</li>
                        <li> Food Security – Supply & Demand Analytics</li>
                      </ul>
                    </div>
                  </div>
                  <div className="mb-8 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12">
                      {" "}
                      <img className="" src={EnvironmentalAudit} />
                    </div>
                    <div className="order-1  w-5/12 px-1 py-4 text-left">
                      <h4 className="mb-3 font-bold text-lg md:text-2xl text-black">
                        Risk Intelligence – Climate & Disaster Tech
                      </h4>
                      <div className="flex ">
                        <ul className="w-[50%] text-[#888] list-disc">
                          <b className="underline decoration-green-500 text-black">
                            Disasters
                          </b>
                          <li>Natural</li>
                          <li>Ecological</li>
                          <li>Biological</li>
                          <li>Multi-Hazard</li>
                        </ul>
                        <ul className="w-[50%] text-[#888] list-disc">
                          <b className="underline decoration-green-500 text-black">
                            Analytics
                          </b>
                          <li>Risk Management Analytics</li>
                          <li>Causal Analytics</li>
                          <li>Biological</li>
                          <li>Forecast & Prediction</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div className="order-1 w-5/12">
                      <img className="" src={LossBiodiversity} />
                    </div>
                    <div className="order-1 w-5/12 px-1 py-4 text-left">
                      <h4 className="mb-3 font-bold text-lg md:text-2xl text-black">
                        Environment & Ecology
                      </h4>
                      <ul className="text-[#888] list-disc">
                        <li>Disturbance Analytics – Biodiversity & Forest</li>
                        <li>Resilience Analytics</li>
                        <li>Land System Analytics</li>
                        <li>
                          Carbon Footprint, Sequestration & Carbon Credits
                        </li>
                        <li>Mitigation Analytics</li>
                      </ul>
                      <ul>
                        <li></li>
                      </ul>
                    </div>
                  </div>

                  <div className="mb-8 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12">
                      <img className="" src={AcidRain} />
                    </div>

                    <div className="order-1  w-5/12 px-1 py-4">
                      <h4 className="mb-3 font-bold  text-lg md:text-2xl text-left text-black">
                        Sustainability
                      </h4>
                      <ul className="text-[#888] list-disc">
                        <li>Location Analytics</li>
                        <li>Demographic Analytics</li>
                        <li>
                          Sustainability Analytics – Environment, Economy &
                          Society
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProduct;
