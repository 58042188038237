import TasksList from 'components/Task/TasksList'
import PageLayout from 'layout/PageLayout'
import React from 'react'

const Task = () => {

  const menuItems = [
    {
      label: "Tasks",
      key: "tasks",
    }
  ]
  const breadcrumbs = [
    {
      title: 'Dashboard',
      href: '/dashboard'
    },
    {
      title: 'Data Pipeline',
      href: '/data-pipeline'
    },
    {
      title: 'Tasks',
      href: '/data-pipeline/tasks'
    }
  ]

  return (
    <PageLayout
      pageName='Data Pipeline'
      pagePath={`/data-pipeline/tasks`}
      menuItems={menuItems}
      breadCrumbs={breadcrumbs}
      activeMenuItem={"tasks"}
      showSideMenu={true}
    >
      <TasksList />
    </PageLayout>
  )
}

export default Task