import { Box, ClickAwayListener, Divider, IconButton, ListItem, ListItemButton, ListItemText, Paper, Typography, Zoom } from "@mui/material";
import Close from '@mui/icons-material/Close';
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import { useEffect, useState } from "react";
import { List, Switch } from "antd";
import { CompareRounded } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectCogList } from "components/PixStackJourney/pixStackJourneySlice";



const SideBySideControl = ({ baseLayer, leftLayer = null, setLeftLayer = () => { }, rightLayer = null, setRightLayer = () => { } }) => {

  const [layerOpen, setLayerOpen] = useState(false);

  const layers = useSelector(selectCogList)

  return (
    < ClickAwayListener onClickAway={() => setLayerOpen(false)} >
      <Box className="w-full flex justify-end">
        <Zoom className="absolute right-12" in={layerOpen} mountOnEnter unmountOnExit>
          <div className='mr-2'>
            <div className="bg-white rounded flex p-2">
              <List className='w-[250px] max-h-[300px] overflow-auto rounded' sx={{ bgcolor: 'background.paper', }}>
                <div>Left</div>
                {[{...baseLayer, name: `Base Layer (${baseLayer.name})`, rasterLayerUrl: baseLayer.url}, ...layers].map((item, index) => {
                  return (
                    <ListItem
                      key={index}
                      onClick={() => { }}
                      className='!p-1 !px-3 hover:cursor-pointer hover:bg-gray-100 border-b space-x-4 flex justify-center'
                    >
                      <Switch
                        size="small"
                        className="bg-gray-600"
                        checked={item.name === leftLayer?.name}
                        onChange={(e) => e ? setLeftLayer({...item, opacity: 2}) : setLeftLayer(null)}
                      />
                      <ListItemText
                        secondary={
                          <Typography
                            sx={{ display: 'inline', fontSize: "small" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {item.name}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )
                })}
              </List>
              <Divider flexItem orientation="vertical" className="!mx-2" />
              <List className='w-[250px] max-h-[300px] overflow-auto rounded' sx={{ bgcolor: 'background.paper', }}>
                <div>Right</div>
                {[{...baseLayer, name: `Base Layer (${baseLayer.name})`, rasterLayerUrl: baseLayer.url}, ...layers].map((item, index) => {
                  return (
                    <ListItem
                      key={index}
                      onClick={() => { }}
                      className='!p-1 !px-3 hover:cursor-pointer hover:bg-gray-100 border-b space-x-4 flex justify-center'
                    >
                      <Switch
                        size="small"
                        className="bg-gray-600"
                        checked={item.name === rightLayer?.name}
                        onChange={(e) => e ? setRightLayer({...item, opacity: 2}) : setRightLayer(null)}
                      />
                      <ListItemText
                        secondary={
                          <Typography
                            sx={{ display: 'inline', fontSize: "small" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {item.name}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )
                })}
              </List>
            </div>
          </div>
        </Zoom>

        <Paper className='flex flex-col !h-fit !w-fit !rounded-sm'>
          <IconButton onClick={() => setLayerOpen((prev) => !prev)} color="inherit" centerRipple={false} className="!rounded-sm">
            {layerOpen ? <Close className='!text-[16px]' /> : <CompareRounded className='!text-[16px]' />}
          </IconButton>
        </Paper>
      </Box>
    </ClickAwayListener>
  )
}

export default SideBySideControl