import { useState } from 'react'
import Select from 'react-select'
import { Box, Button } from '@mui/material'
import { TravelExploreRounded } from '@mui/icons-material'

import MapDashboard from 'components/MapDashboard'
import LocationImg from 'assets/images/location.jpg'
import EventList from 'components/MapDashboard/Events/EventList'


const ClimateSmartEvents = () => {

  const [selectedLocation, setSelectedLocation] = useState({
    stateName: "",
    stateCode: "",
    districtName: "",
    districtCode: ""
  })
  const [isLocationSelected, setIsLocationSelected] = useState(false)
  const [identifiedEvents, setIdentifiedEvents] = useState([])

  const handleLocationOnSubmit = () => {

    const page = 1
    const perPage = 10
    const API_URL = `https://api.resourcewatch.org/v1/dataset?env=production&application=rw&language=en&includes=layer,metadata,vocabulary,widget&sort=-createdAt&status=saved&published=true&page[number]=${page}&page[size]=${perPage}`
    fetch(API_URL).then((res) => {
      res.json().then((response) => {
        const eventsData = response.data.map((item) => {
          return {
            name: item.attributes.name,
            date: item.attributes?.createdAt,
            thumbnailUrl: item.attributes?.layer[0]?.attributes.thumbnailUrl
          }
        })
        setIdentifiedEvents(eventsData)
      })
    })
    setIsLocationSelected(!isLocationSelected)
  }

  return (
    <MapDashboard>
      <Box className="h-full overflow-hidden bg-gray-50">
        <Box className={`${isLocationSelected ? "-top-[5%]" : "top-0"} relative w-full h-full flex flex-col justify-between items-center p-8 space-y-[8%] !transition-all !duration-700 !ease-out`}>
          <Box className='w-full flex flex-col justify-center items-center space-y-[8%]'>

            <Box className="flex justify-center">
              <img src={LocationImg} className={`${isLocationSelected ? "w-0" : "w-full"} mix-blend-multiply !transition-all !duration-700 !ease-out`} />
            </Box>

            <Box className='w-full flex flex-col space-y-[6%]'>
              <Box className='w-full flex space-x-[4%] justify-between'>
                <Select
                  className="bg-gray-50 rounded w-[50%] lg:w-40"
                  onChange={() => { }}
                  options={[{ label: 'UP', value: '9' }]}
                  styles={{
                    control: (base) => ({
                      ...base,
                      boxShadow: "none",
                      borderColor: "#cccccc",
                      "&:hover": {
                        borderColor: "#cccccc"
                      }
                    })
                  }}
                  placeholder="State"
                  isSearchable={true}
                />
                <Select
                  className="bg-gray-50 rounded w-[50%] lg:w-40"
                  onChange={() => { }}
                  options={[]}
                  styles={{
                    control: (base) => ({
                      ...base,
                      boxShadow: "none",
                      borderColor: "#cccccc",
                      "&:hover": {
                        borderColor: "#cccccc"
                      }
                    })
                  }}
                  placeholder="District"
                  isSearchable={true}
                />
              </Box>
              <Button
                disableElevation
                variant='contained'
                size='large'
                onClick={handleLocationOnSubmit}
                className='!px-6 !py-2.5 '
              >
                Identify Events &nbsp; &nbsp; <TravelExploreRounded fontSize='small' />
              </Button>
            </Box>

          </Box>
          <Box>
            <p className='text-gray-500 text-center italic tracking-wide'>
              Select a district to identify Climate Smart Events
            </p>
          </Box>
        </Box>
        <Box className={`${isLocationSelected ? "-top-[78%]" : "top-[30rem]"} h-full overflow-auto relative rounded mx-3 transition-all !duration-700 !ease-out`}>
          <EventList className={"h-[77%]"} events={identifiedEvents} />
        </Box>
      </Box>
    </MapDashboard>
  )
}

export default ClimateSmartEvents