import { createSlice } from "@reduxjs/toolkit";
import storePersist from "services/redux/storePersist";

const initialState = {
  menuOpen: true
}
const persistedState = storePersist.get('reduxState')

const MapDashboardSlice = createSlice({
  name: "MapDashboard",
  initialState: { ...initialState, ...persistedState?.MapDashboard },

  reducers: {
    toggleMenu: (state) => {
      state.menuOpen = !state.menuOpen
    },
    openMenu: (state) => {
      state.menuOpen = true
    },
    closeMenu: (state) => {
      state.menuOpen = false
    }
  }
})

export const { toggleMenu, openMenu, closeMenu } = MapDashboardSlice.actions

export const selectMenuOpen = (state) => state.MapDashboard?.menuOpen

export default MapDashboardSlice