


const CustomColorConfig = () => {

  const colors = {
    colors: {
      'primary': {
        '50': '#fcf3fa',
        '100': '#fbe8f8',
        '200': '#f8d2f1',
        '300': '#f4ade6',
        '400': '#ec7ad2',
        '500': '#e252bd',
        '600': '#cf309e',
        '700': '#b32383',
        '800': '#94206c',
        '900': '#7c1f5b',
        '950': '#4b0c35',
      },
      'secondary': {
        '50': '#fffbeb',
        '100': '#fef3c7',
        '200': '#fde58a',
        '300': '#fbd24e',
        '400': '#fabe25',
        '500': '#f49d0c',
        '600': '#d87607',
        '700': '#bc560a',
        '800': '#923f0e',
        '900': '#78340f',
        '950': '#451a03',
    },
    
    }
  }
  return colors
}

export default CustomColorConfig()