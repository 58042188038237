import { createSlice } from "@reduxjs/toolkit";
import storePersist from "services/redux/storePersist";
import dayjs from 'dayjs';

const sections = [
  {
    id: 0,
    title: "Overview"
  },
  {
    id: 1,
    title: "SpaceTime Dynamics - Trend"
  },
  {
    id: 2,
    title: "SpaceTime Dynamics - Risk Intensity"
  },
  {
    id: 3,
    title: "Hazard Score"
  },
  {
    id: 4,
    title: "Sustainability Score"
  }
]
const demoData = {
  "sections": [
    {
      "id": 0,
      "title": "Overview"
    },
    {
      "id": 1,
      "title": "SpaceTime Dynamics - Trend"
    },
    {
      "id": 2,
      "title": "SpaceTime Dynamics - Risk Intensity"
    },
    {
      "id": 3,
      "title": "Hazard Score"
    },
    {
      "id": 4,
      "title": "Sustainability Score"
    }
  ],
  "currentSection": {
    "id": 1,
    "title": "SpaceTime Dynamics - Trend"
  },
  "JourneyData": {
    "data": {
      "current_status": "Completed",
      "event_intensity": [
        {
          "cog_status": true,
          "opacity": 100,
          "layer_name": "High",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/high_intensity.tif",
          "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Fhigh_intensity.tif&rescale=0%2C0&colormap_name=magma",
          "bounds": [
            [
              27.222889934810176,
              95.21717756465193
            ],
            [
              27.97151057994381,
              96.0179076899002
            ]
          ],
          "stats": {
            "b1": {
              "min": null,
              "max": null,
              "mean": null,
              "count": 0,
              "sum": null,
              "std": null,
              "median": null,
              "majority": null,
              "minority": null,
              "unique": 0,
              "histogram": [
                [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
                ],
                [
                  0,
                  0.10000000149011612,
                  0.20000000298023224,
                  0.30000001192092896,
                  0.4000000059604645,
                  0.5,
                  0.6000000238418579,
                  0.699999988079071,
                  0.800000011920929,
                  0.8999999761581421,
                  1
                ]
              ],
              "valid_percent": 0,
              "masked_pixels": 996352,
              "valid_pixels": 0,
              "percentile_2": null,
              "percentile_98": null
            }
          }
        },
        {
          "cog_status": true,
          "opacity": 100,
          "layer_name": "Moderate",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/medium_intensity.tif",
          "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Fmedium_intensity.tif&rescale=0%2C0.002755632856860757&colormap_name=magma",
          "bounds": [
            [
              27.222889934810176,
              95.21717756465193
            ],
            [
              27.97151057994381,
              96.0179076899002
            ]
          ],
          "stats": {
            "b1": {
              "min": 0,
              "max": 0.002755632856860757,
              "mean": 0.0005589149425515098,
              "count": 556557,
              "sum": 311.0680236816406,
              "std": 0.0009590248587740735,
              "median": 0,
              "majority": 0,
              "minority": 0.002755632856860757,
              "unique": 4,
              "histogram": [
                [
                  387346,
                  0,
                  53194,
                  0,
                  0,
                  0,
                  0,
                  0,
                  96493,
                  19524
                ],
                [
                  0,
                  0.0002755632740445435,
                  0.000551126548089087,
                  0.0008266898803412914,
                  0.001102253096178174,
                  0.0013778164284303784,
                  0.0016533797606825829,
                  0.0019289429765194654,
                  0.002204506192356348,
                  0.0024800696410238743,
                  0.002755632856860757
                ]
              ],
              "valid_percent": 55.86,
              "masked_pixels": 439795,
              "valid_pixels": 556557,
              "percentile_2": 0,
              "percentile_98": 0.002755632856860757
            }
          }
        },
        {
          "cog_status": true,
          "opacity": 100,
          "layer_name": "Low",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/low_intensity.tif",
          "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Flow_intensity.tif&rescale=0.9972443580627441%2C1&colormap_name=magma",
          "bounds": [
            [
              27.222889934810176,
              95.21717756465193
            ],
            [
              27.97151057994381,
              96.0179076899002
            ]
          ],
          "stats": {
            "b1": {
              "min": 0.9972443580627441,
              "max": 1,
              "mean": 0.9994410949821851,
              "count": 556557,
              "sum": 556245.9375,
              "std": 0.0009590168018410333,
              "median": 1,
              "majority": 1,
              "minority": 0.9972443580627441,
              "unique": 4,
              "histogram": [
                [
                  19524,
                  96493,
                  0,
                  0,
                  0,
                  0,
                  0,
                  53194,
                  0,
                  387346
                ],
                [
                  0.9972443580627441,
                  0.9975199103355408,
                  0.9977954626083374,
                  0.9980710744857788,
                  0.9983466267585754,
                  0.9986221790313721,
                  0.9988977313041687,
                  0.9991732835769653,
                  0.9994488954544067,
                  0.9997244477272034,
                  1
                ]
              ],
              "valid_percent": 55.86,
              "masked_pixels": 439795,
              "valid_pixels": 556557,
              "percentile_2": 0.9972443580627441,
              "percentile_98": 1
            }
          }
        }
      ],
      "impact_analysis": [
        {
          "average_score": -0.08833976686851872,
          "cog_status": true,
          "opacity": 100,
          "layer_name": "Moderate",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/medium_impact.tif",
          "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Fmedium_impact.tif&rescale=-0.8235985040664673%2C0.9983344674110413&colormap_name=ylorrd",
          "bounds": [
            [
              27.222889934810176,
              95.21717756465193
            ],
            [
              27.97151057994381,
              96.0179076899002
            ]
          ],
          "stats": {
            "b1": {
              "min": -0.8235985040664673,
              "max": 0.9983344674110413,
              "mean": -0.0881784799617869,
              "count": 556441,
              "sum": -49066.12156841667,
              "std": 0.21434974865613513,
              "median": -0.014436958357691765,
              "majority": 0,
              "minority": -0.8235985040664673,
              "unique": 357596,
              "histogram": [
                [
                  13137,
                  43750,
                  35228,
                  55833,
                  382228,
                  15305,
                  3639,
                  7094,
                  214,
                  13
                ],
                [
                  -0.8235985040664673,
                  -0.6414052069187164,
                  -0.4592119097709656,
                  -0.2770186126232148,
                  -0.09482531547546391,
                  0.08736798167228699,
                  0.26956127882003766,
                  0.45175457596778856,
                  0.6339478731155395,
                  0.8161411702632904,
                  0.9983344674110413
                ]
              ],
              "valid_percent": 55.85,
              "masked_pixels": 439911,
              "valid_pixels": 556441,
              "percentile_2": -0.6521858215332031,
              "percentile_98": 0.26068735122680664
            }
          }
        },
        {
          "average_score": 0.9105422972132468,
          "cog_status": true,
          "opacity": 100,
          "layer_name": "Low",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/low_impact.tif",
          "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Flow_impact.tif&rescale=0.17511366307735443%2C1.9970465898513794&colormap_name=ylorrd",
          "bounds": [
            [
              27.222889934810176,
              95.21717756465193
            ],
            [
              27.97151057994381,
              96.0179076899002
            ]
          ],
          "stats": {
            "b1": {
              "min": 0.17511366307735443,
              "max": 1.9970465898513794,
              "mean": 0.9107039186708668,
              "count": 556441,
              "sum": 506752.99920913577,
              "std": 0.2141089766073343,
              "median": 0.9850084781646729,
              "majority": 1,
              "minority": 0.17511366307735443,
              "unique": 350914,
              "histogram": [
                [
                  13054,
                  43695,
                  35231,
                  55461,
                  383332,
                  14695,
                  3618,
                  7116,
                  226,
                  13
                ],
                [
                  0.17511366307735443,
                  0.3573069557547569,
                  0.5395002484321594,
                  0.721693541109562,
                  0.9038868337869644,
                  1.086080126464367,
                  1.2682734191417695,
                  1.4504667118191719,
                  1.6326600044965744,
                  1.814853297173977,
                  1.9970465898513794
                ]
              ],
              "valid_percent": 55.85,
              "masked_pixels": 439911,
              "valid_pixels": 556441,
              "percentile_2": 0.34673474431037904,
              "percentile_98": 1.2605975389480575
            }
          }
        },
        {
          "average_score": 0,
          "cog_status": true,
          "opacity": 100,
          "layer_name": "High",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/high_impact.tif",
          "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Fhigh_impact.tif&rescale=0%2C0&colormap_name=ylorrd",
          "bounds": [
            [
              27.222889934810176,
              95.21717756465193
            ],
            [
              27.97151057994381,
              96.0179076899002
            ]
          ],
          "stats": {
            "b1": {
              "min": null,
              "max": null,
              "mean": null,
              "count": 0,
              "sum": null,
              "std": null,
              "median": null,
              "majority": null,
              "minority": null,
              "unique": 0,
              "histogram": [
                [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
                ],
                [
                  0,
                  0.1,
                  0.2,
                  0.30000000000000004,
                  0.4,
                  0.5,
                  0.6000000000000001,
                  0.7000000000000001,
                  0.8,
                  0.9,
                  1
                ]
              ],
              "valid_percent": 0,
              "masked_pixels": 996352,
              "valid_pixels": 0,
              "percentile_2": null,
              "percentile_98": null
            }
          }
        }
      ],
      "sustainability": [
        {
          "average_score": 0,
          "cog_status": true,
          "opacity": 100,
          "layer_name": "High",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/sustain_high.tif",
          "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Fsustain_high.tif&rescale=0%2C0&colormap_name=rdylgn",
          "bounds": [
            [
              27.222889934810176,
              95.21717756465193
            ],
            [
              27.97151057994381,
              96.0179076899002
            ]
          ],
          "stats": {
            "b1": {
              "min": null,
              "max": null,
              "mean": null,
              "count": 0,
              "sum": null,
              "std": null,
              "median": null,
              "majority": null,
              "minority": null,
              "unique": 0,
              "histogram": [
                [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
                ],
                [
                  0,
                  0.1,
                  0.2,
                  0.30000000000000004,
                  0.4,
                  0.5,
                  0.6000000000000001,
                  0.7000000000000001,
                  0.8,
                  0.9,
                  1
                ]
              ],
              "valid_percent": 0,
              "masked_pixels": 996352,
              "valid_pixels": 0,
              "percentile_2": null,
              "percentile_98": null
            }
          }
        },
        {
          "average_score": 0.06064820573175418,
          "cog_status": true,
          "opacity": 100,
          "layer_name": "Low",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/sustain_low.tif",
          "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Fsustain_low.tif&rescale=-1.5505621433258057%2C1.3410545587539673&colormap_name=rdylgn",
          "bounds": [
            [
              27.222889934810176,
              95.21717756465193
            ],
            [
              27.97151057994381,
              96.0179076899002
            ]
          ],
          "stats": {
            "b1": {
              "min": -1.5505621433258057,
              "max": 1.3410545587539673,
              "mean": 0.06044570962997569,
              "count": 556441,
              "sum": 33634.4711122133,
              "std": 0.2635473050563289,
              "median": 0.005498200189322233,
              "majority": -0.11808021366596222,
              "minority": -1.5505621433258057,
              "unique": 550627,
              "histogram": [
                [
                  186,
                  1241,
                  2045,
                  10693,
                  88547,
                  331048,
                  68713,
                  48845,
                  5082,
                  41
                ],
                [
                  -1.5505621433258057,
                  -1.2614004731178283,
                  -0.9722388029098511,
                  -0.6830771327018739,
                  -0.39391546249389653,
                  -0.10475379228591919,
                  0.18440787792205793,
                  0.4735695481300355,
                  0.7627312183380126,
                  1.0518928885459897,
                  1.3410545587539673
                ]
              ],
              "valid_percent": 55.85,
              "masked_pixels": 439911,
              "valid_pixels": 556441,
              "percentile_2": -0.46000159978866567,
              "percentile_98": 0.7140289783477778
            }
          }
        },
        {
          "average_score": 1.0595302698135138,
          "cog_status": true,
          "opacity": 100,
          "layer_name": "Moderate",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/sustain_medium.tif",
          "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Fsustain_medium.tif&rescale=-0.5505621433258057%2C2.341054677963257&colormap_name=rdylgn",
          "bounds": [
            [
              27.222889934810176,
              95.21717756465193
            ],
            [
              27.97151057994381,
              96.0179076899002
            ]
          ],
          "stats": {
            "b1": {
              "min": -0.5505621433258057,
              "max": 2.341054677963257,
              "mean": 1.059328108061076,
              "count": 556441,
              "sum": 589453.5917776132,
              "std": 0.2637600774977925,
              "median": 1.00443434715271,
              "majority": 0.9427593946456909,
              "minority": -0.5505621433258057,
              "unique": 532083,
              "histogram": [
                [
                  186,
                  1242,
                  2044,
                  10723,
                  90505,
                  329254,
                  68621,
                  48827,
                  4999,
                  40
                ],
                [
                  -0.5505621433258057,
                  -0.2614004611968994,
                  0.027761220932006836,
                  0.3169229030609131,
                  0.6060845851898193,
                  0.8952462673187256,
                  1.1844079494476318,
                  1.473569631576538,
                  1.7627313137054443,
                  2.0518929958343506,
                  2.341054677963257
                ]
              ],
              "valid_percent": 55.85,
              "masked_pixels": 439911,
              "valid_pixels": 556441,
              "percentile_2": 0.5396613478660583,
              "percentile_98": 1.7131042718887328
            }
          }
        }
      ],
      "task_id": "4f48be59-b7cc-4639-9da8-a319b5150db6",
      "trend_analysis": {
        "max_data": [
          {
            "cog_status": true,
            "opacity": 100,
            "layer_name": "First Scenario",
            "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/rainfall_max_1.tif",
            "max_info": {
              "max": 188.27000427246094,
              "mean": 129.8311767578125,
              "min": 101.55999755859375
            },
            "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Frainfall_max_1.tif&rescale=101.55999755859375%2C188.27000427246094&colormap_name=magma",
            "bounds": [
              [
                27.249991085073376,
                95.24999999998438
              ],
              [
                27.950000000003527,
                95.95007045280211
              ]
            ],
            "stats": {
              "b1": {
                "min": 101.55999755859375,
                "max": 188.27000427246094,
                "mean": 124.51370039701266,
                "count": 635748,
                "sum": 79159336,
                "std": 29.351438745927492,
                "median": 107.37999725341797,
                "majority": 107.37999725341797,
                "minority": 130.3800048828125,
                "unique": 8,
                "histogram": [
                  [
                    375164,
                    57245,
                    0,
                    37804,
                    48223,
                    0,
                    0,
                    38218,
                    0,
                    79094
                  ],
                  [
                    101.55999755859375,
                    110.23099517822266,
                    118.9020004272461,
                    127.572998046875,
                    136.24400329589844,
                    144.91500854492188,
                    153.58599853515625,
                    162.2570037841797,
                    170.92800903320312,
                    179.5989990234375,
                    188.27000427246094
                  ]
                ],
                "valid_percent": 68.3,
                "masked_pixels": 295068,
                "valid_pixels": 635748,
                "percentile_2": 101.55999755859375,
                "percentile_98": 188.27000427246094
              }
            }
          },
          {
            "cog_status": true,
            "opacity": 100,
            "layer_name": "Second Scenario",
            "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/rainfall_max_2.tif",
            "max_info": {
              "max": 110.83999633789062,
              "mean": 98.44207000732422,
              "min": 79.5
            },
            "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Frainfall_max_2.tif&rescale=79.5%2C110.83999633789062&colormap_name=magma",
            "bounds": [
              [
                27.249991085073376,
                95.24999999998438
              ],
              [
                27.950000000003527,
                95.95007045280211
              ]
            ],
            "stats": {
              "b1": {
                "min": 79.5,
                "max": 110.83999633789062,
                "mean": 96.23002195838603,
                "count": 635748,
                "sum": 61178044,
                "std": 8.984254081351093,
                "median": 94.36000061035156,
                "majority": 88.73999786376953,
                "minority": 94.54000091552734,
                "unique": 8,
                "histogram": [
                  [
                    48223,
                    0,
                    189605,
                    0,
                    135695,
                    0,
                    0,
                    166762,
                    0,
                    95463
                  ],
                  [
                    79.5,
                    82.63400268554688,
                    85.76799774169922,
                    88.9020004272461,
                    92.03599548339844,
                    95.16999816894531,
                    98.30400085449219,
                    101.43799591064453,
                    104.5719985961914,
                    107.70599365234375,
                    110.83999633789062
                  ]
                ],
                "valid_percent": 68.3,
                "masked_pixels": 295068,
                "valid_pixels": 635748,
                "percentile_2": 79.5,
                "percentile_98": 110.83999633789062
              }
            }
          },
          {
            "cog_status": true,
            "opacity": 100,
            "layer_name": "Third Scenario",
            "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/rainfall_max_3.tif",
            "max_info": {
              "max": 99.12999725341797,
              "mean": 85.44058990478516,
              "min": 72.31999969482422
            },
            "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Frainfall_max_3.tif&rescale=72.31999969482422%2C99.12999725341797&colormap_name=magma",
            "bounds": [
              [
                27.249991085073376,
                95.24999999998438
              ],
              [
                27.950000000003527,
                95.95007045280211
              ]
            ],
            "stats": {
              "b1": {
                "min": 72.31999969482422,
                "max": 99.12999725341797,
                "mean": 84.15647080289675,
                "count": 635748,
                "sum": 53502308,
                "std": 10.143228395142081,
                "median": 79.94999694824219,
                "majority": 72.31999969482422,
                "minority": 90.77999877929688,
                "unique": 8,
                "histogram": [
                  [
                    189605,
                    48223,
                    97891,
                    0,
                    0,
                    0,
                    155116,
                    0,
                    0,
                    144913
                  ],
                  [
                    72.31999969482422,
                    75.0009994506836,
                    77.68199920654297,
                    80.36299896240234,
                    83.04399871826172,
                    85.7249984741211,
                    88.40599822998047,
                    91.08699798583984,
                    93.76799774169922,
                    96.4489974975586,
                    99.12999725341797
                  ]
                ],
                "valid_percent": 68.3,
                "masked_pixels": 295068,
                "valid_pixels": 635748,
                "percentile_2": 72.31999969482422,
                "percentile_98": 99.12999725341797
              }
            }
          },
          {
            "cog_status": true,
            "opacity": 100,
            "layer_name": "Fourth Scenario",
            "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/rainfall_max_4.tif",
            "max_info": {
              "max": 95.81999969482422,
              "mean": 79.69676208496094,
              "min": 67.7300033569336
            },
            "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Frainfall_max_4.tif&rescale=67.7300033569336%2C95.81999969482422&colormap_name=magma",
            "bounds": [
              [
                27.249991085073376,
                95.24999999998438
              ],
              [
                27.950000000003527,
                95.95007045280211
              ]
            ],
            "stats": {
              "b1": {
                "min": 67.7300033569336,
                "max": 95.81999969482422,
                "mean": 79.22293109848556,
                "count": 635748,
                "sum": 50365820,
                "std": 9.823656390355357,
                "median": 75.18000030517578,
                "majority": 67.7300033569336,
                "minority": 84.79000091552734,
                "unique": 8,
                "histogram": [
                  [
                    189605,
                    0,
                    146114,
                    0,
                    38218,
                    0,
                    37804,
                    166762,
                    0,
                    57245
                  ],
                  [
                    67.7300033569336,
                    70.53900146484375,
                    73.3479995727539,
                    76.1570053100586,
                    78.96600341796875,
                    81.7750015258789,
                    84.58399963378906,
                    87.39299774169922,
                    90.2020034790039,
                    93.01100158691406,
                    95.81999969482422
                  ]
                ],
                "valid_percent": 68.3,
                "masked_pixels": 295068,
                "valid_pixels": 635748,
                "percentile_2": 67.7300033569336,
                "percentile_98": 95.81999969482422
              }
            }
          },
          {
            "cog_status": true,
            "opacity": 100,
            "layer_name": "Fifth Scenario",
            "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/mean_of_max.tif",
            "max_info": {
              "max": 93.75908660888672,
              "mean": 77.13279724121094,
              "min": 67.13545227050781
            },
            "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Fmean_of_max.tif&rescale=67.13545227050781%2C93.75908660888672&colormap_name=magma",
            "bounds": [
              [
                27.249991085073376,
                95.24999999998438
              ],
              [
                27.950000000003527,
                95.95007045280211
              ]
            ],
            "stats": {
              "b1": {
                "min": 67.13545227050781,
                "max": 93.75908660888672,
                "mean": 77.13531147561612,
                "count": 635748,
                "sum": 49038620,
                "std": 8.638502761059854,
                "median": 78.19999694824219,
                "majority": 67.13545227050781,
                "minority": 72.15363311767578,
                "unique": 8,
                "histogram": [
                  [
                    185677,
                    22378,
                    87668,
                    0,
                    121203,
                    109795,
                    0,
                    48223,
                    0,
                    60804
                  ],
                  [
                    67.13545227050781,
                    69.79781341552734,
                    72.4601821899414,
                    75.12254333496094,
                    77.78490447998047,
                    80.447265625,
                    83.10963439941406,
                    85.7719955444336,
                    88.43435668945312,
                    91.09672546386719,
                    93.75908660888672
                  ]
                ],
                "valid_percent": 68.3,
                "masked_pixels": 295068,
                "valid_pixels": 635748,
                "percentile_2": 67.13545227050781,
                "percentile_98": 93.75908660888672
              }
            }
          }
        ],
        "mean_data": [
          {
            "cog_status": true,
            "opacity": 100,
            "layer_name": "First Scenario",
            "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/rainfall_mean_1.tif",
            "mean_info": {
              "max": 17.16377067565918,
              "mean": 14.471803665161133,
              "min": 11.571640014648438
            },
            "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Frainfall_mean_1.tif&rescale=11.571640014648438%2C17.16377067565918&colormap_name=magma",
            "bounds": [
              [
                27.249991085073376,
                95.24999999998438
              ],
              [
                27.950000000003527,
                95.95007045280211
              ]
            ],
            "stats": {
              "b1": {
                "min": 11.571640014648438,
                "max": 17.16377067565918,
                "mean": 14.143695300653718,
                "count": 635748,
                "sum": 8991826,
                "std": 1.7904103315175497,
                "median": 14.48909854888916,
                "majority": 14.48909854888916,
                "minority": 13.523114204406738,
                "unique": 8,
                "histogram": [
                  [
                    185559,
                    0,
                    0,
                    37804,
                    0,
                    295073,
                    0,
                    0,
                    0,
                    117312
                  ],
                  [
                    11.571640014648438,
                    12.130852699279785,
                    12.69006633758545,
                    13.249279022216797,
                    13.808492660522461,
                    14.367705345153809,
                    14.926918029785156,
                    15.48613166809082,
                    16.045345306396484,
                    16.604557037353516,
                    17.16377067565918
                  ]
                ],
                "valid_percent": 68.3,
                "masked_pixels": 295068,
                "valid_pixels": 635748,
                "percentile_2": 11.571640014648438,
                "percentile_98": 17.16377067565918
              }
            }
          },
          {
            "cog_status": true,
            "opacity": 100,
            "layer_name": "Second Scenario",
            "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/rainfall_mean_2.tif",
            "mean_info": {
              "max": 15.82877254486084,
              "mean": 13.607893943786621,
              "min": 11.073524475097656
            },
            "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Frainfall_mean_2.tif&rescale=11.073524475097656%2C15.82877254486084&colormap_name=magma",
            "bounds": [
              [
                27.249991085073376,
                95.24999999998438
              ],
              [
                27.950000000003527,
                95.95007045280211
              ]
            ],
            "stats": {
              "b1": {
                "min": 11.073524475097656,
                "max": 15.82877254486084,
                "mean": 13.317808313986045,
                "count": 635748,
                "sum": 8466770,
                "std": 1.5032135674307578,
                "median": 13.083279609680176,
                "majority": 13.949020385742188,
                "minority": 12.097211837768555,
                "unique": 8,
                "histogram": [
                  [
                    87668,
                    0,
                    135695,
                    57245,
                    48223,
                    0,
                    189605,
                    0,
                    0,
                    117312
                  ],
                  [
                    11.073524475097656,
                    11.549049377441406,
                    12.024574279785156,
                    12.500099182128906,
                    12.975624084472656,
                    13.451148986816406,
                    13.92667293548584,
                    14.40219783782959,
                    14.87772274017334,
                    15.35324764251709,
                    15.82877254486084
                  ]
                ],
                "valid_percent": 68.3,
                "masked_pixels": 295068,
                "valid_pixels": 635748,
                "percentile_2": 11.073524475097656,
                "percentile_98": 15.82877254486084
              }
            }
          },
          {
            "cog_status": true,
            "opacity": 100,
            "layer_name": "Third Scenario",
            "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/rainfall_mean_3.tif",
            "mean_info": {
              "max": 14.61819839477539,
              "mean": 13.030558586120605,
              "min": 11.017867088317871
            },
            "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Frainfall_mean_3.tif&rescale=11.017867088317871%2C14.61819839477539&colormap_name=magma",
            "bounds": [
              [
                27.249991085073376,
                95.24999999998438
              ],
              [
                27.950000000003527,
                95.95007045280211
              ]
            ],
            "stats": {
              "b1": {
                "min": 11.017867088317871,
                "max": 14.61819839477539,
                "mean": 12.799005266237566,
                "count": 635748,
                "sum": 8136942,
                "std": 1.201321205189049,
                "median": 12.269506454467773,
                "majority": 13.520903587341309,
                "minority": 11.973197937011719,
                "unique": 8,
                "histogram": [
                  [
                    87668,
                    0,
                    135695,
                    105468,
                    0,
                    0,
                    189605,
                    0,
                    0,
                    117312
                  ],
                  [
                    11.017867088317871,
                    11.377900123596191,
                    11.737933158874512,
                    12.097966194152832,
                    12.457999229431152,
                    12.818033218383789,
                    13.17806625366211,
                    13.53809928894043,
                    13.89813232421875,
                    14.25816535949707,
                    14.61819839477539
                  ]
                ],
                "valid_percent": 68.3,
                "masked_pixels": 295068,
                "valid_pixels": 635748,
                "percentile_2": 11.017867088317871,
                "percentile_98": 14.61819839477539
              }
            }
          },
          {
            "cog_status": true,
            "opacity": 100,
            "layer_name": "Fourth Scenario",
            "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/rainfall_mean_4.tif",
            "mean_info": {
              "max": 14.230409622192383,
              "mean": 12.778246879577637,
              "min": 10.683769226074219
            },
            "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Frainfall_mean_4.tif&rescale=10.683769226074219%2C14.230409622192383&colormap_name=magma",
            "bounds": [
              [
                27.249991085073376,
                95.24999999998438
              ],
              [
                27.950000000003527,
                95.95007045280211
              ]
            ],
            "stats": {
              "b1": {
                "min": 10.683769226074219,
                "max": 14.230409622192383,
                "mean": 12.593319208239743,
                "count": 635748,
                "sum": 8006177.5,
                "std": 1.1991592430410842,
                "median": 12.024015426635742,
                "majority": 13.469915390014648,
                "minority": 11.859505653381348,
                "unique": 8,
                "histogram": [
                  [
                    87668,
                    0,
                    97891,
                    143272,
                    0,
                    0,
                    0,
                    189605,
                    0,
                    117312
                  ],
                  [
                    10.683769226074219,
                    11.038433074951172,
                    11.393096923828125,
                    11.747761726379395,
                    12.102425575256348,
                    12.4570894241333,
                    12.811753273010254,
                    13.166417121887207,
                    13.521081924438477,
                    13.87574577331543,
                    14.230409622192383
                  ]
                ],
                "valid_percent": 68.3,
                "masked_pixels": 295068,
                "valid_pixels": 635748,
                "percentile_2": 10.683769226074219,
                "percentile_98": 14.230409622192383
              }
            }
          },
          {
            "cog_status": true,
            "opacity": 100,
            "layer_name": "Fifth Scenario",
            "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/4f48be59-b7cc-4639-9da8-a319b5150db6/COG/mean_of_mean.tif",
            "mean_info": {
              "max": 13.993694305419922,
              "mean": 12.097013473510742,
              "min": 9.748114585876465
            },
            "rasterLayerUrl": "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fnextech-analytics.s3.ap-south-1.amazonaws.com%2FDatapipeline%2F4f48be59-b7cc-4639-9da8-a319b5150db6%2FCOG%2Fmean_of_mean.tif&rescale=9.748114585876465%2C13.993694305419922&colormap_name=magma",
            "bounds": [
              [
                27.249991085073376,
                95.24999999998438
              ],
              [
                27.950000000003527,
                95.95007045280211
              ]
            ],
            "stats": {
              "b1": {
                "min": 9.748114585876465,
                "max": 13.993694305419922,
                "mean": 12.0961237786041,
                "count": 635748,
                "sum": 7690086.5,
                "std": 1.5064796356666008,
                "median": 12.645162582397461,
                "majority": 12.645162582397461,
                "minority": 11.875210762023926,
                "unique": 8,
                "histogram": [
                  [
                    97891,
                    0,
                    110980,
                    48223,
                    0,
                    22378,
                    185677,
                    0,
                    0,
                    170599
                  ],
                  [
                    9.748114585876465,
                    10.172672271728516,
                    10.597230911254883,
                    11.021788597106934,
                    11.446346282958984,
                    11.870904922485352,
                    12.295462608337402,
                    12.720020294189453,
                    13.144577980041504,
                    13.569136619567871,
                    13.993694305419922
                  ]
                ],
                "valid_percent": 68.3,
                "masked_pixels": 295068,
                "valid_pixels": 635748,
                "percentile_2": 9.748114585876465,
                "percentile_98": 13.993694305419922
              }
            }
          }
        ]
      }
    },
    "message": "success",
    "status": 200
  },
  "journeyInputData": {
    "journeyName": "",
    "location": {
      "stateDistrictDropdowns": {
        "selectedState": {
          "stateName": "ASSAM",
          "stateCode": "18"
        },
        "selectedDistrict": {
          "districtName": "Tinsukia",
          "districtCode": "309"
        }
      }
    },
    "contextVariable": {
      "name": "Meteorological",
      "value": "Precipitation_Flux"
    },
    "date": dayjs()
  },
  "JourneyMap": {
    "isLoading": false,
    "geoJson": {
      "features": [
        {
          "geometry": {
            "coordinates": [
              [
                [
                  [
                    95.97182,
                    27.962178
                  ],
                  [
                    95.971617,
                    27.961637
                  ],
                  [
                    95.968025,
                    27.957366
                  ],
                  [
                    95.965303,
                    27.953176
                  ],
                  [
                    95.9652,
                    27.951665
                  ],
                  [
                    95.962526,
                    27.949858
                  ],
                  [
                    95.956729,
                    27.943143
                  ],
                  [
                    95.954563,
                    27.941136
                  ],
                  [
                    95.951178,
                    27.939554
                  ],
                  [
                    95.949069,
                    27.938175
                  ],
                  [
                    95.948047,
                    27.936484
                  ],
                  [
                    95.947842,
                    27.934787
                  ],
                  [
                    95.948252,
                    27.9324
                  ],
                  [
                    95.949242,
                    27.931204
                  ],
                  [
                    95.950974,
                    27.930819
                  ],
                  [
                    95.953718,
                    27.931698
                  ],
                  [
                    95.954713,
                    27.930772
                  ],
                  [
                    95.953004,
                    27.928558
                  ],
                  [
                    95.948551,
                    27.924391
                  ],
                  [
                    95.94883,
                    27.923534
                  ],
                  [
                    95.952186,
                    27.921407
                  ],
                  [
                    95.952693,
                    27.920094
                  ],
                  [
                    95.947124,
                    27.917453
                  ],
                  [
                    95.94595,
                    27.916348
                  ],
                  [
                    95.94418,
                    27.911846
                  ],
                  [
                    95.942655,
                    27.910691
                  ],
                  [
                    95.941634,
                    27.910715
                  ],
                  [
                    95.939553,
                    27.912502
                  ],
                  [
                    95.938151,
                    27.912525
                  ],
                  [
                    95.937541,
                    27.911375
                  ],
                  [
                    95.935457,
                    27.90898
                  ],
                  [
                    95.935533,
                    27.907487
                  ],
                  [
                    95.936319,
                    27.907013
                  ],
                  [
                    95.939601,
                    27.906989
                  ],
                  [
                    95.941048,
                    27.906514
                  ],
                  [
                    95.940056,
                    27.904976
                  ],
                  [
                    95.936445,
                    27.903737
                  ],
                  [
                    95.934306,
                    27.903237
                  ],
                  [
                    95.93039,
                    27.903171
                  ],
                  [
                    95.92977,
                    27.902102
                  ],
                  [
                    95.93178,
                    27.900138
                  ],
                  [
                    95.93729,
                    27.899193
                  ],
                  [
                    95.937747,
                    27.8982
                  ],
                  [
                    95.936014,
                    27.897547
                  ],
                  [
                    95.931235,
                    27.896461
                  ],
                  [
                    95.929457,
                    27.895086
                  ],
                  [
                    95.927878,
                    27.895335
                  ],
                  [
                    95.928158,
                    27.898137
                  ],
                  [
                    95.92633,
                    27.898882
                  ],
                  [
                    95.925568,
                    27.897641
                  ],
                  [
                    95.926075,
                    27.895809
                  ],
                  [
                    95.924876,
                    27.895065
                  ],
                  [
                    95.922667,
                    27.895698
                  ],
                  [
                    95.920555,
                    27.895856
                  ],
                  [
                    95.919234,
                    27.895044
                  ],
                  [
                    95.919487,
                    27.893122
                  ],
                  [
                    95.920959,
                    27.891313
                  ],
                  [
                    95.92335,
                    27.889164
                  ],
                  [
                    95.923426,
                    27.887878
                  ],
                  [
                    95.920678,
                    27.888285
                  ],
                  [
                    95.917678,
                    27.890073
                  ],
                  [
                    95.915504,
                    27.889388
                  ],
                  [
                    95.915427,
                    27.887014
                  ],
                  [
                    95.916802,
                    27.884996
                  ],
                  [
                    95.918427,
                    27.884685
                  ],
                  [
                    95.920052,
                    27.88491
                  ],
                  [
                    95.921271,
                    27.883759
                  ],
                  [
                    95.924652,
                    27.882608
                  ],
                  [
                    95.925088,
                    27.881678
                  ],
                  [
                    95.922849,
                    27.881589
                  ],
                  [
                    95.919441,
                    27.880597
                  ],
                  [
                    95.918781,
                    27.880981
                  ],
                  [
                    95.917229,
                    27.883603
                  ],
                  [
                    95.915553,
                    27.883761
                  ],
                  [
                    95.91573,
                    27.881884
                  ],
                  [
                    95.917024,
                    27.880462
                  ],
                  [
                    95.915145,
                    27.878428
                  ],
                  [
                    95.914539,
                    27.875757
                  ],
                  [
                    95.913651,
                    27.874896
                  ],
                  [
                    95.910116,
                    27.872411
                  ],
                  [
                    95.910801,
                    27.870944
                  ],
                  [
                    95.910115,
                    27.87015
                  ],
                  [
                    95.908054,
                    27.871012
                  ],
                  [
                    95.907495,
                    27.869903
                  ],
                  [
                    95.908561,
                    27.869158
                  ],
                  [
                    95.90752,
                    27.866943
                  ],
                  [
                    95.906047,
                    27.865973
                  ],
                  [
                    95.903352,
                    27.868911
                  ],
                  [
                    95.901691,
                    27.868469
                  ],
                  [
                    95.901462,
                    27.866479
                  ],
                  [
                    95.902279,
                    27.86508
                  ],
                  [
                    95.904741,
                    27.862955
                  ],
                  [
                    95.904386,
                    27.8618
                  ],
                  [
                    95.901309,
                    27.862048
                  ],
                  [
                    95.899887,
                    27.861801
                  ],
                  [
                    95.895897,
                    27.862275
                  ],
                  [
                    95.894547,
                    27.861621
                  ],
                  [
                    95.895744,
                    27.858683
                  ],
                  [
                    95.89447,
                    27.857574
                  ],
                  [
                    95.890937,
                    27.860038
                  ],
                  [
                    95.890454,
                    27.858391
                  ],
                  [
                    95.890657,
                    27.856717
                  ],
                  [
                    95.888454,
                    27.856573
                  ],
                  [
                    95.885732,
                    27.849498
                  ],
                  [
                    95.883655,
                    27.846105
                  ],
                  [
                    95.878569,
                    27.841201
                  ],
                  [
                    95.870407,
                    27.832669
                  ],
                  [
                    95.868829,
                    27.83215
                  ],
                  [
                    95.86555,
                    27.831766
                  ],
                  [
                    95.859987,
                    27.83025
                  ],
                  [
                    95.854394,
                    27.830023
                  ],
                  [
                    95.847401,
                    27.827116
                  ],
                  [
                    95.839449,
                    27.824516
                  ],
                  [
                    95.837759,
                    27.824118
                  ],
                  [
                    95.837837,
                    27.818853
                  ],
                  [
                    95.838149,
                    27.81046
                  ],
                  [
                    95.836774,
                    27.809467
                  ],
                  [
                    95.831213,
                    27.809376
                  ],
                  [
                    95.827935,
                    27.809126
                  ],
                  [
                    95.826585,
                    27.808715
                  ],
                  [
                    95.822065,
                    27.806521
                  ],
                  [
                    95.81891,
                    27.804498
                  ],
                  [
                    95.816648,
                    27.802688
                  ],
                  [
                    95.811673,
                    27.797623
                  ],
                  [
                    95.810613,
                    27.795759
                  ],
                  [
                    95.810413,
                    27.790715
                  ],
                  [
                    95.809552,
                    27.789076
                  ],
                  [
                    95.804805,
                    27.785979
                  ],
                  [
                    95.803285,
                    27.783424
                  ],
                  [
                    95.80233,
                    27.777445
                  ],
                  [
                    95.800349,
                    27.773695
                  ],
                  [
                    95.7979,
                    27.771609
                  ],
                  [
                    95.79503,
                    27.76969
                  ],
                  [
                    95.791022,
                    27.764417
                  ],
                  [
                    95.78932,
                    27.76193
                  ],
                  [
                    95.785741,
                    27.758742
                  ],
                  [
                    95.78235,
                    27.756385
                  ],
                  [
                    95.778035,
                    27.75464
                  ],
                  [
                    95.776002,
                    27.753623
                  ],
                  [
                    95.775852,
                    27.751994
                  ],
                  [
                    95.776255,
                    27.749234
                  ],
                  [
                    95.777335,
                    27.745917
                  ],
                  [
                    95.77798,
                    27.742463
                  ],
                  [
                    95.778599,
                    27.740539
                  ],
                  [
                    95.780549,
                    27.736917
                  ],
                  [
                    95.780281,
                    27.735212
                  ],
                  [
                    95.778774,
                    27.73318
                  ],
                  [
                    95.774297,
                    27.730938
                  ],
                  [
                    95.771911,
                    27.730175
                  ],
                  [
                    95.768912,
                    27.728456
                  ],
                  [
                    95.766307,
                    27.726651
                  ],
                  [
                    95.765002,
                    27.726332
                  ],
                  [
                    95.76265,
                    27.726838
                  ],
                  [
                    95.75848,
                    27.726944
                  ],
                  [
                    95.754148,
                    27.725703
                  ],
                  [
                    95.752369,
                    27.723829
                  ],
                  [
                    95.752263,
                    27.720918
                  ],
                  [
                    95.752665,
                    27.719826
                  ],
                  [
                    95.754186,
                    27.718421
                  ],
                  [
                    95.757584,
                    27.717436
                  ],
                  [
                    95.75911,
                    27.716457
                  ],
                  [
                    95.760831,
                    27.715981
                  ],
                  [
                    95.762401,
                    27.715011
                  ],
                  [
                    95.764157,
                    27.715354
                  ],
                  [
                    95.764625,
                    27.71438
                  ],
                  [
                    95.763069,
                    27.713584
                  ],
                  [
                    95.762689,
                    27.712641
                  ],
                  [
                    95.76403,
                    27.711347
                  ],
                  [
                    95.762774,
                    27.710087
                  ],
                  [
                    95.762898,
                    27.709015
                  ],
                  [
                    95.763859,
                    27.707689
                  ],
                  [
                    95.765749,
                    27.70627
                  ],
                  [
                    95.766445,
                    27.703437
                  ],
                  [
                    95.767896,
                    27.70247
                  ],
                  [
                    95.766687,
                    27.700421
                  ],
                  [
                    95.764882,
                    27.700049
                  ],
                  [
                    95.765746,
                    27.698556
                  ],
                  [
                    95.765732,
                    27.697568
                  ],
                  [
                    95.764496,
                    27.695884
                  ],
                  [
                    95.76462,
                    27.694287
                  ],
                  [
                    95.763811,
                    27.693435
                  ],
                  [
                    95.765734,
                    27.690439
                  ],
                  [
                    95.767153,
                    27.690485
                  ],
                  [
                    95.767712,
                    27.688794
                  ],
                  [
                    95.767592,
                    27.687296
                  ],
                  [
                    95.768271,
                    27.686809
                  ],
                  [
                    95.770238,
                    27.686851
                  ],
                  [
                    95.770478,
                    27.685992
                  ],
                  [
                    95.772639,
                    27.686129
                  ],
                  [
                    95.773786,
                    27.684997
                  ],
                  [
                    95.775616,
                    27.68672
                  ],
                  [
                    95.777264,
                    27.686585
                  ],
                  [
                    95.777405,
                    27.687556
                  ],
                  [
                    95.779745,
                    27.68739
                  ],
                  [
                    95.781468,
                    27.686872
                  ],
                  [
                    95.78293,
                    27.687214
                  ],
                  [
                    95.782755,
                    27.685369
                  ],
                  [
                    95.783811,
                    27.684496
                  ],
                  [
                    95.785119,
                    27.68514
                  ],
                  [
                    95.786613,
                    27.683645
                  ],
                  [
                    95.786001,
                    27.682201
                  ],
                  [
                    95.786772,
                    27.681498
                  ],
                  [
                    95.78815,
                    27.681805
                  ],
                  [
                    95.79026,
                    27.679781
                  ],
                  [
                    95.787949,
                    27.67949
                  ],
                  [
                    95.786982,
                    27.678407
                  ],
                  [
                    95.783678,
                    27.678152
                  ],
                  [
                    95.779061,
                    27.677283
                  ],
                  [
                    95.778662,
                    27.676069
                  ],
                  [
                    95.775136,
                    27.67432
                  ],
                  [
                    95.773395,
                    27.671882
                  ],
                  [
                    95.773761,
                    27.670398
                  ],
                  [
                    95.772893,
                    27.666269
                  ],
                  [
                    95.77228,
                    27.665591
                  ],
                  [
                    95.772019,
                    27.663681
                  ],
                  [
                    95.772942,
                    27.662176
                  ],
                  [
                    95.772235,
                    27.659992
                  ],
                  [
                    95.770433,
                    27.657937
                  ],
                  [
                    95.770709,
                    27.656278
                  ],
                  [
                    95.771636,
                    27.655013
                  ],
                  [
                    95.772562,
                    27.654729
                  ],
                  [
                    95.77349,
                    27.651642
                  ],
                  [
                    95.774321,
                    27.650799
                  ],
                  [
                    95.777876,
                    27.649694
                  ],
                  [
                    95.779232,
                    27.649609
                  ],
                  [
                    95.780251,
                    27.647891
                  ],
                  [
                    95.7815,
                    27.647131
                  ],
                  [
                    95.786217,
                    27.646317
                  ],
                  [
                    95.786465,
                    27.645219
                  ],
                  [
                    95.787841,
                    27.643956
                  ],
                  [
                    95.79027,
                    27.644718
                  ],
                  [
                    95.7924,
                    27.643612
                  ],
                  [
                    95.794785,
                    27.64179
                  ],
                  [
                    95.795785,
                    27.641733
                  ],
                  [
                    95.795383,
                    27.638799
                  ],
                  [
                    95.79443,
                    27.638869
                  ],
                  [
                    95.793631,
                    27.637694
                  ],
                  [
                    95.79095,
                    27.6371
                  ],
                  [
                    95.790436,
                    27.6362
                  ],
                  [
                    95.788299,
                    27.634843
                  ],
                  [
                    95.788234,
                    27.631262
                  ],
                  [
                    95.789198,
                    27.629989
                  ],
                  [
                    95.789093,
                    27.628325
                  ],
                  [
                    95.789497,
                    27.626312
                  ],
                  [
                    95.787584,
                    27.625541
                  ],
                  [
                    95.785863,
                    27.625779
                  ],
                  [
                    95.784467,
                    27.625354
                  ],
                  [
                    95.781221,
                    27.625088
                  ],
                  [
                    95.779572,
                    27.625643
                  ],
                  [
                    95.778913,
                    27.625123
                  ],
                  [
                    95.778359,
                    27.622342
                  ],
                  [
                    95.778324,
                    27.619378
                  ],
                  [
                    95.779286,
                    27.617589
                  ],
                  [
                    95.780605,
                    27.616232
                  ],
                  [
                    95.785139,
                    27.615611
                  ],
                  [
                    95.787906,
                    27.614083
                  ],
                  [
                    95.790366,
                    27.611589
                  ],
                  [
                    95.789662,
                    27.606786
                  ],
                  [
                    95.789425,
                    27.604003
                  ],
                  [
                    95.78991,
                    27.601402
                  ],
                  [
                    95.790644,
                    27.599648
                  ],
                  [
                    95.792532,
                    27.597939
                  ],
                  [
                    95.795327,
                    27.597664
                  ],
                  [
                    95.796877,
                    27.598759
                  ],
                  [
                    95.798824,
                    27.599423
                  ],
                  [
                    95.801149,
                    27.599659
                  ],
                  [
                    95.802175,
                    27.60045
                  ],
                  [
                    95.805574,
                    27.600946
                  ],
                  [
                    95.807932,
                    27.59995
                  ],
                  [
                    95.810127,
                    27.600194
                  ],
                  [
                    95.81411,
                    27.599506
                  ],
                  [
                    95.815533,
                    27.598823
                  ],
                  [
                    95.818279,
                    27.596342
                  ],
                  [
                    95.818382,
                    27.59516
                  ],
                  [
                    95.816172,
                    27.592839
                  ],
                  [
                    95.813502,
                    27.588424
                  ],
                  [
                    95.813344,
                    27.587002
                  ],
                  [
                    95.812145,
                    27.58436
                  ],
                  [
                    95.8119,
                    27.581736
                  ],
                  [
                    95.813152,
                    27.578726
                  ],
                  [
                    95.813221,
                    27.577353
                  ],
                  [
                    95.813982,
                    27.576189
                  ],
                  [
                    95.814509,
                    27.574159
                  ],
                  [
                    95.817497,
                    27.573664
                  ],
                  [
                    95.817912,
                    27.574657
                  ],
                  [
                    95.81934,
                    27.575023
                  ],
                  [
                    95.820347,
                    27.574541
                  ],
                  [
                    95.823441,
                    27.574479
                  ],
                  [
                    95.824906,
                    27.571591
                  ],
                  [
                    95.826375,
                    27.570734
                  ],
                  [
                    95.827707,
                    27.56898
                  ],
                  [
                    95.829121,
                    27.565221
                  ],
                  [
                    95.830505,
                    27.563065
                  ],
                  [
                    95.832262,
                    27.562208
                  ],
                  [
                    95.833022,
                    27.560372
                  ],
                  [
                    95.834501,
                    27.559614
                  ],
                  [
                    95.836298,
                    27.559263
                  ],
                  [
                    95.841913,
                    27.561364
                  ],
                  [
                    95.84473,
                    27.559572
                  ],
                  [
                    95.845834,
                    27.558196
                  ],
                  [
                    95.852903,
                    27.552787
                  ],
                  [
                    95.854093,
                    27.54999
                  ],
                  [
                    95.856124,
                    27.54749
                  ],
                  [
                    95.857202,
                    27.547319
                  ],
                  [
                    95.858523,
                    27.544756
                  ],
                  [
                    95.860174,
                    27.543407
                  ],
                  [
                    95.861693,
                    27.543443
                  ],
                  [
                    95.862755,
                    27.545397
                  ],
                  [
                    95.865762,
                    27.544869
                  ],
                  [
                    95.871938,
                    27.545763
                  ],
                  [
                    95.873366,
                    27.546905
                  ],
                  [
                    95.8758,
                    27.546488
                  ],
                  [
                    95.876937,
                    27.542948
                  ],
                  [
                    95.876629,
                    27.538914
                  ],
                  [
                    95.876963,
                    27.536409
                  ],
                  [
                    95.876765,
                    27.530525
                  ],
                  [
                    95.876983,
                    27.525964
                  ],
                  [
                    95.877408,
                    27.523491
                  ],
                  [
                    95.878217,
                    27.521505
                  ],
                  [
                    95.87846,
                    27.518518
                  ],
                  [
                    95.877478,
                    27.517241
                  ],
                  [
                    95.877418,
                    27.515427
                  ],
                  [
                    95.87584,
                    27.512422
                  ],
                  [
                    95.877498,
                    27.508677
                  ],
                  [
                    95.878803,
                    27.504889
                  ],
                  [
                    95.88011,
                    27.503812
                  ],
                  [
                    95.882123,
                    27.502878
                  ],
                  [
                    95.884296,
                    27.501047
                  ],
                  [
                    95.884228,
                    27.500453
                  ],
                  [
                    95.885685,
                    27.498517
                  ],
                  [
                    95.886535,
                    27.498219
                  ],
                  [
                    95.886682,
                    27.496892
                  ],
                  [
                    95.884668,
                    27.496238
                  ],
                  [
                    95.88275,
                    27.497055
                  ],
                  [
                    95.880757,
                    27.497136
                  ],
                  [
                    95.879203,
                    27.496703
                  ],
                  [
                    95.875899,
                    27.494059
                  ],
                  [
                    95.8743,
                    27.493247
                  ],
                  [
                    95.872939,
                    27.490779
                  ],
                  [
                    95.873162,
                    27.485157
                  ],
                  [
                    95.873045,
                    27.482652
                  ],
                  [
                    95.87389,
                    27.481159
                  ],
                  [
                    95.872848,
                    27.478438
                  ],
                  [
                    95.871538,
                    27.476105
                  ],
                  [
                    95.871346,
                    27.473736
                  ],
                  [
                    95.87171,
                    27.470781
                  ],
                  [
                    95.870693,
                    27.466652
                  ],
                  [
                    95.868341,
                    27.465145
                  ],
                  [
                    95.865645,
                    27.465496
                  ],
                  [
                    95.862974,
                    27.464684
                  ],
                  [
                    95.861229,
                    27.463533
                  ],
                  [
                    95.859312,
                    27.461584
                  ],
                  [
                    95.85698,
                    27.461448
                  ],
                  [
                    95.856034,
                    27.460744
                  ],
                  [
                    95.856035,
                    27.459305
                  ],
                  [
                    95.853319,
                    27.456908
                  ],
                  [
                    95.853511,
                    27.454305
                  ],
                  [
                    95.854605,
                    27.450555
                  ],
                  [
                    95.856431,
                    27.447884
                  ],
                  [
                    95.856871,
                    27.446774
                  ],
                  [
                    95.85675,
                    27.444929
                  ],
                  [
                    95.855759,
                    27.443399
                  ],
                  [
                    95.853524,
                    27.443209
                  ],
                  [
                    95.852189,
                    27.442072
                  ],
                  [
                    95.849979,
                    27.441828
                  ],
                  [
                    95.849083,
                    27.441218
                  ],
                  [
                    95.848037,
                    27.439052
                  ],
                  [
                    95.846682,
                    27.438186
                  ],
                  [
                    95.847289,
                    27.436191
                  ],
                  [
                    95.846222,
                    27.434973
                  ],
                  [
                    95.845298,
                    27.43199
                  ],
                  [
                    95.843139,
                    27.430243
                  ],
                  [
                    95.84227,
                    27.4268
                  ],
                  [
                    95.843485,
                    27.423078
                  ],
                  [
                    95.845579,
                    27.420353
                  ],
                  [
                    95.847202,
                    27.419135
                  ],
                  [
                    95.850019,
                    27.417809
                  ],
                  [
                    95.851941,
                    27.414028
                  ],
                  [
                    95.852958,
                    27.411113
                  ],
                  [
                    95.857039,
                    27.410058
                  ],
                  [
                    95.85898,
                    27.408352
                  ],
                  [
                    95.860143,
                    27.408014
                  ],
                  [
                    95.862499,
                    27.409449
                  ],
                  [
                    95.862449,
                    27.411534
                  ],
                  [
                    95.861892,
                    27.412405
                  ],
                  [
                    95.857721,
                    27.4148
                  ],
                  [
                    95.856411,
                    27.416858
                  ],
                  [
                    95.856603,
                    27.419109
                  ],
                  [
                    95.859151,
                    27.421587
                  ],
                  [
                    95.863691,
                    27.422792
                  ],
                  [
                    95.864687,
                    27.424444
                  ],
                  [
                    95.868379,
                    27.424904
                  ],
                  [
                    95.869759,
                    27.4255
                  ],
                  [
                    95.871221,
                    27.426894
                  ],
                  [
                    95.871827,
                    27.429358
                  ],
                  [
                    95.873865,
                    27.431934
                  ],
                  [
                    95.875666,
                    27.434791
                  ],
                  [
                    95.878746,
                    27.436122
                  ],
                  [
                    95.883216,
                    27.435318
                  ],
                  [
                    95.884622,
                    27.433748
                  ],
                  [
                    95.88496,
                    27.431307
                  ],
                  [
                    95.884429,
                    27.427043
                  ],
                  [
                    95.884747,
                    27.423451
                  ],
                  [
                    95.883923,
                    27.419954
                  ],
                  [
                    95.884676,
                    27.418289
                  ],
                  [
                    95.884676,
                    27.415279
                  ],
                  [
                    95.884337,
                    27.41356
                  ],
                  [
                    95.886036,
                    27.413018
                  ],
                  [
                    95.887588,
                    27.411665
                  ],
                  [
                    95.888295,
                    27.408993
                  ],
                  [
                    95.887567,
                    27.40615
                  ],
                  [
                    95.88609,
                    27.403994
                  ],
                  [
                    95.884392,
                    27.402965
                  ],
                  [
                    95.883931,
                    27.401855
                  ],
                  [
                    95.884513,
                    27.400199
                  ],
                  [
                    95.885726,
                    27.398155
                  ],
                  [
                    95.887814,
                    27.399021
                  ],
                  [
                    95.890994,
                    27.401214
                  ],
                  [
                    95.892496,
                    27.403027
                  ],
                  [
                    95.895242,
                    27.408487
                  ],
                  [
                    95.896628,
                    27.410016
                  ],
                  [
                    95.897331,
                    27.411532
                  ],
                  [
                    95.899056,
                    27.413621
                  ],
                  [
                    95.899522,
                    27.419325
                  ],
                  [
                    95.900008,
                    27.422019
                  ],
                  [
                    95.902123,
                    27.42561
                  ],
                  [
                    95.904986,
                    27.429242
                  ],
                  [
                    95.906033,
                    27.432314
                  ],
                  [
                    95.906666,
                    27.433293
                  ],
                  [
                    95.909605,
                    27.435391
                  ],
                  [
                    95.910889,
                    27.43577
                  ],
                  [
                    95.913221,
                    27.435796
                  ],
                  [
                    95.916548,
                    27.435267
                  ],
                  [
                    95.918232,
                    27.434725
                  ],
                  [
                    95.921024,
                    27.436105
                  ],
                  [
                    95.924615,
                    27.437105
                  ],
                  [
                    95.926998,
                    27.438228
                  ],
                  [
                    95.928793,
                    27.438552
                  ],
                  [
                    95.930614,
                    27.437902
                  ],
                  [
                    95.933202,
                    27.435992
                  ],
                  [
                    95.935943,
                    27.435612
                  ],
                  [
                    95.938639,
                    27.437208
                  ],
                  [
                    95.94131,
                    27.437292
                  ],
                  [
                    95.942108,
                    27.436692
                  ],
                  [
                    95.945602,
                    27.435242
                  ],
                  [
                    95.947446,
                    27.432994
                  ],
                  [
                    95.947734,
                    27.431365
                  ],
                  [
                    95.94662,
                    27.429375
                  ],
                  [
                    95.944823,
                    27.427616
                  ],
                  [
                    95.945187,
                    27.426637
                  ],
                  [
                    95.946885,
                    27.425161
                  ],
                  [
                    95.949676,
                    27.424022
                  ],
                  [
                    95.951132,
                    27.423967
                  ],
                  [
                    95.95339,
                    27.420622
                  ],
                  [
                    95.955043,
                    27.420242
                  ],
                  [
                    95.95509,
                    27.422652
                  ],
                  [
                    95.957789,
                    27.427826
                  ],
                  [
                    95.959368,
                    27.428921
                  ],
                  [
                    95.960339,
                    27.429029
                  ],
                  [
                    95.962599,
                    27.428351
                  ],
                  [
                    95.963205,
                    27.427213
                  ],
                  [
                    95.962353,
                    27.424507
                  ],
                  [
                    95.961576,
                    27.420184
                  ],
                  [
                    95.961792,
                    27.418014
                  ],
                  [
                    95.962327,
                    27.417242
                  ],
                  [
                    95.965388,
                    27.418648
                  ],
                  [
                    95.964904,
                    27.421247
                  ],
                  [
                    95.965004,
                    27.425759
                  ],
                  [
                    95.965465,
                    27.427009
                  ],
                  [
                    95.966927,
                    27.427861
                  ],
                  [
                    95.969355,
                    27.427886
                  ],
                  [
                    95.97042,
                    27.426288
                  ],
                  [
                    95.969619,
                    27.423495
                  ],
                  [
                    95.970756,
                    27.422493
                  ],
                  [
                    95.971995,
                    27.422506
                  ],
                  [
                    95.974082,
                    27.421692
                  ],
                  [
                    95.975173,
                    27.420365
                  ],
                  [
                    95.975439,
                    27.418194
                  ],
                  [
                    95.974371,
                    27.416909
                  ],
                  [
                    95.971216,
                    27.416938
                  ],
                  [
                    95.969951,
                    27.415937
                  ],
                  [
                    95.970218,
                    27.414908
                  ],
                  [
                    95.971482,
                    27.414041
                  ],
                  [
                    95.973396,
                    27.411585
                  ],
                  [
                    95.974705,
                    27.411286
                  ],
                  [
                    95.978589,
                    27.412258
                  ],
                  [
                    95.979777,
                    27.412338
                  ],
                  [
                    95.983296,
                    27.411835
                  ],
                  [
                    95.984366,
                    27.409641
                  ],
                  [
                    95.984339,
                    27.408147
                  ],
                  [
                    95.983639,
                    27.406266
                  ],
                  [
                    95.984558,
                    27.404452
                  ],
                  [
                    95.985937,
                    27.403936
                  ],
                  [
                    95.988101,
                    27.40407
                  ],
                  [
                    95.990137,
                    27.402358
                  ],
                  [
                    95.992419,
                    27.39959
                  ],
                  [
                    95.993488,
                    27.396394
                  ],
                  [
                    95.996469,
                    27.394605
                  ],
                  [
                    95.998167,
                    27.387894
                  ],
                  [
                    96.017876,
                    27.369427
                  ],
                  [
                    96.017754,
                    27.364919
                  ],
                  [
                    96.012705,
                    27.365028
                  ],
                  [
                    96.007534,
                    27.36063
                  ],
                  [
                    96.002485,
                    27.360739
                  ],
                  [
                    95.996498,
                    27.356364
                  ],
                  [
                    95.99329,
                    27.357936
                  ],
                  [
                    95.991106,
                    27.357437
                  ],
                  [
                    95.989205,
                    27.356496
                  ],
                  [
                    95.988354,
                    27.354236
                  ],
                  [
                    95.988696,
                    27.35271
                  ],
                  [
                    95.986848,
                    27.349057
                  ],
                  [
                    95.986553,
                    27.347924
                  ],
                  [
                    95.987083,
                    27.34685
                  ],
                  [
                    95.986879,
                    27.345609
                  ],
                  [
                    95.981727,
                    27.341687
                  ],
                  [
                    95.978004,
                    27.338365
                  ],
                  [
                    95.975613,
                    27.336999
                  ],
                  [
                    95.972283,
                    27.336871
                  ],
                  [
                    95.970933,
                    27.335473
                  ],
                  [
                    95.971012,
                    27.332815
                  ],
                  [
                    95.969732,
                    27.331386
                  ],
                  [
                    95.965295,
                    27.32788
                  ],
                  [
                    95.966044,
                    27.325946
                  ],
                  [
                    95.967721,
                    27.325674
                  ],
                  [
                    95.972093,
                    27.32744
                  ],
                  [
                    95.97584,
                    27.325136
                  ],
                  [
                    95.975556,
                    27.324459
                  ],
                  [
                    95.970573,
                    27.322703
                  ],
                  [
                    95.967693,
                    27.32234
                  ],
                  [
                    95.964769,
                    27.323018
                  ],
                  [
                    95.962763,
                    27.323141
                  ],
                  [
                    95.961636,
                    27.322276
                  ],
                  [
                    95.961513,
                    27.320588
                  ],
                  [
                    95.962589,
                    27.319712
                  ],
                  [
                    95.961915,
                    27.316892
                  ],
                  [
                    95.961025,
                    27.316442
                  ],
                  [
                    95.959121,
                    27.316605
                  ],
                  [
                    95.957257,
                    27.317789
                  ],
                  [
                    95.955474,
                    27.318015
                  ],
                  [
                    95.954751,
                    27.316883
                  ],
                  [
                    95.956093,
                    27.31459
                  ],
                  [
                    95.956561,
                    27.312568
                  ],
                  [
                    95.95987,
                    27.311795
                  ],
                  [
                    95.960041,
                    27.310951
                  ],
                  [
                    95.958439,
                    27.310153
                  ],
                  [
                    95.953427,
                    27.308924
                  ],
                  [
                    95.951693,
                    27.30763
                  ],
                  [
                    95.95001,
                    27.305497
                  ],
                  [
                    95.946629,
                    27.303788
                  ],
                  [
                    95.942407,
                    27.305266
                  ],
                  [
                    95.940775,
                    27.304788
                  ],
                  [
                    95.939309,
                    27.302307
                  ],
                  [
                    95.937562,
                    27.300824
                  ],
                  [
                    95.933338,
                    27.300261
                  ],
                  [
                    95.930123,
                    27.300276
                  ],
                  [
                    95.929013,
                    27.299045
                  ],
                  [
                    95.926825,
                    27.297588
                  ],
                  [
                    95.92432,
                    27.296858
                  ],
                  [
                    95.922182,
                    27.294594
                  ],
                  [
                    95.919576,
                    27.293313
                  ],
                  [
                    95.91767,
                    27.291202
                  ],
                  [
                    95.916373,
                    27.292028
                  ],
                  [
                    95.914777,
                    27.29201
                  ],
                  [
                    95.913382,
                    27.289136
                  ],
                  [
                    95.910927,
                    27.288997
                  ],
                  [
                    95.908725,
                    27.289598
                  ],
                  [
                    95.907175,
                    27.288971
                  ],
                  [
                    95.906547,
                    27.285898
                  ],
                  [
                    95.906597,
                    27.282947
                  ],
                  [
                    95.905557,
                    27.282252
                  ],
                  [
                    95.90231,
                    27.283896
                  ],
                  [
                    95.901113,
                    27.283918
                  ],
                  [
                    95.899481,
                    27.282818
                  ],
                  [
                    95.897971,
                    27.282538
                  ],
                  [
                    95.896809,
                    27.281713
                  ],
                  [
                    95.897092,
                    27.280544
                  ],
                  [
                    95.898218,
                    27.279578
                  ],
                  [
                    95.903949,
                    27.275574
                  ],
                  [
                    95.904352,
                    27.273918
                  ],
                  [
                    95.903049,
                    27.273515
                  ],
                  [
                    95.901358,
                    27.2747
                  ],
                  [
                    95.89956,
                    27.273689
                  ],
                  [
                    95.899887,
                    27.269393
                  ],
                  [
                    95.898948,
                    27.268324
                  ],
                  [
                    95.895225,
                    27.2685
                  ],
                  [
                    95.89414,
                    27.268144
                  ],
                  [
                    95.893499,
                    27.26703
                  ],
                  [
                    95.894625,
                    27.265504
                  ],
                  [
                    95.89417,
                    27.262246
                  ],
                  [
                    95.894624,
                    27.261005
                  ],
                  [
                    95.894184,
                    27.256917
                  ],
                  [
                    95.892431,
                    27.255284
                  ],
                  [
                    95.884646,
                    27.255456
                  ],
                  [
                    95.881319,
                    27.255908
                  ],
                  [
                    95.879824,
                    27.257356
                  ],
                  [
                    95.878203,
                    27.258187
                  ],
                  [
                    95.876497,
                    27.259635
                  ],
                  [
                    95.874305,
                    27.259211
                  ],
                  [
                    95.872038,
                    27.259179
                  ],
                  [
                    95.869372,
                    27.260023
                  ],
                  [
                    95.86613,
                    27.263746
                  ],
                  [
                    95.864646,
                    27.264366
                  ],
                  [
                    95.863388,
                    27.266719
                  ],
                  [
                    95.861121,
                    27.267057
                  ],
                  [
                    95.860984,
                    27.267757
                  ],
                  [
                    95.859212,
                    27.268989
                  ],
                  [
                    95.857974,
                    27.270599
                  ],
                  [
                    95.856429,
                    27.271059
                  ],
                  [
                    95.854995,
                    27.273182
                  ],
                  [
                    95.85508,
                    27.275757
                  ],
                  [
                    95.853822,
                    27.279195
                  ],
                  [
                    95.855632,
                    27.280413
                  ],
                  [
                    95.856256,
                    27.281921
                  ],
                  [
                    95.857614,
                    27.28364
                  ],
                  [
                    95.85948,
                    27.287022
                  ],
                  [
                    95.851775,
                    27.28678
                  ],
                  [
                    95.850497,
                    27.28659
                  ],
                  [
                    95.847811,
                    27.285331
                  ],
                  [
                    95.845533,
                    27.285136
                  ],
                  [
                    95.839685,
                    27.283921
                  ],
                  [
                    95.829505,
                    27.280498
                  ],
                  [
                    95.824799,
                    27.279472
                  ],
                  [
                    95.823319,
                    27.278871
                  ],
                  [
                    95.820794,
                    27.278636
                  ],
                  [
                    95.815375,
                    27.278541
                  ],
                  [
                    95.811175,
                    27.276199
                  ],
                  [
                    95.808282,
                    27.274871
                  ],
                  [
                    95.805284,
                    27.273009
                  ],
                  [
                    95.805863,
                    27.27506
                  ],
                  [
                    95.804242,
                    27.276101
                  ],
                  [
                    95.802464,
                    27.2755
                  ],
                  [
                    95.799446,
                    27.27655
                  ],
                  [
                    95.800528,
                    27.278888
                  ],
                  [
                    95.80009,
                    27.280002
                  ],
                  [
                    95.80113,
                    27.280407
                  ],
                  [
                    95.802621,
                    27.282944
                  ],
                  [
                    95.801959,
                    27.284209
                  ],
                  [
                    95.800888,
                    27.284267
                  ],
                  [
                    95.799536,
                    27.28234
                  ],
                  [
                    95.797501,
                    27.281657
                  ],
                  [
                    95.795218,
                    27.282531
                  ],
                  [
                    95.794537,
                    27.281601
                  ],
                  [
                    95.791147,
                    27.279808
                  ],
                  [
                    95.789549,
                    27.278692
                  ],
                  [
                    95.787841,
                    27.276383
                  ],
                  [
                    95.786562,
                    27.276073
                  ],
                  [
                    95.7858,
                    27.27504
                  ],
                  [
                    95.784371,
                    27.274547
                  ],
                  [
                    95.783579,
                    27.273626
                  ],
                  [
                    95.781817,
                    27.273313
                  ],
                  [
                    95.779199,
                    27.269661
                  ],
                  [
                    95.777532,
                    27.270668
                  ],
                  [
                    95.776826,
                    27.269632
                  ],
                  [
                    95.775255,
                    27.270407
                  ],
                  [
                    95.773978,
                    27.269391
                  ],
                  [
                    95.773016,
                    27.269711
                  ],
                  [
                    95.768166,
                    27.268999
                  ],
                  [
                    95.768076,
                    27.267753
                  ],
                  [
                    95.766788,
                    27.268046
                  ],
                  [
                    95.765178,
                    27.267264
                  ],
                  [
                    95.761244,
                    27.267509
                  ],
                  [
                    95.758804,
                    27.268576
                  ],
                  [
                    95.75686,
                    27.268644
                  ],
                  [
                    95.754897,
                    27.26781
                  ],
                  [
                    95.752701,
                    27.267465
                  ],
                  [
                    95.749874,
                    27.266082
                  ],
                  [
                    95.747698,
                    27.265746
                  ],
                  [
                    95.745542,
                    27.26691
                  ],
                  [
                    95.744183,
                    27.266357
                  ],
                  [
                    95.742112,
                    27.266658
                  ],
                  [
                    95.740376,
                    27.265217
                  ],
                  [
                    95.736704,
                    27.263664
                  ],
                  [
                    95.734841,
                    27.260489
                  ],
                  [
                    95.732692,
                    27.259992
                  ],
                  [
                    95.727739,
                    27.25999
                  ],
                  [
                    95.727023,
                    27.259543
                  ],
                  [
                    95.726045,
                    27.257195
                  ],
                  [
                    95.724351,
                    27.255519
                  ],
                  [
                    95.723053,
                    27.254835
                  ],
                  [
                    95.720206,
                    27.249478
                  ],
                  [
                    95.717485,
                    27.249511
                  ],
                  [
                    95.716557,
                    27.250026
                  ],
                  [
                    95.713785,
                    27.25024
                  ],
                  [
                    95.71252,
                    27.25256
                  ],
                  [
                    95.71004,
                    27.252399
                  ],
                  [
                    95.709717,
                    27.251265
                  ],
                  [
                    95.707881,
                    27.250057
                  ],
                  [
                    95.701509,
                    27.248574
                  ],
                  [
                    95.699612,
                    27.249549
                  ],
                  [
                    95.696529,
                    27.25173
                  ],
                  [
                    95.692365,
                    27.253212
                  ],
                  [
                    95.688961,
                    27.252415
                  ],
                  [
                    95.686431,
                    27.252339
                  ],
                  [
                    95.681534,
                    27.251741
                  ],
                  [
                    95.679797,
                    27.251146
                  ],
                  [
                    95.676528,
                    27.247678
                  ],
                  [
                    95.675261,
                    27.246622
                  ],
                  [
                    95.664415,
                    27.242334
                  ],
                  [
                    95.653468,
                    27.238163
                  ],
                  [
                    95.639847,
                    27.232764
                  ],
                  [
                    95.633907,
                    27.230312
                  ],
                  [
                    95.631634,
                    27.229498
                  ],
                  [
                    95.629307,
                    27.23133
                  ],
                  [
                    95.62757,
                    27.231592
                  ],
                  [
                    95.623037,
                    27.231492
                  ],
                  [
                    95.621412,
                    27.232557
                  ],
                  [
                    95.619034,
                    27.232485
                  ],
                  [
                    95.617565,
                    27.230897
                  ],
                  [
                    95.615788,
                    27.231636
                  ],
                  [
                    95.614471,
                    27.230793
                  ],
                  [
                    95.612724,
                    27.23049
                  ],
                  [
                    95.611256,
                    27.229669
                  ],
                  [
                    95.61062,
                    27.227602
                  ],
                  [
                    95.608606,
                    27.227638
                  ],
                  [
                    95.60583,
                    27.226013
                  ],
                  [
                    95.603553,
                    27.224172
                  ],
                  [
                    95.600752,
                    27.224171
                  ],
                  [
                    95.59896,
                    27.222907
                  ],
                  [
                    95.596724,
                    27.222943
                  ],
                  [
                    95.594942,
                    27.223421
                  ],
                  [
                    95.592686,
                    27.223001
                  ],
                  [
                    95.590778,
                    27.223217
                  ],
                  [
                    95.587573,
                    27.222941
                  ],
                  [
                    95.585104,
                    27.224438
                  ],
                  [
                    95.584029,
                    27.225652
                  ],
                  [
                    95.583225,
                    27.228255
                  ],
                  [
                    95.582372,
                    27.228589
                  ],
                  [
                    95.580459,
                    27.227812
                  ],
                  [
                    95.578964,
                    27.229125
                  ],
                  [
                    95.578256,
                    27.232067
                  ],
                  [
                    95.576065,
                    27.233167
                  ],
                  [
                    95.574464,
                    27.23461
                  ],
                  [
                    95.574893,
                    27.23614
                  ],
                  [
                    95.576735,
                    27.237025
                  ],
                  [
                    95.577214,
                    27.238276
                  ],
                  [
                    95.575356,
                    27.239529
                  ],
                  [
                    95.575142,
                    27.243175
                  ],
                  [
                    95.574248,
                    27.243965
                  ],
                  [
                    95.5719,
                    27.244627
                  ],
                  [
                    95.567977,
                    27.244517
                  ],
                  [
                    95.566872,
                    27.245221
                  ],
                  [
                    95.564307,
                    27.244669
                  ],
                  [
                    95.56058,
                    27.247068
                  ],
                  [
                    95.55808,
                    27.24898
                  ],
                  [
                    95.55501,
                    27.248455
                  ],
                  [
                    95.553536,
                    27.248856
                  ],
                  [
                    95.551566,
                    27.250416
                  ],
                  [
                    95.551207,
                    27.251213
                  ],
                  [
                    95.552241,
                    27.252229
                  ],
                  [
                    95.551604,
                    27.253434
                  ],
                  [
                    95.548974,
                    27.253568
                  ],
                  [
                    95.548034,
                    27.254862
                  ],
                  [
                    95.548507,
                    27.257317
                  ],
                  [
                    95.54637,
                    27.258354
                  ],
                  [
                    95.545359,
                    27.259856
                  ],
                  [
                    95.54337,
                    27.259061
                  ],
                  [
                    95.541541,
                    27.25914
                  ],
                  [
                    95.539743,
                    27.261062
                  ],
                  [
                    95.537112,
                    27.262504
                  ],
                  [
                    95.535345,
                    27.26161
                  ],
                  [
                    95.53484,
                    27.262061
                  ],
                  [
                    95.53554,
                    27.263704
                  ],
                  [
                    95.533889,
                    27.264204
                  ],
                  [
                    95.532639,
                    27.261468
                  ],
                  [
                    95.531624,
                    27.261048
                  ],
                  [
                    95.529911,
                    27.262116
                  ],
                  [
                    95.53086,
                    27.263209
                  ],
                  [
                    95.529723,
                    27.264458
                  ],
                  [
                    95.528285,
                    27.263171
                  ],
                  [
                    95.52526,
                    27.263611
                  ],
                  [
                    95.521966,
                    27.259972
                  ],
                  [
                    95.518196,
                    27.257564
                  ],
                  [
                    95.51552,
                    27.257711
                  ],
                  [
                    95.5136,
                    27.259288
                  ],
                  [
                    95.51232,
                    27.261151
                  ],
                  [
                    95.511239,
                    27.262008
                  ],
                  [
                    95.508654,
                    27.261699
                  ],
                  [
                    95.507364,
                    27.259428
                  ],
                  [
                    95.506839,
                    27.253972
                  ],
                  [
                    95.50513,
                    27.249982
                  ],
                  [
                    95.503791,
                    27.248531
                  ],
                  [
                    95.501766,
                    27.247155
                  ],
                  [
                    95.499631,
                    27.246181
                  ],
                  [
                    95.493871,
                    27.245917
                  ],
                  [
                    95.491209,
                    27.245165
                  ],
                  [
                    95.487188,
                    27.242384
                  ],
                  [
                    95.484725,
                    27.241443
                  ],
                  [
                    95.48283,
                    27.243116
                  ],
                  [
                    95.482316,
                    27.244145
                  ],
                  [
                    95.482258,
                    27.247222
                  ],
                  [
                    95.48278,
                    27.250015
                  ],
                  [
                    95.48276,
                    27.251755
                  ],
                  [
                    95.481064,
                    27.253447
                  ],
                  [
                    95.478182,
                    27.253506
                  ],
                  [
                    95.474054,
                    27.252734
                  ],
                  [
                    95.470531,
                    27.253225
                  ],
                  [
                    95.469668,
                    27.254714
                  ],
                  [
                    95.470248,
                    27.257204
                  ],
                  [
                    95.471272,
                    27.259518
                  ],
                  [
                    95.471277,
                    27.262296
                  ],
                  [
                    95.470797,
                    27.263099
                  ],
                  [
                    95.468586,
                    27.263956
                  ],
                  [
                    95.465835,
                    27.26313
                  ],
                  [
                    95.464104,
                    27.261529
                  ],
                  [
                    95.462086,
                    27.259156
                  ],
                  [
                    95.460128,
                    27.256034
                  ],
                  [
                    95.458286,
                    27.254098
                  ],
                  [
                    95.45646,
                    27.252637
                  ],
                  [
                    95.453474,
                    27.248736
                  ],
                  [
                    95.453411,
                    27.246878
                  ],
                  [
                    95.454122,
                    27.244354
                  ],
                  [
                    95.456289,
                    27.242008
                  ],
                  [
                    95.456721,
                    27.239707
                  ],
                  [
                    95.456348,
                    27.238099
                  ],
                  [
                    95.455138,
                    27.235549
                  ],
                  [
                    95.45424,
                    27.232701
                  ],
                  [
                    95.452875,
                    27.231149
                  ],
                  [
                    95.451016,
                    27.231399
                  ],
                  [
                    95.449033,
                    27.233092
                  ],
                  [
                    95.447578,
                    27.235497
                  ],
                  [
                    95.447424,
                    27.237438
                  ],
                  [
                    95.447984,
                    27.24046
                  ],
                  [
                    95.447925,
                    27.244161
                  ],
                  [
                    95.447678,
                    27.245465
                  ],
                  [
                    95.446409,
                    27.248377
                  ],
                  [
                    95.441634,
                    27.25293
                  ],
                  [
                    95.440121,
                    27.254998
                  ],
                  [
                    95.440243,
                    27.257656
                  ],
                  [
                    95.440875,
                    27.259153
                  ],
                  [
                    95.440886,
                    27.260886
                  ],
                  [
                    95.442427,
                    27.262767
                  ],
                  [
                    95.444422,
                    27.262274
                  ],
                  [
                    95.44623,
                    27.263555
                  ],
                  [
                    95.44868,
                    27.266018
                  ],
                  [
                    95.450073,
                    27.264902
                  ],
                  [
                    95.452295,
                    27.264685
                  ],
                  [
                    95.454214,
                    27.26546
                  ],
                  [
                    95.457377,
                    27.268323
                  ],
                  [
                    95.460014,
                    27.269303
                  ],
                  [
                    95.46054,
                    27.270916
                  ],
                  [
                    95.458472,
                    27.274666
                  ],
                  [
                    95.456898,
                    27.275996
                  ],
                  [
                    95.455784,
                    27.277664
                  ],
                  [
                    95.455681,
                    27.279372
                  ],
                  [
                    95.454314,
                    27.281982
                  ],
                  [
                    95.454748,
                    27.284276
                  ],
                  [
                    95.456131,
                    27.286598
                  ],
                  [
                    95.454281,
                    27.288001
                  ],
                  [
                    95.45022,
                    27.288463
                  ],
                  [
                    95.447047,
                    27.292294
                  ],
                  [
                    95.446165,
                    27.292708
                  ],
                  [
                    95.445425,
                    27.29423
                  ],
                  [
                    95.448703,
                    27.296111
                  ],
                  [
                    95.451528,
                    27.29831
                  ],
                  [
                    95.454134,
                    27.299991
                  ],
                  [
                    95.455565,
                    27.30178
                  ],
                  [
                    95.458925,
                    27.3039
                  ],
                  [
                    95.462116,
                    27.307167
                  ],
                  [
                    95.463581,
                    27.308357
                  ],
                  [
                    95.465808,
                    27.310831
                  ],
                  [
                    95.467871,
                    27.314167
                  ],
                  [
                    95.468053,
                    27.315796
                  ],
                  [
                    95.466547,
                    27.31771
                  ],
                  [
                    95.465106,
                    27.322642
                  ],
                  [
                    95.464244,
                    27.32331
                  ],
                  [
                    95.461236,
                    27.324126
                  ],
                  [
                    95.452768,
                    27.33922
                  ],
                  [
                    95.456728,
                    27.34405
                  ],
                  [
                    95.460224,
                    27.343164
                  ],
                  [
                    95.467085,
                    27.341923
                  ],
                  [
                    95.471311,
                    27.343924
                  ],
                  [
                    95.469473,
                    27.348074
                  ],
                  [
                    95.468689,
                    27.355082
                  ],
                  [
                    95.468125,
                    27.358898
                  ],
                  [
                    95.467007,
                    27.364205
                  ],
                  [
                    95.469646,
                    27.365557
                  ],
                  [
                    95.473807,
                    27.36685
                  ],
                  [
                    95.47361,
                    27.367382
                  ],
                  [
                    95.465364,
                    27.369923
                  ],
                  [
                    95.458795,
                    27.372693
                  ],
                  [
                    95.455511,
                    27.375159
                  ],
                  [
                    95.453702,
                    27.374957
                  ],
                  [
                    95.452301,
                    27.374078
                  ],
                  [
                    95.452281,
                    27.373333
                  ],
                  [
                    95.448195,
                    27.371503
                  ],
                  [
                    95.446914,
                    27.366983
                  ],
                  [
                    95.442424,
                    27.36398
                  ],
                  [
                    95.437805,
                    27.364866
                  ],
                  [
                    95.436092,
                    27.365692
                  ],
                  [
                    95.428242,
                    27.374774
                  ],
                  [
                    95.425569,
                    27.375953
                  ],
                  [
                    95.424913,
                    27.377722
                  ],
                  [
                    95.421613,
                    27.379902
                  ],
                  [
                    95.417984,
                    27.381316
                  ],
                  [
                    95.411516,
                    27.387156
                  ],
                  [
                    95.406567,
                    27.386977
                  ],
                  [
                    95.403135,
                    27.387979
                  ],
                  [
                    95.399899,
                    27.388219
                  ],
                  [
                    95.395148,
                    27.389456
                  ],
                  [
                    95.392772,
                    27.390517
                  ],
                  [
                    95.38937,
                    27.391108
                  ],
                  [
                    95.386266,
                    27.390991
                  ],
                  [
                    95.383956,
                    27.389574
                  ],
                  [
                    95.381645,
                    27.38716
                  ],
                  [
                    95.380791,
                    27.387336
                  ],
                  [
                    95.379669,
                    27.389046
                  ],
                  [
                    95.378344,
                    27.389813
                  ],
                  [
                    95.376894,
                    27.389813
                  ],
                  [
                    95.375377,
                    27.38887
                  ],
                  [
                    95.374983,
                    27.38716
                  ],
                  [
                    95.373264,
                    27.384507
                  ],
                  [
                    95.36508,
                    27.383387
                  ],
                  [
                    95.360855,
                    27.382972
                  ],
                  [
                    95.359141,
                    27.383093
                  ],
                  [
                    95.356502,
                    27.384153
                  ],
                  [
                    95.353398,
                    27.386039
                  ],
                  [
                    95.349929,
                    27.390045
                  ],
                  [
                    95.348078,
                    27.394051
                  ],
                  [
                    95.345768,
                    27.393758
                  ],
                  [
                    95.340556,
                    27.39187
                  ],
                  [
                    95.336593,
                    27.390867
                  ],
                  [
                    95.333889,
                    27.390627
                  ],
                  [
                    95.327752,
                    27.390625
                  ],
                  [
                    95.322702,
                    27.390213
                  ],
                  [
                    95.321712,
                    27.388033
                  ],
                  [
                    95.319534,
                    27.387499
                  ],
                  [
                    95.31874,
                    27.38809
                  ],
                  [
                    95.315175,
                    27.389384
                  ],
                  [
                    95.309039,
                    27.389263
                  ],
                  [
                    95.308638,
                    27.390265
                  ],
                  [
                    95.306064,
                    27.391739
                  ],
                  [
                    95.306392,
                    27.392682
                  ],
                  [
                    95.304941,
                    27.393092
                  ],
                  [
                    95.305202,
                    27.396689
                  ],
                  [
                    95.303356,
                    27.39704
                  ],
                  [
                    95.30289,
                    27.398222
                  ],
                  [
                    95.301702,
                    27.399164
                  ],
                  [
                    95.29629,
                    27.401516
                  ],
                  [
                    95.296749,
                    27.403344
                  ],
                  [
                    95.296812,
                    27.40759
                  ],
                  [
                    95.295522,
                    27.415012
                  ],
                  [
                    95.293494,
                    27.41507
                  ],
                  [
                    95.292884,
                    27.413282
                  ],
                  [
                    95.291438,
                    27.413471
                  ],
                  [
                    95.289234,
                    27.412423
                  ],
                  [
                    95.289266,
                    27.410884
                  ],
                  [
                    95.287556,
                    27.411465
                  ],
                  [
                    95.283734,
                    27.411399
                  ],
                  [
                    95.282425,
                    27.410049
                  ],
                  [
                    95.279362,
                    27.40974
                  ],
                  [
                    95.276784,
                    27.409034
                  ],
                  [
                    95.276081,
                    27.409363
                  ],
                  [
                    95.275188,
                    27.412426
                  ],
                  [
                    95.273788,
                    27.411221
                  ],
                  [
                    95.271522,
                    27.412045
                  ],
                  [
                    95.270416,
                    27.411412
                  ],
                  [
                    95.268896,
                    27.409547
                  ],
                  [
                    95.266819,
                    27.408219
                  ],
                  [
                    95.264743,
                    27.406069
                  ],
                  [
                    95.262285,
                    27.406731
                  ],
                  [
                    95.257806,
                    27.40698
                  ],
                  [
                    95.25696,
                    27.407805
                  ],
                  [
                    95.253734,
                    27.407878
                  ],
                  [
                    95.254429,
                    27.405876
                  ],
                  [
                    95.252333,
                    27.40459
                  ],
                  [
                    95.250739,
                    27.405552
                  ],
                  [
                    95.249995,
                    27.404541
                  ],
                  [
                    95.248075,
                    27.404684
                  ],
                  [
                    95.248174,
                    27.405877
                  ],
                  [
                    95.246295,
                    27.409079
                  ],
                  [
                    95.244011,
                    27.414232
                  ],
                  [
                    95.243629,
                    27.417907
                  ],
                  [
                    95.244169,
                    27.42225
                  ],
                  [
                    95.24503,
                    27.425844
                  ],
                  [
                    95.247409,
                    27.429811
                  ],
                  [
                    95.251738,
                    27.433917
                  ],
                  [
                    95.250806,
                    27.434747
                  ],
                  [
                    95.248332,
                    27.434446
                  ],
                  [
                    95.244359,
                    27.434483
                  ],
                  [
                    95.239794,
                    27.435031
                  ],
                  [
                    95.235203,
                    27.435895
                  ],
                  [
                    95.229415,
                    27.437304
                  ],
                  [
                    95.225489,
                    27.438886
                  ],
                  [
                    95.221861,
                    27.441144
                  ],
                  [
                    95.220037,
                    27.443162
                  ],
                  [
                    95.219578,
                    27.4457
                  ],
                  [
                    95.219876,
                    27.452696
                  ],
                  [
                    95.21978,
                    27.454747
                  ],
                  [
                    95.21766,
                    27.458104
                  ],
                  [
                    95.217148,
                    27.459451
                  ],
                  [
                    95.217781,
                    27.460694
                  ],
                  [
                    95.222845,
                    27.466511
                  ],
                  [
                    95.224532,
                    27.469226
                  ],
                  [
                    95.225404,
                    27.471943
                  ],
                  [
                    95.227352,
                    27.483856
                  ],
                  [
                    95.228066,
                    27.493783
                  ],
                  [
                    95.229008,
                    27.498861
                  ],
                  [
                    95.235707,
                    27.500634
                  ],
                  [
                    95.244517,
                    27.503773
                  ],
                  [
                    95.248156,
                    27.504808
                  ],
                  [
                    95.251251,
                    27.50543
                  ],
                  [
                    95.254068,
                    27.50656
                  ],
                  [
                    95.258111,
                    27.507051
                  ],
                  [
                    95.261172,
                    27.507875
                  ],
                  [
                    95.264046,
                    27.508193
                  ],
                  [
                    95.266352,
                    27.50922
                  ],
                  [
                    95.266625,
                    27.510104
                  ],
                  [
                    95.265543,
                    27.518275
                  ],
                  [
                    95.264838,
                    27.520463
                  ],
                  [
                    95.264842,
                    27.521645
                  ],
                  [
                    95.265624,
                    27.523315
                  ],
                  [
                    95.268752,
                    27.523981
                  ],
                  [
                    95.270456,
                    27.525052
                  ],
                  [
                    95.272105,
                    27.526587
                  ],
                  [
                    95.271714,
                    27.527742
                  ],
                  [
                    95.269824,
                    27.529216
                  ],
                  [
                    95.265809,
                    27.530864
                  ],
                  [
                    95.26475,
                    27.53177
                  ],
                  [
                    95.264743,
                    27.533548
                  ],
                  [
                    95.266606,
                    27.537972
                  ],
                  [
                    95.266078,
                    27.539668
                  ],
                  [
                    95.264634,
                    27.541056
                  ],
                  [
                    95.265087,
                    27.543548
                  ],
                  [
                    95.265708,
                    27.545082
                  ],
                  [
                    95.269183,
                    27.547729
                  ],
                  [
                    95.274218,
                    27.550422
                  ],
                  [
                    95.276436,
                    27.549986
                  ],
                  [
                    95.281045,
                    27.546917
                  ],
                  [
                    95.282569,
                    27.547455
                  ],
                  [
                    95.281204,
                    27.550621
                  ],
                  [
                    95.281522,
                    27.552016
                  ],
                  [
                    95.280365,
                    27.554262
                  ],
                  [
                    95.278539,
                    27.558575
                  ],
                  [
                    95.276719,
                    27.561204
                  ],
                  [
                    95.273911,
                    27.564789
                  ],
                  [
                    95.272426,
                    27.56592
                  ],
                  [
                    95.273129,
                    27.567225
                  ],
                  [
                    95.272904,
                    27.569418
                  ],
                  [
                    95.274026,
                    27.571476
                  ],
                  [
                    95.273183,
                    27.574043
                  ],
                  [
                    95.274453,
                    27.57454
                  ],
                  [
                    95.275351,
                    27.573295
                  ],
                  [
                    95.276447,
                    27.570548
                  ],
                  [
                    95.277754,
                    27.569778
                  ],
                  [
                    95.279967,
                    27.569405
                  ],
                  [
                    95.281693,
                    27.569677
                  ],
                  [
                    95.281887,
                    27.568738
                  ],
                  [
                    95.284723,
                    27.567725
                  ],
                  [
                    95.287415,
                    27.569297
                  ],
                  [
                    95.288128,
                    27.570615
                  ],
                  [
                    95.287175,
                    27.57209
                  ],
                  [
                    95.287134,
                    27.573173
                  ],
                  [
                    95.288804,
                    27.573923
                  ],
                  [
                    95.289698,
                    27.576468
                  ],
                  [
                    95.291749,
                    27.576849
                  ],
                  [
                    95.293238,
                    27.575595
                  ],
                  [
                    95.294059,
                    27.575573
                  ],
                  [
                    95.295471,
                    27.576797
                  ],
                  [
                    95.295955,
                    27.579473
                  ],
                  [
                    95.299996,
                    27.579642
                  ],
                  [
                    95.30036,
                    27.58036
                  ],
                  [
                    95.299447,
                    27.581501
                  ],
                  [
                    95.299162,
                    27.583233
                  ],
                  [
                    95.300432,
                    27.584967
                  ],
                  [
                    95.30184,
                    27.584706
                  ],
                  [
                    95.302043,
                    27.583745
                  ],
                  [
                    95.303741,
                    27.582424
                  ],
                  [
                    95.305473,
                    27.582204
                  ],
                  [
                    95.308166,
                    27.583419
                  ],
                  [
                    95.310035,
                    27.58365
                  ],
                  [
                    95.312115,
                    27.584761
                  ],
                  [
                    95.31412,
                    27.585258
                  ],
                  [
                    95.315745,
                    27.586586
                  ],
                  [
                    95.317664,
                    27.5866
                  ],
                  [
                    95.318378,
                    27.587561
                  ],
                  [
                    95.317344,
                    27.589835
                  ],
                  [
                    95.316395,
                    27.592759
                  ],
                  [
                    95.316951,
                    27.595092
                  ],
                  [
                    95.316702,
                    27.596432
                  ],
                  [
                    95.315794,
                    27.59792
                  ],
                  [
                    95.313747,
                    27.599187
                  ],
                  [
                    95.313149,
                    27.600076
                  ],
                  [
                    95.310496,
                    27.607249
                  ],
                  [
                    95.308585,
                    27.610181
                  ],
                  [
                    95.308425,
                    27.613524
                  ],
                  [
                    95.307923,
                    27.615455
                  ],
                  [
                    95.306304,
                    27.619032
                  ],
                  [
                    95.305254,
                    27.622078
                  ],
                  [
                    95.301356,
                    27.632377
                  ],
                  [
                    95.299042,
                    27.638034
                  ],
                  [
                    95.297783,
                    27.641512
                  ],
                  [
                    95.296539,
                    27.644088
                  ],
                  [
                    95.296178,
                    27.645901
                  ],
                  [
                    95.292873,
                    27.654143
                  ],
                  [
                    95.289126,
                    27.663797
                  ],
                  [
                    95.286778,
                    27.665726
                  ],
                  [
                    95.281573,
                    27.671661
                  ],
                  [
                    95.280842,
                    27.673217
                  ],
                  [
                    95.277544,
                    27.676549
                  ],
                  [
                    95.276519,
                    27.678362
                  ],
                  [
                    95.273791,
                    27.679488
                  ],
                  [
                    95.272654,
                    27.680818
                  ],
                  [
                    95.271544,
                    27.68135
                  ],
                  [
                    95.269734,
                    27.681421
                  ],
                  [
                    95.270449,
                    27.682846
                  ],
                  [
                    95.27236,
                    27.685404
                  ],
                  [
                    95.273704,
                    27.689318
                  ],
                  [
                    95.274486,
                    27.690325
                  ],
                  [
                    95.282517,
                    27.695273
                  ],
                  [
                    95.287299,
                    27.69733
                  ],
                  [
                    95.291604,
                    27.697991
                  ],
                  [
                    95.295953,
                    27.697025
                  ],
                  [
                    95.298084,
                    27.697143
                  ],
                  [
                    95.301563,
                    27.697997
                  ],
                  [
                    95.303649,
                    27.698889
                  ],
                  [
                    95.311637,
                    27.699242
                  ],
                  [
                    95.315942,
                    27.69905
                  ],
                  [
                    95.320321,
                    27.699669
                  ],
                  [
                    95.331715,
                    27.699596
                  ],
                  [
                    95.334629,
                    27.699945
                  ],
                  [
                    95.337523,
                    27.699943
                  ],
                  [
                    95.340393,
                    27.700292
                  ],
                  [
                    95.346568,
                    27.70134
                  ],
                  [
                    95.358856,
                    27.706288
                  ],
                  [
                    95.360943,
                    27.707412
                  ],
                  [
                    95.363596,
                    27.70931
                  ],
                  [
                    95.366198,
                    27.713094
                  ],
                  [
                    95.369895,
                    27.71538
                  ],
                  [
                    95.371461,
                    27.71662
                  ],
                  [
                    95.377506,
                    27.720378
                  ],
                  [
                    95.379237,
                    27.721952
                  ],
                  [
                    95.382978,
                    27.72447
                  ],
                  [
                    95.386937,
                    27.726329
                  ],
                  [
                    95.39479,
                    27.731386
                  ],
                  [
                    95.39753,
                    27.735028
                  ],
                  [
                    95.398096,
                    27.736461
                  ],
                  [
                    95.397574,
                    27.738824
                  ],
                  [
                    95.396355,
                    27.741369
                  ],
                  [
                    95.396266,
                    27.743196
                  ],
                  [
                    95.397021,
                    27.744585
                  ],
                  [
                    95.401361,
                    27.750683
                  ],
                  [
                    95.404287,
                    27.755258
                  ],
                  [
                    95.404991,
                    27.757202
                  ],
                  [
                    95.405447,
                    27.765816
                  ],
                  [
                    95.405629,
                    27.773442
                  ],
                  [
                    95.40603,
                    27.777677
                  ],
                  [
                    95.407463,
                    27.779876
                  ],
                  [
                    95.40928,
                    27.781201
                  ],
                  [
                    95.417088,
                    27.784765
                  ],
                  [
                    95.419568,
                    27.786311
                  ],
                  [
                    95.421586,
                    27.788024
                  ],
                  [
                    95.42545,
                    27.789441
                  ],
                  [
                    95.42917,
                    27.789941
                  ],
                  [
                    95.433909,
                    27.792423
                  ],
                  [
                    95.436375,
                    27.793501
                  ],
                  [
                    95.446395,
                    27.797076
                  ],
                  [
                    95.453607,
                    27.799008
                  ],
                  [
                    95.455308,
                    27.799327
                  ],
                  [
                    95.458998,
                    27.800844
                  ],
                  [
                    95.461231,
                    27.80252
                  ],
                  [
                    95.463414,
                    27.804814
                  ],
                  [
                    95.464537,
                    27.806533
                  ],
                  [
                    95.468115,
                    27.812877
                  ],
                  [
                    95.470098,
                    27.815683
                  ],
                  [
                    95.470933,
                    27.817585
                  ],
                  [
                    95.471517,
                    27.820284
                  ],
                  [
                    95.473772,
                    27.824976
                  ],
                  [
                    95.473212,
                    27.826734
                  ],
                  [
                    95.473612,
                    27.830042
                  ],
                  [
                    95.477792,
                    27.833205
                  ],
                  [
                    95.47921,
                    27.835265
                  ],
                  [
                    95.481013,
                    27.838734
                  ],
                  [
                    95.482766,
                    27.848405
                  ],
                  [
                    95.485202,
                    27.853278
                  ],
                  [
                    95.487094,
                    27.855232
                  ],
                  [
                    95.491651,
                    27.856955
                  ],
                  [
                    95.496343,
                    27.85826
                  ],
                  [
                    95.497688,
                    27.858439
                  ],
                  [
                    95.5,
                    27.85812
                  ],
                  [
                    95.508268,
                    27.858571
                  ],
                  [
                    95.515181,
                    27.859479
                  ],
                  [
                    95.515164,
                    27.861487
                  ],
                  [
                    95.514512,
                    27.868038
                  ],
                  [
                    95.514354,
                    27.873786
                  ],
                  [
                    95.514575,
                    27.879456
                  ],
                  [
                    95.515336,
                    27.879723
                  ],
                  [
                    95.520132,
                    27.881238
                  ],
                  [
                    95.522272,
                    27.882959
                  ],
                  [
                    95.52409,
                    27.887147
                  ],
                  [
                    95.526161,
                    27.890618
                  ],
                  [
                    95.529007,
                    27.893791
                  ],
                  [
                    95.534036,
                    27.898142
                  ],
                  [
                    95.534954,
                    27.898227
                  ],
                  [
                    95.537613,
                    27.89692
                  ],
                  [
                    95.539465,
                    27.896421
                  ],
                  [
                    95.541395,
                    27.897111
                  ],
                  [
                    95.544269,
                    27.89978
                  ],
                  [
                    95.544664,
                    27.901131
                  ],
                  [
                    95.54278,
                    27.904121
                  ],
                  [
                    95.542191,
                    27.906268
                  ],
                  [
                    95.542663,
                    27.908089
                  ],
                  [
                    95.544669,
                    27.910842
                  ],
                  [
                    95.546092,
                    27.911673
                  ],
                  [
                    95.550191,
                    27.912511
                  ],
                  [
                    95.553832,
                    27.913729
                  ],
                  [
                    95.559762,
                    27.917594
                  ],
                  [
                    95.56269,
                    27.918544
                  ],
                  [
                    95.563765,
                    27.919628
                  ],
                  [
                    95.564536,
                    27.922816
                  ],
                  [
                    95.564595,
                    27.927355
                  ],
                  [
                    95.564815,
                    27.929273
                  ],
                  [
                    95.566095,
                    27.932474
                  ],
                  [
                    95.56807,
                    27.93534
                  ],
                  [
                    95.571931,
                    27.938516
                  ],
                  [
                    95.574224,
                    27.939464
                  ],
                  [
                    95.576883,
                    27.939648
                  ],
                  [
                    95.579304,
                    27.939107
                  ],
                  [
                    95.58472,
                    27.936569
                  ],
                  [
                    95.586113,
                    27.936724
                  ],
                  [
                    95.587538,
                    27.93801
                  ],
                  [
                    95.588643,
                    27.940594
                  ],
                  [
                    95.590983,
                    27.94419
                  ],
                  [
                    95.594576,
                    27.94753
                  ],
                  [
                    95.599181,
                    27.950268
                  ],
                  [
                    95.602016,
                    27.952919
                  ],
                  [
                    95.605278,
                    27.954615
                  ],
                  [
                    95.610471,
                    27.955285
                  ],
                  [
                    95.617087,
                    27.955544
                  ],
                  [
                    95.62486,
                    27.95598
                  ],
                  [
                    95.632316,
                    27.956506
                  ],
                  [
                    95.641247,
                    27.957338
                  ],
                  [
                    95.643209,
                    27.95762
                  ],
                  [
                    95.648277,
                    27.957923
                  ],
                  [
                    95.658899,
                    27.958864
                  ],
                  [
                    95.664391,
                    27.959179
                  ],
                  [
                    95.66971,
                    27.959718
                  ],
                  [
                    95.687922,
                    27.961062
                  ],
                  [
                    95.69549,
                    27.961812
                  ],
                  [
                    95.721879,
                    27.963747
                  ],
                  [
                    95.737735,
                    27.96515
                  ],
                  [
                    95.74594,
                    27.965604
                  ],
                  [
                    95.757581,
                    27.96663
                  ],
                  [
                    95.772546,
                    27.967693
                  ],
                  [
                    95.780407,
                    27.968357
                  ],
                  [
                    95.783944,
                    27.968472
                  ],
                  [
                    95.785575,
                    27.968767
                  ],
                  [
                    95.789493,
                    27.968882
                  ],
                  [
                    95.801821,
                    27.969683
                  ],
                  [
                    95.814089,
                    27.970682
                  ],
                  [
                    95.819353,
                    27.971203
                  ],
                  [
                    95.823881,
                    27.971475
                  ],
                  [
                    95.841882,
                    27.970325
                  ],
                  [
                    95.85758,
                    27.969498
                  ],
                  [
                    95.864426,
                    27.969025
                  ],
                  [
                    95.869975,
                    27.968822
                  ],
                  [
                    95.878076,
                    27.968326
                  ],
                  [
                    95.882273,
                    27.967875
                  ],
                  [
                    95.894541,
                    27.967021
                  ],
                  [
                    95.901538,
                    27.966749
                  ],
                  [
                    95.91317,
                    27.966001
                  ],
                  [
                    95.920168,
                    27.965458
                  ],
                  [
                    95.938273,
                    27.96439
                  ],
                  [
                    95.940895,
                    27.964163
                  ],
                  [
                    95.952705,
                    27.963544
                  ],
                  [
                    95.957944,
                    27.96345
                  ],
                  [
                    95.971287,
                    27.96272
                  ],
                  [
                    95.97182,
                    27.962178
                  ]
                ]
              ]
            ],
            "type": "MultiPolygon"
          },
          "id": 118,
          "properties": {
            "dist_code": "309",
            "dist_name": "Tinsukia",
            "state_code": "18",
            "state_name": "ASSAM"
          },
          "type": "Feature"
        }
      ],
      "type": "FeatureCollection"
    },
    "cogList": [],
    "bounds": null
  },
  "isEventsListViewOpen": false
}

const initialState = {
  sections: sections,
  currentSection: sections[0],
  JourneyData: null,
  journeyInputData: {
    journeyName: "",
    location: {
      stateDistrictDropdowns: {
        selectedState: {
          stateName: "",
          stateCode: "",
        },
        selectedDistrict: {
          districtName: "",
          districtCode: "",
        }
      },
    },
    contextVariable: {
      name: "",
      value: ""
    },
    date: dayjs().format('YYYY-MM-DD')
  },
  JourneyMap: {
    isLoading: false,
    geoJson: null,
    cogList: [],
    bounds: null,
    draw: null
  },
  isEventsListViewOpen: false,
  isSustainabilityReportOpen: false
}
const persistedState = storePersist.get('reduxState')

const pixStackJourneySlice = createSlice({
  name: "PixStackJourney",
  initialState: { ...initialState, ...persistedState?.PixStackJourney },

  reducers: {
    openEventsListView: (state) => {
      state.isEventsListViewOpen = true
    },
    openSustainabilityReport: (state) => {
      state.isSustainabilityReportOpen = true
    },
    closeSustainabilityReport: (state) => {
      state.isSustainabilityReportOpen = false
    },
    setDemoJourney: (state) => {
      state.JourneyData = demoData.JourneyData
      state.journeyInputData = demoData.journeyInputData
      state.JourneyMap = demoData.JourneyMap
      state.currentSection = sections[1]
    },
    closeEventsListView: (state) => {
      state.isEventsListViewOpen = false
    },
    setJourneyMapDraw: (state, action) => {
      state.JourneyMap.draw = action.payload
    },
    toggleEventsListView: (state) => {
      state.isEventsListViewOpen = !state.isEventsListViewOpen
    },
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload
    },
    handleNextSection: (state) => {
      if (state.currentSection.id === sections.length - 1) state.currentSection = sections[0]
      else state.currentSection = sections[state.currentSection.id + 1]
    },
    handlePrevSection: (state) => {
      if (state.currentSection.id === 0) state.currentSection = sections[0]
      else state.currentSection = sections[state.currentSection.id - 1]
    },
    setJourneyName: (state, action) => {
      state.journeyInputData = {
        ...state.journeyInputData,
        name: action.payload
      }
    },
    setContextVariable: (state, action) => {
      state.journeyInputData = {
        ...state.journeyInputData,
        contextVariable: action.payload
      }
    },
    setJourneyInputLocationData: (state, action) => {
      state.journeyInputData = {
        ...state.journeyInputData,
        location: {
          ...state.journeyInputData.location,
          ...action.payload
        }
      }
    },
    setJourneyInputData: (state, action) => {
      state.journeyInputData = {
        ...state.journeyInputData,
        ...action.payload
      }
    },
    setJourneyMapIsLoading: (state, action) => {
      state.JourneyMap.isLoading = action.payload
    },
    setJourneyMapGeoJson: (state, action) => {
      state.JourneyMap.geoJson = action.payload
      state.JourneyMap.bounds = null
    },
    setJourneyMapBounds: (state, action) => {
      state.JourneyMap.bounds = action.payload
    },
    setJourneyData: (state, action) => {
      state.JourneyData = action.payload
    },
    addCogToMap: (state, action) => {
      state.JourneyMap.cogList.push(action.payload)
    },
    updateCogAddedToMap: (state, action) => {
      state.JourneyMap.cogList = state.JourneyMap.cogList.map((item) => {
        if (item.rasterLayerUrl === action.payload.rasterLayerUrl){
          return action.payload
        }
        return item
      })
    },
    removeCogFromMap: (state, action) => {
      // state.JourneyMap.cogList.splice(state.JourneyMap.cogList.indexOf(action.payload), 1)
      state.JourneyMap.cogList = state.JourneyMap.cogList.filter((layer) => layer.rasterLayerUrl !== action.payload.rasterLayerUrl)
    },
    resetJourney: (state) => {
      state.sections = initialState.sections
      state.currentSection = initialState.currentSection
      state.JourneyData = initialState.JourneyData
      state.journeyInputData = initialState.journeyInputData
      state.JourneyMap = initialState.JourneyMap
      state.isEventsListViewOpen = false
    },

  }
})

export const {
  setDemoJourney,
  setJourneyMapBounds,
  openEventsListView,
  closeEventsListView,
  toggleEventsListView,
  setCurrentSection,
  handleNextSection,
  handlePrevSection,
  setJourneyName,
  setContextVariable,
  setJourneyInputLocationData,
  setJourneyData,
  setJourneyInputData,
  openSustainabilityReport,
  closeSustainabilityReport,
  setJourneyMapDraw,

  setJourneyMapIsLoading,
  setJourneyMapGeoJson,
  addCogToMap,
  updateCogAddedToMap,
  removeCogFromMap,
  resetJourney

} = pixStackJourneySlice.actions


export const selectIsEventsListViewOpen = (state) => state.PixStackJourney?.isEventsListViewOpen
export const selectIsSustainabilityReportOpen = (state) => state.PixStackJourney?.isSustainabilityReportOpen
export const selectSections = (state) => state.PixStackJourney?.sections
export const selectCurrentSection = (state) => state.PixStackJourney?.currentSection
export const selectJourneyData = (state) => state.PixStackJourney?.JourneyData
export const selectJourneyInputData = (state) => state.PixStackJourney?.journeyInputData

export const selectJourneyMapIsLoading = (state) => state.PixStackJourney?.JourneyMap.isLoading
export const selectJourneyMapGeoJson = (state) => state.PixStackJourney?.JourneyMap.geoJson
export const selectJourneyMapDraw = (state) => state.PixStackJourney?.JourneyMap.draw
export const selectJourneyMapBounds = (state) => state.PixStackJourney?.JourneyMap.bounds
export const selectCogList = (state) => state.PixStackJourney?.JourneyMap.cogList

export default pixStackJourneySlice