import queryString from 'query-string';
import { apiSlice } from "./apiSlice"

export const TasksApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListOfTasks: builder.query({
      query: (filters) => `/tasklist?${queryString.stringify(filters)}`
    }),
    getTrendAnalysis: builder.mutation({
      query: (payload) => ({ url: "/extreme_event", method: 'POST', body: payload })
    }),
    getFilteredTasks: builder.query({
      query: (filters) => `/task_details?${queryString.stringify(filters)}`,
    }),
  })
})

export const {
  useGetListOfTasksQuery,
  useGetTrendAnalysisMutation,
  useGetFilteredTasksQuery
} = TasksApiSlice 