import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import storePersist from "services/redux/storePersist";

const initialState = { accessToken: null, refreshToken: null, currentUser: null }
const persistedState = storePersist.get('reduxState')

const authSlice = createSlice({
  name: "auth",
  initialState: { ...initialState, ...persistedState?.auth },
  reducers: {
    setCredentials: (state, action) => {
      const tokenData = action.payload
      if (!tokenData.data.access_token) return
      const decoded = jwtDecode(tokenData.data.access_token)

      state.currentUser = decoded
      state.accessToken = tokenData.data.access_token
      state.refreshToken = tokenData.data?.refresh_token
    }
  }
})

export const { setCredentials, } = authSlice.actions

export const selectCurrentUser = (state) => state.auth?.currentUser
export const selectAccessToken = (state) => state.auth?.accessToken

export default authSlice