import { Card, IconButton } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Switch } from 'antd';

const EventRisk = ({
  layers = [],
  onChange = () => { }
}) => {
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex justify-between items-center'>
        <p className='text-base font-medium pl-2'>Layers</p>
        <IconButton centerRipple={false} className="!rounded hover:!bg-base-50 !bg-white !text-base-950" >
          <AddRoundedIcon fontSize='small' />
        </IconButton>
      </div>

      {layers?.map((layer, index) => {
        return (
          <Card key={index} elevation={0} className={`p-3 border border-gray-300 hover:border-gray-400`}>
            <div className="relative flex gap-4 justify-between align-middle">
              <div className='flex flex-col gap-1'>
                <p className="relative text-sm font-medium text-gray-700 break-words overflow-hidden leading-[18px]">
                  {layer.layer.layerName}
                </p>
                <p className="text-gray-400 text-xs">this is {layer.layer.layerName}</p>
              </div>
              <div className='flex justify-center items-center'>
                <Switch defaultChecked onChange={(e) => onChange({layer: layer.layer, checked: e})} size='small' className='bg-gray-400' />
              </div>
            </div>
          </Card>
        )
      })}

    </div>
  )
}

export default EventRisk