import React from 'react'

const EventSummery = () => {
  return (
    <div>
      <p>
        The Air Quality: NO₂ Satellite Measurements dataset provides global monthly averages of nitrogen dioxide (NO₂) density in the troposphere. Each value shown in the dataset represents the density of NO₂ between Earth’s surface and the top of the troposphere. The NO₂ density is reported with units of moles of NO₂ per square meter of air (mol/m²).
        Nitrogen dioxide (NO₂) is one of the most common compounds in the nitrogen oxides (NOx) group. Other nitrogen oxides include nitric acid (HNO₃) and nitric oxide (NO). NO₂ is used as the indicator for the larger group of nitrogen oxides, meaning if NO₂ is present it is likely other nitrogen oxides are as well. NO₂ is primarily created by the burning of fuel, which can be from cars, trucks and buses, power plants, and off-road equipment. Breathing air with a high concentration of NO₂ can irritate airways in the human respiratory system. Acute exposures can aggravate respiratory diseases, particularly asthma, leading to respiratory symptoms, like coughing, wheezing or difficulty breathing. Longer chronic exposures to elevated concentrations of NO₂ may contribute to the development of asthma and potentially increase susceptibility to respiratory infections. People with asthma, as well as children and the elderly are generally at greater risk for the health effects of NO₂.
      </p>
    </div>
  )
}

export default EventSummery