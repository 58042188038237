import { combineReducers } from 'redux';
import { apiSlice } from 'services/api/apiSlice';
import authSlice from 'services/auth/authSlice';
import eventSlice from 'components/MapDashboard/Events/eventSlice';
import MapDashboardSlice from 'components/MapDashboard/mapDashboardSlice';
import pixStackJourneySlice from 'components/PixStackJourney/pixStackJourneySlice';


const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authSlice.reducer,
  MapDashboardEvents: eventSlice.reducer,
  MapDashboard: MapDashboardSlice.reducer,
  PixStackJourney: pixStackJourneySlice.reducer
});

export default rootReducer;
