import React from 'react'

const LoadingDots = ({ className }) => {
  return (
    <span className={`LoadingDotContainer ${className}`}>
      <span className="LoadingDotOne"></span>
      <span className="LoadingDotTwo"></span>
      <span className="LoadingDotThree"></span>
    </span>
  )
}

export default LoadingDots