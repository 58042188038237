import Darklogo from 'assets/images/logo/LC_PIXSTACK_LOGO.png'
import Lightlogo from 'assets/images/logo/White_PIXSTACK_LOGO.png'

const HeaderLogo = () => {

  return (
    <a href='/' className=''>
      <img className='w-[100px] lg:w-[110px]' src={Lightlogo} />
    </a>
  )
}

export default HeaderLogo