import { useState, useEffect } from 'react';

export default function useIsTab(width = 768) {
  const [isTab, setIsTab] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsTab((window.innerWidth >= width) && (window.innerWidth < 1024)); // Adjust the breakpoint as per your requirements
    }

    handleResize(); // Initial check

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  return isTab;
}
