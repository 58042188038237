import React from 'react'
import { Layout } from 'antd'

const Header = ({children, className}) => {
  return (
    <Layout.Header className={`w-full h-[60px] overflow-hidden flex justify-between items-center bg-primary-900 !z-[500] !px-8 md:!px-14 lg:!px-20 ${className}`}>
      {children}
    </Layout.Header>
  )
}

export default Header
