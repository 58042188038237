import { Box, ClickAwayListener, IconButton, Paper, } from "@mui/material";
import Close from '@mui/icons-material/Close';
import { DrawRounded } from "@mui/icons-material";


const DrawControl = ({ drawEnabled, setDrawEnabled=()=>{} }) => {

  return (
    < ClickAwayListener onClickAway={() => {}} >
      <Box className="w-full flex justify-end">
        <Paper className='flex flex-col !h-fit !w-fit !rounded-sm'>
          <IconButton onClick={() => setDrawEnabled((prev) => !prev)} color="inherit" centerRipple={false} className="!rounded-sm">
            {drawEnabled ? <Close className='!text-[16px]' /> : <DrawRounded className='!text-[16px]' />}
          </IconButton>
        </Paper>
      </Box>
    </ClickAwayListener>
  )
}

export default DrawControl