import { Box, Card } from "@mui/material"

import EventCard from "./EventCard"
import NoResults from 'assets/images/no-results.jpg'

const EventList = ({
  className,
  events = [],
}) => {

  return (
    <Box className={`${className}`}>
      {
        events.length > 0 ? (
          <Card className='w-full h-full flex justify-center items-center !bg-gray-200 p-3'>
            <Box className={`h-full overflow-auto scroll-bar-hidden flex flex-col gap-2`}>
              {events.map((event, index) => {
                return (
                  <EventCard
                    key={index}
                    event={event}
                  />
                )
              })}
            </Box>
          </Card>
        ) : (
          <Card className='w-full h-full flex justify-center items-center !bg-gray-50 p-3'>
            <Box className="h-full flex flex-col space-y-[4%]">
              <Box className="flex justify-center">
                <img src={NoResults} className='w-[80%] mix-blend-multiply' />
              </Box>
              <Box className="text-center">
                <p className=" font-bold">No Events Found in the selected Location</p>
                <p className=" text-gray-500">Please select a different location or Try again later</p>
              </Box>
            </Box>
          </Card>
        )
      }
    </Box>
  )
}

export default EventList