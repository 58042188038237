import React, { useState } from 'react'
import { Divider, Layout, Menu, Typography } from 'antd'
import { Breadcrumb } from 'antd';

import Content from 'layout/Content'
import MenuSider from 'layout/MenuSider'
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';


const { Text } = Typography

const PageLayout = ({
  children,
  pageName = "",
  pagePath = "",
  showSideMenu = true,
  sideMenuOpen = true,
  menuItems = [],
  activeMenuItem = [],
  setActiveMenuItem = () => { },
  breadCrumbs = []
}) => {
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(sideMenuOpen !== false)
  const [openKeys, setOpenKeys] = useState([]);
  const [activeMenuItem_, setActiveMenuItem_] = useState(activeMenuItem || "");

  const handleOnSelect = (key) => {
    setActiveMenuItem_(key.keyPath)
    setActiveMenuItem(key.keyPath)
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <Layout className=''>
      {showSideMenu ? (
        <MenuSider
          pageName={pageName}
          pagePath={pagePath}
          open={menuOpen}
          setOpen={setMenuOpen}
        >
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            selectedKeys={activeMenuItem_}
            onSelect={handleOnSelect}
            // onClick={(value) => navigate(value.key)}
            style={{
              width: "100%",
            }}
            items={menuItems?.map((item) => {
              item.label = (<Text strong className='text-gray-700'>{item.label}</Text>)
              return item
            })}
          />
        </MenuSider>
      ) : null}
      <Content className={"p-5 px-10"}>
        {breadCrumbs.length > 0 ? (
          <Box className="h-fit">
            <Breadcrumb
              separator="/"
              items={breadCrumbs?.map((item, index) => {
                if (index === breadCrumbs.length - 1) {
                  item.className = "!text-black !font-semibold hover:!rounded-lg"
                }
                item.className = item.className + " !text-[15px] rounded !text-center"
                return item
              })}
            />
            <Divider className='my-5' />
          </Box>
        ) : null}

        <Box className="w-full h-[calc(90%-10px)]">
          {children}
        </Box>
      </Content>
    </Layout>
  )
}

export default PageLayout