import React, { useState } from 'react'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Table, Tag } from 'antd'
import { useNavigate } from 'react-router-dom';

const TasksTable = ({tasks}) => {

  const navigate = useNavigate()

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pageOptions, setPagination] = useState({
    "current": 1,
    "pageSize": 10,
    "simple": true,
    "total": tasks?.length || 0,
    "pageSizeOptions": [
      10,
      50,
      100,
      500,
      1000
    ]
  })

  const TaskStatuses = {
    "Tiff generation task Completed": {
      status: "success",
      icon: <CheckCircleOutlined />,
      color: "success"
    },
    "Task In Progress": {
      status: "Task In Progress",
      icon: <SyncOutlined spin />,
      color: "processing"
    },
    "Task Failed": {
      status: "error",
      icon: <CloseCircleOutlined />,
      color: "error"
    },
    InvalidInput: {
      status: "warning",
      icon: <ExclamationCircleOutlined />,
      color: "warning"
    }
  }

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'task_id',
    //   key: 'task_id',
    //   filteredValue: filteredInfo.task_id || null,
    //   onFilter: (value, record) => record.task_id.includes(value),
    //   sorter: (a, b) => a.task_id.length - b.task_id.length,
    //   sortOrder: sortedInfo.columnKey === 'task_id' ? sortedInfo.order : null,
    //   ellipsis: true,
    // },
    {
      title: 'Name',
      dataIndex: 'task_name',
      key: 'task_name',
      filteredValue: filteredInfo.task_name || null,
      onFilter: (value, record) => record.task_name.includes(value),
      sorter: (a, b) => a.task_name.length - b.task_name.length,
      sortOrder: sortedInfo.columnKey === 'task_name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Status',
      key: 'status',
      render: ({ status }) => <Tag icon={TaskStatuses[JSON.stringify(status)]?.icon || ""} color={TaskStatuses[JSON.stringify(status)]?.color || "default"}>{JSON.stringify(status)}</Tag>,
      onFilter: (value, record) => JSON.stringify(record.status).includes(value),
      sorter: (a, b) => JSON.stringify(a.status).length - JSON.stringify(b.status).length,
      sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 200,
      render: (operation) => (
        <div className='w-full flex justify-around'>
          <Button className='' onClick={() => navigate(`/data-pipeline/tasks/${operation.task_id}`)}>view</Button>
          <Button className='' onClick={() => navigate(`/data-pipeline/tasks/${operation.task_id}`)}>Download</Button>
        </div>
      ),
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    setPagination({ ...pageOptions, ...pagination })
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  return (
    <Table
      size="middle"
      columns={columns}
      dataSource={tasks}
      onChange={handleChange}
      bordered
      // footer={() => <div></div>}
      scroll={{
        y: 340,
      }}
      rowSelection
      loading={false}
      rowKey="task_id"
      pagination={{
        simple: true,
        current: pageOptions.current,
        pageSize: pageOptions.pageSize,
        total: pageOptions.total,
        pageSizeOptions: pageOptions.pageSizeOptions
      }}
    />
  )
}

export default TasksTable