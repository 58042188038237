import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "services/api/apiSlice"
import storePersist from "./storePersist"
import rootReducer from "./rootReducer"

const persistedState = storePersist.get('pixstackReduxState')

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(apiSlice.middleware),
    
  devTools: process.env.NODE_ENV !== 'production'
})

store.subscribe(() => {
  storePersist.set("pixstackReduxState", store.getState())
})