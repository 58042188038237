import {
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  InputBase,
  List, ListItem, ListItemText,
  Paper,
  Typography,
  Zoom
} from '@mui/material'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useEffect, useState } from 'react';
import DirectionsIcon from '@mui/icons-material/Directions';
import Close from '@mui/icons-material/Close';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import L from 'leaflet'

const SearchResults = ({ searchResults = [{ 'label': '' }], onClick = () => { } }) => {

  return (
    <List className='w-[250px] max-h-[300px] overflow-auto rounded' sx={{ bgcolor: 'background.paper', }}>
      {searchResults.map((item, index) => {
        return (
          <ListItem
            key={index}
            onClick={() => onClick(item)}
            className='!p-1 !px-3 hover:cursor-pointer hover:bg-gray-100 border-b'
          >
            <ListItemText
              secondary={
                <Typography
                  sx={{ display: 'inline', fontSize: "small" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {item.label}
                </Typography>
              }
            />
            <DirectionsIcon color='primary' />
          </ListItem>
        )
      })}
    </List>
  )
}

const SearchBar = ({ searchKey = "", setSearchKey = () => { } }) => {

  return (
    <Paper className="flex w-[250px]">
      <IconButton>
        <SearchRoundedIcon className='!text-[16px]' />
      </IconButton>

      <div className='py-2'><Divider sx={{}} orientation="vertical" /></div>
      <InputBase
        className='lg:p-[6px]'
        autoFocus
        sx={{ ml: 1, flex: 1, fontSize: "small" }}
        placeholder="Search a location"
        value={searchKey}
        inputProps={{ 'aria-label': 'search a location' }}
        onChange={(e) => { setSearchKey(e.target.value) }}
      />
    </Paper>
  )
}

const SearchControl = ({ handleSearchSelection = () => { } }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    provider.search({ query: searchKey }).then((results) => {
      setSearchResults(results)
      if (results.length > 0) {
        const bounds = L.latLngBounds(results[0].bounds)
        const geoJSONRectangle = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [bounds.getWest(), bounds.getSouth()], // [xmin, ymin]
                [bounds.getWest(), bounds.getNorth()], // [xmin, ymax]
                [bounds.getEast(), bounds.getNorth()], // [xmax, ymax]
                [bounds.getEast(), bounds.getSouth()], // [xmax, ymin]
                [bounds.getWest(), bounds.getSouth()]  // [xmin, ymin]
              ]
            ]
          }
        };

        console.log(geoJSONRectangle)
      }

    }).catch((err) => console.log("err", err));
  }, [searchKey])



  return (
    < ClickAwayListener onClickAway={() => setSearchOpen(false)} >
      <Box className="w-full flex justify-end">
        <Zoom className='absolute right-12' in={searchOpen} mountOnEnter unmountOnExit>
          <div className=''>
            <div className='flex flex-col gap-1'>
              <SearchBar searchKey={searchKey} setSearchKey={setSearchKey} />
              <SearchResults searchResults={searchResults} onClick={handleSearchSelection} />
            </div>
          </div>
        </Zoom>

        <Paper className='flex flex-col !h-fit !w-fit !rounded-sm'>
          <IconButton onClick={() => setSearchOpen((prev) => !prev)} color="inherit" centerRipple={false} className="!rounded-sm">
            {searchOpen ? <Close className='!text-[16px]' /> : <SearchRoundedIcon className='!text-[16px]' />}
          </IconButton>
        </Paper>
      </Box>
    </ClickAwayListener >
  )
}

export default SearchControl