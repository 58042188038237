import { MenuRounded } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React from 'react'

const HeaderLinks = () => {
  const links = [
    // {
    //   name: "HOME",
    //   url: "/"
    // },
    // {
    //   name: "DATA-PIPELINE",
    //   url: "/data-pipeline"
    // },
    {
      name: "DASHBOARD",
      url: "/dashboard"
    },
    {
      name: "CROP DASHBOARD",
      url: "/crop-dashboard"
    },
    {
      name: "CONTACT",
      url: "https://leadsnextech.com"
    }
  ]

  return (
    <Box className="">
      <Box className='md:hidden lg:hidden flex justify-center items-center align-middle'>
        <IconButton centerRipple={false} className='!rounded-none !p-0'>
          <MenuRounded className='text-white' />
        </IconButton>
      </Box>
      <Box className='hidden md:block lg:block md:space-x-3 lg:space-x-4'>
        {links.map((link, index) => {
          return (
            <a
              key={index}
              target={link.name === 'CONTACT' ? '_blank': ''}
              href={link.url}
              className='text-gray-200 hover:text-gray-300 text-[13px] font-medium tracking-wide'
            >
              {link.name}
            </a>
          )
        })}
      </Box>
    </Box>
  )
}

export default HeaderLinks