import { Close } from '@mui/icons-material'
import { Box, Divider, SwipeableDrawer, Typography } from '@mui/material'
import React from 'react'

const Drawer = ({
  children,
  className,
  position,
  title,
  open = false,
  onClose = () => { }
}) => {
  return (
    <SwipeableDrawer
      open={open}
      onClose={onClose}
      anchor={position || 'right'}
    >
      <Box className={`p-3 flex flex-col space-y-2`}>
        <Box className="flex text-center items-center align-middle h-8 space-x-3">
          <Close onClick={onClose} fontSize='small' className='!text-gray-500 hover:!text-gray-800 hover:!cursor-pointer' />
          <p className='!text-gray-900'>{title}</p>
        </Box>
        <Divider />
        <Box  className={`p-3 flex flex-col space-y-2 ${className}`}>{children}</Box>
      </Box>

    </SwipeableDrawer>
  )
}

export default Drawer