import L from 'leaflet'
import { EditControl } from "react-leaflet-draw";
import { MapContainer, useMap, GeoJSON, LayerGroup, TileLayer, LayersControl, FeatureGroup } from 'react-leaflet';
import { forwardRef, useEffect, useRef, useState } from 'react';
import ZoomControl from 'components/Map/MapControls/ZoomControl';
import BaseLayerControl from 'components/Map/MapControls/BaseLayerControl';
import SearchControl from 'components/Map/MapControls/SearchControl';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, Typography } from '@mui/material';
import Legend from './Legend';
import { useDispatch } from 'react-redux';
import { removeCogFromMap, selectCogList, selectJourneyMapDraw, setJourneyMapDraw, setJourneyMapIsLoading } from 'components/PixStackJourney/pixStackJourneySlice';
import * as d3 from "d3-scale-chromatic"
import DrawControl from './MapControls/DrawControl';
import { useSelector } from 'react-redux';
import SideBySideControl from './MapControls/SideBySideControl';
import "leaflet-side-by-side";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Map = ({
  key = 0,
  className,
  center = [20.5937, 79.9629],
  showControls = true,
  CogUrl,
  // rasterLayers = [],
  WidgetLegend,


  isLoading = false,
  geoJson,
  initialBounds,
  CogList = [],
  rasterList = []
}) => {

  const BaseLayers = [
    {
      name: "Satillite",
      url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
      attribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
    },
    {
      name: "Satillite With Labels",
      url: "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
      attribution: ""
    },
    {
      name: "Voyager",
      url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png",
      attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>"
    },
    {
      name: "Voyager with Labels",
      url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png",
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
    },
    {
      name: "Esri World Imagery",
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      attribution: ""
    },
    {
      name: "Terrain",
      url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
      attribution: ""
    }
  ]

  const [bounds, setBounds] = useState(initialBounds)
  const [baseLayer, setBaseLayer] = useState(BaseLayers[0])
  const [rasterLayers, setRasterLayers] = useState()
  const [fitBounds, setFitBounds] = useState(initialBounds)

  const [isCogLoading, setIsCogLoading] = useState(isLoading)
  const [CogNotReadyModalOpen, setCogNotReadyModalOpen] = useState(false)
  const [legendData, setLegendData] = useState(null)

  const dispatch = useDispatch()

  // useEffect(() => {
  //   setRasterLayers([])

  //   if (CogList.length === 0) return
  //   setIsCogLoading(true)
  //   CogList.map((CogUrl) => {

  //     const statsURL = "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/statistics"
  //     const layerURL = "https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/tilejson.json"
  //     const colorMapName = "magma"

      // fetch((`${statsURL}?url=${CogUrl}`)).then((statsResponse) => {
      //   if (statsResponse.status === 200) {
      //     statsResponse.json().then((stats) => {
      //       const LayerUrlWithFilters = `${layerURL}?url=${CogUrl}&rescale=${stats.b1?.min || 0},${stats.b1?.max || 0}&colormap_name=${colorMapName}`

      //       fetch(LayerUrlWithFilters).then((response) => {
      //         response.json().then((data) => {

      //           setRasterLayers((prev) => [...prev, data.tiles[0]])
      //           setIsCogLoading(false)
      //           setCogNotReadyModalOpen(false)
      //           setBounds([[data.bounds[1], data.bounds[0]], [data.bounds[3], data.bounds[2]]])
      //         })
      //       }).catch((err) => {
      //         dispatch(removeCogFromMap(CogUrl))
      //         setIsCogLoading(false)
      //         setCogNotReadyModalOpen(true)
      //       })
      //     })

      //   } else {
      //     dispatch(removeCogFromMap(CogUrl))
      //     setIsCogLoading(false)
      //     setCogNotReadyModalOpen(true)
      //   }
      // }).catch((err) => {
      //   dispatch(removeCogFromMap(CogUrl))
      //   setIsCogLoading(false)
      //   setCogNotReadyModalOpen(true)
      // })

  //   })

  // }, [CogList])

  useEffect(() => {
    if (geoJson) {
      setBounds(L.geoJSON(geoJson).getBounds())
    }
  }, [geoJson])

  useEffect(() => {
    setFitBounds(initialBounds)
  }, [initialBounds])

  return (
    <Box key={key} className={`w-full relative h-full overflow-hidden ${className}`}>
      <MapContainer
        key={key}
        center={center}
        bounds={bounds}
        zoom={4}
        style={{ height: '100%', width: '100%' }}
        zoomControl={false}
        className='relative'
      >
        <Dialog
          open={CogNotReadyModalOpen || false}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => { setCogNotReadyModalOpen(false) }}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Data Not Ready Yet !!!"}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-slide-description">
              Data Processing might take few minutes. please add layer after sometime.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={() => setCogNotReadyModalOpen(false)}>Cancel</Button>
            <Button className='!text-white !bg-primary-500' variant='contained' onClick={() => setCogNotReadyModalOpen()}>Ok</Button>
          </DialogActions>
        </Dialog>

        <MapControl
          showControls={showControls}
          BaseLayers={BaseLayers}
          setBaseLayer={setBaseLayer}
          baseLayer={baseLayer}
          bounds={bounds}
          fitBounds={fitBounds}
          setBounds={setBounds}
        />
        {WidgetLegend ? WidgetLegend : null}

        <GeoJSON
          key={JSON.stringify(geoJson)}
          data={geoJson}
          style={{ fillOpacity: rasterList.length > 0 ? "0" : geoJson?.category === "Sustainability Clip Layer" || geoJson?.category === "Hazard Clip Layer" ? "0.5" : "0.2" }}
          onEachFeature={(feature, layer) => {
            if (!geoJson.features) return

            if (geoJson.category === "Sustainability Clip Layer") {
              layer.options.color = "white"
              layer.options.fillOpacity = 1
              layer.options.weight = 2
              layer.options.dashArray = 1
              layer.options.opacity = 1

              let mean = feature.properties?.statistics.b1.mean
              let min = feature.properties?.statistics.b1.min
              let max = feature.properties?.statistics.b1.max

              const normalizedValue = (mean - min) / (max - min);
              const fillColor = d3.interpolateRdYlGn(normalizedValue);

              layer.setStyle({
                fillColor: mean < 0 ? "red" : mean > 0 && mean < 1 ? "yellow" : "green",
                weight: 2,
                opacity: 1,
                color: 'white',
                dashArray: '3',
                fillOpacity: 0.7
              })

              layer.on({
                click: (event) => {
                  const properties = event.target.feature.properties

                },
                mouseover: (event) => {
                  event.target.bringToFront()
                  setLegendData({
                    block_name: event.target.feature.properties?.sdtname,
                    area: (event.target.feature.properties?.statistics.b1.valid_pixels * 100) / 10000,
                    min: event.target.feature.properties?.statistics.b1.min?.toFixed(2),
                    max: event.target.feature.properties?.statistics.b1.max?.toFixed(2),
                    mean: event.target.feature.properties?.statistics.b1.mean?.toFixed(2)
                  })
                },
                mouseout: (event) => {
                  event.target.bringToBack()
                  event.target.setStyle({
                    color: "white",
                    weight: 2
                  });

                  setLegendData(null)
                }

              });
            }

            if (geoJson.category === "Hazard Clip Layer") {
              layer.options.color = "white"
              layer.options.fillOpacity = 1
              layer.options.weight = 2
              layer.options.dashArray = 1
              layer.options.opacity = 1

              let mean = feature.properties?.statistics.b1.mean
              let min = feature.properties?.statistics.b1.min
              let max = feature.properties?.statistics.b1.max

              const normalizedValue = (mean - min) / (max - min);
              const fillColor = d3.interpolateRdYlGn(normalizedValue);

              layer.setStyle({
                fillColor: mean < 0 ? "green" : mean > 0 && mean < 1 ? "yellow" : "red",
                weight: 2,
                opacity: 1,
                color: 'white',
                dashArray: '3',
                fillOpacity: 0.7
              })


              layer.on({
                click: (event) => {
                  const properties = event.target.feature.properties

                },
                mouseover: (event) => {
                  event.target.bringToFront()
                  setLegendData({
                    block_name: event.target.feature.properties?.sdtname,
                    area: (event.target.feature.properties?.statistics.b1.valid_pixels * 100) * 1 / 10000,
                    min: event.target.feature.properties?.statistics.b1.min?.toFixed(2),
                    max: event.target.feature.properties?.statistics.b1.max?.toFixed(2),
                    mean: event.target.feature.properties?.statistics.b1.mean?.toFixed(2)
                  })
                },
                mouseout: (event) => {
                  event.target.bringToBack()
                  event.target.setStyle({
                    color: "white",
                    weight: 2
                  });

                  setLegendData(null)
                }

              });
            }

          }}
        />

        <LayersControl>
          <LayersControl.BaseLayer checked>
            <TileLayer url={baseLayer.url} attribution={baseLayer.attribution} />
          </LayersControl.BaseLayer>

          <LayersControl.Overlay checked name=''>
            <LayerGroup>
              {
                [...rasterList]?.sort((a, b) => a.priority || 0 - b.priority || 0).map((layer, index) => {
                  return <TileLayer key={index} url={layer.rasterLayerUrl} opacity={layer?.opacity / 100} />
                })
              }
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>

        <Legend legendData={legendData} />

        <Backdrop
          sx={{ color: '#fff', zIndex: 440 }}
          open={isLoading || isCogLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop
          sx={{ color: '#fff', zIndex: 440 }}
          open={false}
        >
          <Box className="text-white flex flex-col justify-center gap-8 items-center">
            <CircularProgress className='!text-primary-200  rounded-full' />
            <Box className="flex flex-col justify-center items-center gap-1">
              <Typography variant='h5' className='!text-gray-300 !text-[100%]'>
                Please wait while we fetch your data ...
              </Typography>
              <Typography className='!text-gray-400 !text-[100%]'>
                Data processing may take a few minutes, please don't close or refresh this page.
              </Typography>
            </Box>
          </Box>
        </Backdrop>

      </MapContainer>

    </Box>
  )
}

export default Map

const MapControl = ({ showControls = true, baseLayer, bounds, fitBounds, setBounds, BaseLayers, setBaseLayer, geoJson }) => {
  const map = useMap();
  const [zoom, setZoom] = useState(4)

  const JourneyMapDraw = useSelector(selectJourneyMapDraw)
  const dispatch = useDispatch()

  const [drawEnabled, setDrawEnabled] = useState(false)
  var drawPolygon = new L.Draw.Polygon(map)

  map.on(L.Draw.Event.CREATED, (event) => {
    setDrawEnabled(false)
    if (JourneyMapDraw?.status === 'INITIALIZED') {
      dispatch(setJourneyMapDraw({
        ...JourneyMapDraw, status: "CREATED", geoJson: { ...event.layer.toGeoJSON(), name: JourneyMapDraw?.name }
      }))
      dispatch(setJourneyMapIsLoading(true))
    }

  })

  useEffect(() => {
    setDrawEnabled(Boolean(JourneyMapDraw?.status === 'INITIALIZED'))
  }, [{ ...JourneyMapDraw }])

  useEffect(() => {
    if (drawEnabled) {
      drawPolygon.enable()
    }
    else {
      drawPolygon.disable()
      dispatch(setJourneyMapDraw(null))
    }

    return (() => {
      drawPolygon.disable()
    })
  }, [drawEnabled])

  const [leftLayer, setLeftLayer] = useState(null)
  const [leftLayerInstance, setLeftLayerInstance] = useState(null)
  const [rightLayer, setRightLayer] = useState(null)
  const [rightLayerInstance, setRightLayerInstance] = useState(null)
  const [currentActiveLayers, setCurrentActiveLayers] = useState([])

  const CogList = useSelector(selectCogList)

  // useEffect(() => {
  //   setTimeout(() => {
  //     setCurrentActiveLayers([])
  //     map.eachLayer((layer) => {
  //       setCurrentActiveLayers((prev) => [...prev, layer])
  //     })
  //   }, [800])

  // }, [CogList])

  useEffect(() => {
    if (!map) return

    if (leftLayer && rightLayer) {
      let leftflag = false
      let rightflag = false

      if (!currentActiveLayers.length > 0 && (!leftLayerInstance || !rightLayerInstance)) {
        setCurrentActiveLayers([])
      }

      currentActiveLayers?.map((layer) => {
        if (layer._url === leftLayer.rasterLayerUrl || layer._url === rightLayer.rasterLayerUrl) {
          if (layer._url === leftLayer.rasterLayerUrl) {
            leftflag = true
            setLeftLayerInstance(new L.tileLayer(layer._url))
          } if (layer._url === rightLayer.rasterLayerUrl) {
            rightflag = true
            setRightLayerInstance(new L.tileLayer(layer._url))
          }
        }
      })

      map.eachLayer((layer) => {
        if (!currentActiveLayers.length > 0 && (!leftLayerInstance || !rightLayerInstance)) {
          setCurrentActiveLayers((prev) => [...prev, layer])
        }

        if (layer._url === leftLayer.rasterLayerUrl || layer._url === rightLayer.rasterLayerUrl) {
          if (layer._url === leftLayer.rasterLayerUrl) {
            leftflag = true
            setLeftLayerInstance(new L.tileLayer(layer._url))
          } if (layer._url === rightLayer.rasterLayerUrl) {
            rightflag = true
            setRightLayerInstance(new L.tileLayer(layer._url))
          }

        } else {
          if (layer._url !== baseLayer.url) {
            map.removeLayer(layer)
          }
        }
      })

      if(!leftflag || !rightflag){
        if (!leftflag){
          var layer = new L.tileLayer(leftLayer.rasterLayerUrl)
          setLeftLayerInstance(layer)
          setCurrentActiveLayers((prev) => [...prev, layer])
        }
        if (!rightflag){
          var layer = new L.tileLayer(rightLayer.rasterLayerUrl)
          setRightLayerInstance(layer)
          setCurrentActiveLayers((prev) => [...prev, layer])
        }
      }
      
    }
    else {
      currentActiveLayers?.map((layer) => {
        if (!map.hasLayer(layer)) {
          map.addLayer(layer)
        }
      })
      if (!leftLayer) {
        if (leftLayerInstance) {
          map.removeLayer(leftLayerInstance)
          setLeftLayerInstance(null)
        }
      }
      if (!rightLayer) {
        if (rightLayerInstance) {
          map.removeLayer(rightLayerInstance)
          setRightLayerInstance(null)
        }
      }
    }

  }, [leftLayer, rightLayer])

  useEffect(() => {

    var sideBySideControl = L.control.sideBySide(leftLayerInstance, rightLayerInstance)
    if (leftLayerInstance && rightLayerInstance) {
      leftLayerInstance.addTo(map)
      rightLayerInstance.addTo(map)
      sideBySideControl.addTo(map)
    }
    else {
      sideBySideControl.remove(map)
    }

    return (() => {
      sideBySideControl.remove(map)
    })
  }, [leftLayerInstance, rightLayerInstance])

  useEffect(() => {
    if (!map) return

    if (bounds) map.fitBounds(bounds)
    if (fitBounds) map.flyToBounds(fitBounds)
    if (geoJson) map.addLayer(geoJson)

  }, [map, bounds, baseLayer, geoJson, fitBounds])


  map.addEventListener("zoom", (event) => {
    if (zoom === event.target._zoom) return
    setZoom(event.target._zoom)
  })

  const zoomIn = () => map.zoomIn()
  const zoomOut = () => map.zoomOut()

  const controlRef = useRef()

  controlRef.current?.addEventListener('mouseout', (e) => {
    map._handlers.forEach(function (handler) {
      handler.enable();
    });
  })
  controlRef.current?.addEventListener('mouseover', (e) => {
    map._handlers.forEach(function (handler) {
      handler.disable();
    });
  })

  const handleSearchSelection = (item) => {
    setBounds(item.bounds)
  }

  if (!showControls) return

  return (
    <Box ref={controlRef} className='absolute right-6 top-6 !z-[450] flex flex-col space-y-1.5'>
      <ZoomControl
        zoom={zoom}
        minZoom={map.getMinZoom()}
        maxZoom={map.getMaxZoom()}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
      />
      <SearchControl handleSearchSelection={handleSearchSelection} />
      <BaseLayerControl baseLayers={BaseLayers} setBaseLayer={setBaseLayer} />
      <DrawControl drawEnabled={drawEnabled} setDrawEnabled={setDrawEnabled} />
      <SideBySideControl baseLayer={baseLayer} leftLayer={leftLayer} setLeftLayer={setLeftLayer} rightLayer={rightLayer} setRightLayer={setRightLayer} />
    </Box>
  )
}