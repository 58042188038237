import { DeblurRounded, InfoOutlined, OpenWithRounded, RemoveRedEyeRounded, VisibilityOffRounded } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { Divider, List, ListItem, Paper, Slide } from '@mui/material';
import { Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { removeEventFromMap } from './MapDashboard/Events/eventSlice';

const DraggableList = ({ eventsAddedToMap = [] }) => {
  const [events, setEvents] = useState(eventsAddedToMap);

  const dispatch = useDispatch()

  useEffect(() => {
    setEvents(eventsAddedToMap)
  }, [eventsAddedToMap])

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(events);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setEvents(newItems);
  };

  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [events]);

  if(!events.length > 0) return

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="w-[500px] max-h-[350px] overflow-auto  bg-gray-200 !p-2 space-y-2 "
          >
            {events?.map((event, index) => (
              <Draggable key={event.name} draggableId={event.name} index={index}>
                {(provided) => (
                  <ListItem
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className='w-full !p-0'
                  >
                    <Paper className='flex w-full justify-center align-top space-x-3 p-4'>
                      <OpenWithRounded fontSize='small' className='text-gray-400 mt-1.5' />
                      <div className='!py-1 !m-0'><Divider className=' !mx-2' sx={{ borderStyle: 'dashed' }} orientation="vertical" /></div>
                      <div className='w-full '>
                        <div className='w-full flex justify-between align-top cursor-auto'>
                          <Typography.Title level={5} className='-mt-[6px] !font-bold !text-gray-700' >
                            {event.name}
                          </Typography.Title>
                          <div className='flex justify-center space-x-3 text-xs text-gray-400'>
                            <DeblurRounded fontSize='small' className='hover:text-gray-600 cursor-pointer' />
                            <InfoOutlined fontSize='small' className='hover:text-gray-600 cursor-pointer' />
                            {false ? <RemoveRedEyeRounded fontSize='small' className='hover:text-gray-600 cursor-pointer' /> : <VisibilityOffRounded fontSize='small' className='hover:text-gray-600 cursor-pointer' />}
                            <Close onClick={()=> dispatch(removeEventFromMap(event)) } fontSize='small' className='hover:text-gray-600 cursor-pointer' />
                          </div>
                        </div>
                        <div className='h-20'>

                        </div>
                      </div>
                    </Paper>
                    <div ref={index === events.length - 1 ? scrollRef : null}></div>
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
