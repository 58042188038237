import React from 'react'
import { Divider, Drawer, Layout, Space, Typography } from 'antd'
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import useIsMobile from 'hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography

const MenuSider = ({ children, pageName, pagePath, className, open, setOpen }) => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  if (open) {
    if (isMobile) {
      return (
        <Drawer styles={{ body: { padding: 0 } }} open={open} onClose={() => setOpen(false)} placement='left' closable={false} width={"70%"} >
          <Layout.Sider width={"100%"} className={`!bg-white border border-black !h-full overflow-auto border-r ${className}`}>
            <Space className={`flex justify-between p-[1.08rem] pl-8 ${className}`}>
              <Title onClick={() => pagePath ? navigate(pagePath) : null} level={4} className='!text-lg tracking-wide z-10 !text-gray-800 hover:cursor-pointer hover:!text-base-900' style={{ margin: "0", fontWeight: "bolder" }}>
                {pageName}
              </Title>
              <Title onClick={() => setOpen(false)} level={5} style={{ margin: "0" }} className='!text-gray-500 hover:cursor-pointer hover:!text-gray-600'>
                <CloseOutlined />
              </Title>
            </Space>
            <Divider className='!my-0 !mb-2' />
            {children}
          </Layout.Sider>
        </Drawer>
      )
    }
    return (
      <Layout.Sider width={"16%"} className={`!bg-white !h-full !w-[16%] overflow-auto border-r ${className}`}>
        <Space className={`flex justify-between p-[1.08rem] pl-8 ${className}`}>
          <Title onClick={() => pagePath ? navigate(pagePath) : null} level={4} className='!text-lg tracking-wide !text-gray-800 hover:cursor-pointer hover:!text-base-900' style={{ margin: "0", fontWeight: "bolder" }}>
            {pageName}
          </Title>
          <Title onClick={() => setOpen(false)} level={5} style={{ margin: "0" }} className='!text-gray-500 hover:cursor-pointer hover:!text-gray-600'>
            <CloseOutlined />
          </Title>
        </Space>
        <Divider className='!my-0 !mb-2' />
        {children}
      </Layout.Sider>
    )
  }

  return (
    <Layout.Sider width={isMobile ? "10%" : "3%"} className={`!bg-white border-r ${className}`}>
      <Space className={`flex justify-center items-center p-2 ${className}`}>
        <Title onClick={() => setOpen(true)} level={5} style={{ margin: "0" }} className='!text-gray-500 hover:cursor-pointer hover:!text-gray-600'>
          <MenuOutlined />
        </Title>
      </Space>
    </Layout.Sider>
  )
}

export default MenuSider