import { Button, Space } from "antd";
import FeaturedProduct from "components/LandingPage/FeaturedProduct";
import HeroBanner from "components/LandingPage/HeroBanner";
import Pixstack from "components/LandingPage/pixstack";
import PixstackDeliveryMechanism from "components/LandingPage/pixstackDeliveryMechanism";
import PixStackOfferings from "components/LandingPage/PixStackOfferings";
import PixstackTech from "components/LandingPage/pixstackTech";
import Header from "layout/Header";
import HeaderLogoSection from "layout/Header/LogoSection";
import React from "react";

const Home = () => {
  return (
    <>
      <div className="">
        {/* <div
        className="absolute inset-x-0 -top-50 z-[1] transform-gpu overflow-hidden blur-3xl sm:-top-80 h-[100vh];"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div> */}
        {/* <Header className="sticky border-b top-0 lg:h-[55px]">
          <Space className='container w-full justify-between'>
            <HeaderLogoSection />
            <Button href="/dashboard" className="!bg-base-950 !px-6 !text-orange-400 ">Sign In</Button>
          </Space>
        </Header> */}

        <HeroBanner />
        {/* <Slide /> */}
     {/* <Pixstack />
        <FeaturedProduct />
        <PixstackTech />
        <PixStackOfferings />
        <PixstackDeliveryMechanism /> */}
      
      </div>
    </>
  );
};

export default Home;
