import React from 'react'
import HeaderLogo from './HeaderLogo'

const HeaderLogoSection = () => {
  return (
    <div className='flex justify-center items-center'>
      <HeaderLogo />
    </div>
  )
}

export default HeaderLogoSection