import React from 'react'
import { ConfigProvider } from 'antd'

import CustomColorConfig from '../theme/colors';

const colors = CustomColorConfig.colors

const AntDProvider = ({ children }) => {
  return (
    <ConfigProvider theme={{
      components: {
        Button: {

        },
        
        Tabs: {
          inkBarColor: colors.primary[500],
          itemActiveColor: "#555",
          itemSelectedColor: "#555",
          itemHoverColor: "#777",
          itemColor: "#777",
          horizontalItemGutter: "20px",
          horizontalItemPadding: "12px 12px",
          horizontalMargin: "0 0 12px 0",
        },
        Switch: {
          trackHeightSM: "16.4px",
          trackMinWidthSM: "30px",
        },
        Input: {
          hoverBorderColor: "#737373",
          activeBorderColor: "#737373"
        },
        Slider: {
          handleColor: "#dedede",
          trackBg: "#0070cc",
          trackHoverBg: "#0070cc"
        }
      },
      token: {
        borderRadius: "2",
        
      }
    }}>
      {children}
    </ConfigProvider>
  )
}

export default AntDProvider