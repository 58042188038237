import TaskDetails from 'components/Task/TaskDetails'
import PageLayout from 'layout/PageLayout'
import React from 'react'
import { useParams } from 'react-router-dom'

const Task = () => {
  const { id: taskId } = useParams()

  const breadcrumbs = [
    {
      title: 'Dashboard',
      href: '/dashboard'
    },
    {
      title: 'Data Pipeline',
      href: '/data-pipeline'
    },
    {
      title: 'Tasks',
      href: '/data-pipeline/tasks'
    },
    {
      title: taskId,
      href: `/data-pipeline/tasks/${taskId}`
    }
  ]
  return (
    <PageLayout
      showSideMenu={false}
      breadCrumbs={breadcrumbs}
    >
      <TaskDetails taskId={taskId} />
    </PageLayout>
  )
}

export default Task