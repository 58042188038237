import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useEffect } from 'react';
import { setCredentials } from './authSlice';


const RequireAuth = () => {

	const auth = useAuth();
	const dispatch = useDispatch()

	// useEffect(() => {
	// 	if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
	// 		auth.signinRedirect();
	// 	}
	// }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

	// useEffect(() => {
	// 	if (auth.isAuthenticated && auth.user) {
	// 		dispatch(setCredentials({ data: { access_token: auth.user.access_token, refresh_token: auth.user.refresh_token } }))
	// 	}
	// }, [auth.isAuthenticated, auth.user])

	// useEffect(() => {
	// 	if (auth.error) {
	// 		if (hasAuthParams()) {
	// 			window.history.replaceState( {}, document.title, window.location.pathname )
	// 			auth.signinSilent()
	// 		}else {
	// 			auth.removeUser().then(() => {
	// 				auth.signinRedirect()
	// 			})
	// 		}
	// 	}
	// }, [auth.error])

	// if (auth.isLoading) {
	// 	return "Loading ..."
	// }
	// else if (auth.error) {
	// 	return `Oops... ${auth.error.message}`
	// }
	// else if (auth.isAuthenticated) {
	// 	return <Outlet />
	// }

	switch (auth.activeNavigator) {
		case "signinSilent":
			return <div>Signing you in...</div>;
		case "signoutRedirect":
			return <div>Signing you out...</div>;
	}

	if (auth.isLoading) {
		return <div>Loading...</div>;
	}

	if (auth.error) {
		return <div>Oops... {auth.error.message}</div>;
	}

	if (auth.isAuthenticated) {
		dispatch(setCredentials({ data: { access_token: auth.user.access_token, refresh_token: auth.user.refresh_token } }))
		return (
			<Outlet />
		);
	}

	auth.signinRedirect()
}

export default RequireAuth