import { Box, Button, TextField } from '@mui/material'
import React from 'react'
import Select from 'react-select'

const CreateTask = () => {
  return (
    <Box>
      <Box className='w-full flex flex-col space-y-[6%]'>
        <Box className='w-full flex space-x-[4%] justify-between'>
          <TextField size='small'></TextField>
          <Select
            className="bg-gray-50 rounded w-[50%] lg:w-40"
            onChange={() => { }}
            options={[{ label: 'UP', value: '9' }]}
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
                borderColor: "#cccccc",
                "&:hover": {
                  borderColor: "#cccccc"
                }
              })
            }}
            placeholder="State"
            isSearchable={true}
          />
          <Select
            className="bg-gray-50 rounded w-[50%] lg:w-40"
            onChange={() => { }}
            options={[]}
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
                borderColor: "#cccccc",
                "&:hover": {
                  borderColor: "#cccccc"
                }
              })
            }}
            placeholder="District"
            isSearchable={true}
          />
        </Box>
        <Button
          disableElevation
          variant='contained'
          size='medium'
          onClick={() => { }}
          className='!px-6 !py-2.5 '
        >
          Submit
        </Button>
      </Box>
    </Box>
  )
}

export default CreateTask