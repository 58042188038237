import React from 'react';
import { CaretDownOutlined, UserOutlined, LogoutOutlined, SettingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space, Typography } from 'antd';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'services/auth/authSlice';
import { store } from 'services/redux/store';
import storePersist from 'services/redux/storePersist';

const { Title, Text } = Typography;

const HeaderProfile = () => {

  const auth = useAuth();
  const currentUser = useSelector(selectCurrentUser)

  const handleLogOut = () => {
    store.replaceReducer(() => undefined)
    storePersist.clear()
    auth.removeUser()
    auth.signoutRedirect()
  }

  const items = [
    {
      label: (
        <Space size="middle">
          <Avatar shape="square" icon={<UserOutlined />} />
          <Space.Compact direction="vertical">
            <Title level={5} style={{ margin: "0" }}>{currentUser?.name}</Title>
            <Text type='secondary'>{currentUser?.email}</Text>
          </Space.Compact>
        </Space>
      ),
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Space className='rounded w-full !p-2'>
          <QuestionCircleOutlined />
          Help
        </Space>
      ),
      key: '1',
    },
    {
      label: (
        <Space className='rounded w-full !p-2'>
          <SettingOutlined />
          Settings
        </Space>
      ),
      key: '2',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Space onClick={handleLogOut} className='bg-purple-100 hover:bg-purple-200 font-semibold rounded w-full !p-3'>
          <LogoutOutlined />
          Log Out
        </Space>
      ),
      key: '3',
      disabled: false,
      className: "!p-0 !m-2"
    },

  ];

  return (
    <div className='hidden md:flex lg:flex relative justify-center'>
      {/* <div className='absolute -top-12 bg-secondary-950 -rotate-[60deg] w-44 h-44 z-0'></div>
      <div className='absolute left-12 -top-14 bg-secondary-950 -rotate-[00deg] w-48 h-44 z-0'></div> */}
      <div className='z-10'>
        <Dropdown
          menu={{
            items,
            onClick: () => { },
          }}
          trigger={['click']}
          placement='bottomRight'
          className=''
        >
          <button className={``} onClick={(e) => e.preventDefault()}>
            <Space className='text-gray-50 font-bold tracking-wider text-[14px]'>
              {currentUser?.name}
              <CaretDownOutlined className="text-xs" />
            </Space>
          </button>
        </Dropdown>
      </div>
    </div>
  )
  return (
    <div className='bg-secondary-950 w-60 h-44 -rotate-[65deg] flex justify-center'>
      <Dropdown
        menu={{
          items,
          onClick: () => { },
        }}
        trigger={['click']}
        placement='bottomRight'
        className=''
      >
        <button className={``} onClick={(e) => e.preventDefault()}>
          <Space className='text-primary-950 font-bold tracking-wide rotate-[65deg] text-lg'>
            {currentUser?.name}
            <CaretDownOutlined className="text-xs" />
          </Space>
        </button>
      </Dropdown>
    </div>

  )
}

export default HeaderProfile