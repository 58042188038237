import { createSlice } from "@reduxjs/toolkit";
import storePersist from "services/redux/storePersist";

const initialState = {
  eventsAddedToMap: [],
  selectedEvent: {
    event: {},
    tabId: ""
  }
}
const persistedState = storePersist.get('reduxState')

const eventSlice = createSlice({
  name: "MapDashboardEvents",
  initialState: { ...initialState, ...persistedState?.MapDashboardEvents },

  reducers: {
    addEventToMap: (state, action) => {
      const event = action.payload
      state.eventsAddedToMap.push(event)
    },
    removeEventFromMap: (state, action) => {
      const event = action.payload
      if (!state.eventsAddedToMap.length > 0) return
      state.eventsAddedToMap = state.eventsAddedToMap.filter(obj => JSON.stringify(obj) !== JSON.stringify(event));
    },
    setSelectedEvent: (state, action) => {
      const event = action.payload
      state.selectedEvent = event
    },
    removeSelectedEvent: (state) => {
      state.selectedEvent = null
    }
  }
})

export const { addEventToMap, removeEventFromMap, setSelectedEvent, removeSelectedEvent } = eventSlice.actions

export const selectEventsAddedToMap = (state) => state.MapDashboardEvents?.eventsAddedToMap
export const selectSelectedEvent = (state) => state.MapDashboardEvents?.selectedEvent

export default eventSlice