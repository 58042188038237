import { Box, Button, Card, CardContent, CardMedia, IconButton } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Divider, Tabs } from 'antd';
import EventRisk from './EventRisk';
import EventImpact from './EventImpact';
import EventSummery from './EventSummery';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeSelectedEvent, selectSelectedEvent } from './eventSlice';
import { closeMenu } from '../mapDashboardSlice';
import EventCard from './EventCard';


const items = [
  {
    key: 'Summery',
    label: 'Summary',
    children: <EventSummery />,
  },
  {
    key: 'Risk',
    label: 'Risk',
    children: <EventRisk />,
  },
  {
    key: 'Impact',
    label: 'Impact',
    children: <EventImpact />,
  },
  {
    key: 'Sustainability',
    label: 'Resilience / Sustainability',
    children: 'Resilience / Sustainability',
  }
];

const EventInfo = () => {

  const selectedEvent = useSelector(selectSelectedEvent)
  const [activeTab, setActiveTab] = useState(selectedEvent?.tabId)

  useEffect(() => {
    setActiveTab((previousTab) => selectedEvent?.tabId || previousTab)
  }, [selectedEvent])

  const onChange = (key) => {
    setActiveTab(key)
  };

  // return (
  //   <Box className="w-full h-full">
  //     <Box style={{ background: `url('${selectedEvent?.thumbnailUrl}')` }} className={`relative h-[20%] !bg-contain`}>
  //       <div className="bg-black opacity-50 h-full z-0">
  //       </div>
  //     </Box>
  //     <Card className='w-full h-[80%]'>
  //       <CardContent className='w-full h-full overflow-auto flex flex-col space-y-[4%]'>
  //         <Box>
  //           <p className="relative text-xl font-medium py-2 text-gray-700 break-words overflow-hidden leading-[22px]">{selectedEvent?.name}</p>
  //           <p className="text-gray-400 text-xs">{selectedEvent?.date}</p>
  //         </Box>
  //         <Box className="flex flex-wrap gap-2">
  //           {selectedEvent?.name?.split(' ').map((tag) => {
  //             return (
  //               <p className='text-gray-400 text-xs border border-dotted border-gray-300 px-1 hover:cursor-pointer hover:text-gray-600 font-medium tracking-wide !transition-all !duration-300 !ease-out'>#{tag}</p>
  //             )
  //           })}
  //         </Box>
  //         <Box className="">
  //           <Box className="">
  //             <p className="relative text-[16px] mb-2 font-medium text-gray-600 break-words overflow-hidden leading-[16px]">
  //               Summery
  //             </p>
  //           </Box>
  //           <Divider className='!my-3 p-0' />
  //           <EventSummery />
  //         </Box>
  //         <Box className="border-t border-b py-2">
  //           <Box className="py-3">
  //             <p className="relative text-[16px] mb-2 font-medium text-gray-600 break-words overflow-hidden leading-[16px]">
  //               Related Events
  //             </p>
  //           </Box>
  //           <Box className="w-[440px] overflow-auto scroll-bar-hidden flex flex-grow mx-4 rounded">
  //             <Box className="flex space-x-4">
  //               {[selectedEvent, selectedEvent, selectedEvent, selectedEvent, selectedEvent].map((event) => {
  //                 return (
  //                   <Card elevation={0} className='w-[140px] border'>
  //                     <CardMedia
  //                       className='!h-16'
  //                       component="img"
  //                       image={event?.thumbnailUrl}
  //                       alt={event?.name}
  //                     />
  //                     <CardContent>
  //                       <Box className="flex flex-col space-y-5 justify-center">
  //                         <Box>
  //                           <p className="relative text-xs mb-2 font-medium text-gray-700 break-words overflow-hidden leading-[16px]">{selectedEvent?.name}</p>
  //                           <p className="text-gray-400 text-[8px]">{selectedEvent?.date}</p>
  //                         </Box>
  //                         <Button size='small' className='!bg-primary-950 !text-secondary-500'>View</Button>
  //                       </Box>
  //                     </CardContent>
  //                   </Card>
  //                 )
  //               })}
  //             </Box>
  //           </Box>
  //           <Box className="mt-3">
  //             <Button size='small'>View all</Button>
  //           </Box>
  //         </Box>

  //       </CardContent>
  //     </Card>
  //   </Box>
  // )

  return (
    <Box className="flex h-full flex-col w-full">
      <header style={{ background: `url('${selectedEvent?.thumbnailUrl}')` }} className={`relative h-[25%] !bg-contain`}>
        <div className="bg-black opacity-50 h-full z-0">
        </div>
      </header>
      <Card
        elevation={2}
        className="mx-4 h-[80%] -mt-14 z-10 !overflow-auto scroll-bar-hidden"
      >
        <CardContent>
          <div>
            <p className="relative text-lg font-medium py-2 text-gray-700 break-words overflow-hidden leading-[22px]">{selectedEvent?.name}</p>
            <p className="text-gray-400 text-xs">{selectedEvent?.date}</p>
          </div>
          <Tabs
            className='!w-full'
            activeKey={activeTab}
            items={items}
            onChange={onChange}
          />
        </CardContent>
      </Card>
    </Box>
  )
}

export default EventInfo