import { RefreshRounded } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CircularProgress, IconButton } from '@mui/material'
import MapDashboard from 'components/MapDashboard'
import { useState } from 'react';
import Select from 'react-select';
import { useGetFilteredTasksQuery } from 'services/api/tasksApiSlice';


const TaskDetails = ({ taskId }) => {

  const [taskAPIFilters, setTaskAPIFilters] = useState({ taskId: taskId })
  const {
    data: taskDetailsData,
    isLoading: isDetailsDataLoading,
    isFetching: isTaskDetailsDataFetching,
    // isSuccess: isDetailsDataSuccess,
    // isError: isDetailsDataError,
    refetch: taskDetailsDataRefetch,
    // error: taskDetailsDataError
  } = useGetFilteredTasksQuery(taskAPIFilters)

  console.log(taskDetailsData)

  return (
    <Box className="h-full w-full space-y-5">
      <Card className='w-full h-full'>
        <CardContent className='w-full h-full space-y-3'>
          <Box className="flex justify-end h-[40px] px-2">
            <IconButton size='small' onClick={() => taskDetailsDataRefetch()} variant='outlined' className='!rounded-sm !h-fit'>
              {isTaskDetailsDataFetching ? <CircularProgress className='!text-primary-700' size={"1.1rem"} sx={{ margin: "3.2px" }} /> : <RefreshRounded />}
            </IconButton>
          </Box>
          <MapDashboard className={"rounded border !h-[92%]"}>
            <Box className="bg-white w-full h-full p-4">
              <Box className='w-full flex flex-col space-y-[6%]'>
                <Box className='w-full flex flex-col space-y-[6%] justify-between'>
                  <Select
                    className="bg-gray-50 rounded w-[50%] lg:w-60"
                    onChange={() => { }}
                    options={[{ label: 'UP', value: '9' }]}
                    styles={{
                      control: (base) => ({
                        ...base,
                        boxShadow: "none",
                        borderColor: "#cccccc",
                        "&:hover": {
                          borderColor: "#cccccc"
                        }
                      })
                    }}
                    placeholder="State"
                    isSearchable={true}
                  />
                  <Select
                    className="bg-gray-50 rounded w-[50%] lg:w-60"
                    onChange={() => { }}
                    options={[]}
                    styles={{
                      control: (base) => ({
                        ...base,
                        boxShadow: "none",
                        borderColor: "#cccccc",
                        "&:hover": {
                          borderColor: "#cccccc"
                        }
                      })
                    }}
                    placeholder="District"
                    isSearchable={true}
                  />
                  <Select
                    className="bg-gray-50 rounded w-[50%] lg:w-60"
                    onChange={() => { }}
                    options={[]}
                    styles={{
                      control: (base) => ({
                        ...base,
                        boxShadow: "none",
                        borderColor: "#cccccc",
                        "&:hover": {
                          borderColor: "#cccccc"
                        }
                      })
                    }}
                    placeholder="District"
                    isSearchable={true}
                  />
                </Box>

              </Box>
            </Box>
          </MapDashboard>
        </CardContent>
      </Card>
    </Box>
  )
}

export default TaskDetails