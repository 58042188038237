import React, { forwardRef, useEffect, useState } from 'react'
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Skeleton,
  Slide,
  Slider,
  Tooltip,
  alpha
} from '@mui/material'
import { AddRounded, Close, CloudDownloadRounded, CloudUpload, ContentCutRounded, CropRounded, DeblurRounded, Description, DrawOutlined, DrawRounded, DriveFolderUploadRounded, Edit, ExpandMore, FileDownload, FileDownloadOutlined, FileUpload, FileUploadOutlined, FlipToBackRounded, FlipToFrontRounded, InfoOutlined, LocationSearching, MoreVert, Notes, PhotoSizeSelectLarge, Summarize, VisibilityRounded } from '@mui/icons-material'

import PixStackJourney from 'components/PixStackJourney'
import {
  addCogToMap,
  closeEventsListView,
  closeSustainabilityReport,
  handleNextSection,
  handlePrevSection,
  openSustainabilityReport,
  removeCogFromMap,
  resetJourney,
  selectCogList,
  selectCurrentSection,
  selectIsEventsListViewOpen,
  selectIsSustainabilityReportOpen,
  selectJourneyData,
  selectJourneyInputData,
  selectJourneyMapDraw,
  selectJourneyMapGeoJson,
  selectSections,
  setContextVariable,
  setCurrentSection,
  setDemoJourney,
  setJourneyData,
  setJourneyInputData,
  setJourneyInputLocationData,
  setJourneyMapBounds,
  setJourneyMapDraw,
  setJourneyMapGeoJson,
  setJourneyMapIsLoading,
  updateCogAddedToMap
} from 'components/PixStackJourney/pixStackJourneySlice'
import { useGetDistrictsByFiltersQuery, useGetStatesListQuery } from 'services/api/masterDataApiSlice'
import * as d3 from "d3-scale-chromatic"
import * as L from 'leaflet'

import LocationImg from 'assets/images/location.jpg'
import NoResults from 'assets/images/no-results.jpg'
import { Switch, Tabs } from 'antd'
import { useGetFilteredTasksQuery, useGetListOfTasksQuery, useGetTrendAnalysisMutation } from 'services/api/tasksApiSlice'
import LoadingDots from 'components/LoadingDots'
import ReactApexChart from 'react-apexcharts'
import GaugeChart from 'components/GuageChart'

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from '@emotion/styled'
import Map from 'components/Map'
import JourneyReport from 'components/PixStackJourney/JourneyReport'
import { usePDF } from 'react-to-pdf'
import GoogleSearchBar from 'components/GoogleSearchBar'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export function ResponsiveDatePickers() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'DatePicker',
          'MobileDatePicker',
          'DesktopDatePicker',
          'StaticDatePicker',
        ]}
      >
        <DemoItem label="Responsive variant">
          <DatePicker defaultValue={dayjs('2022-04-17')} />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}

const DummyApiResponse = {
  "data": {
    "current_status": "In Progress",
    "event_intensity": [
      {
        "cog_status": true,
        "layer_name": "High",
        "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/high_intensity.tif"
      },
      {
        "cog_status": true,
        "layer_name": "Moderate",
        "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/medium_intensity.tif"
      },
      {
        "cog_status": true,
        "layer_name": "Low",
        "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/low_intensity.tif"
      }
    ],
    "impact_analysis": [
      {
        "average_score": 0,
        "cog_status": false,
        "layer_name": "High",
        "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/high_impact.tif"
      },
      {
        "average_score": 0,
        "cog_status": false,
        "layer_name": "Moderate",
        "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/medium_impact.tif"
      },
      {
        "average_score": 0,
        "cog_status": false,
        "layer_name": "Low",
        "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/low_impact.tif"
      }
    ],
    "sustainability": [
      {
        "average_score": 0,
        "cog_status": false,
        "layer_name": "High",
        "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/sustain_high.tif"
      },
      {
        "average_score": 0,
        "cog_status": false,
        "layer_name": "Moderate",
        "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/sustain_medium.tif"
      },
      {
        "average_score": 0,
        "cog_status": false,
        "layer_name": "Low",
        "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/sustain_low.tif"
      }
    ],
    "task_id": "cadb9338-270d-4186-9254-b21cd12dae64",
    "trend_analysis": {
      "max_data": [
        {
          "cog_status": true,
          "layer_name": "First Scenario",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/rainfall_max_1.tif",
          "max_info": {
            "max": 139.52000427246094,
            "mean": 127.1805191040039,
            "min": 115.0
          }
        },
        {
          "cog_status": true,
          "layer_name": "Second Scenario",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/rainfall_max_2.tif",
          "max_info": {
            "max": 131.72000122070312,
            "mean": 121.5945053100586,
            "min": 93.5
          }
        },
        {
          "cog_status": true,
          "layer_name": "Third Scenario",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/rainfall_max_3.tif",
          "max_info": {
            "max": 123.97000122070312,
            "mean": 113.70450592041016,
            "min": 88.5999984741211
          }
        },
        {
          "cog_status": true,
          "layer_name": "Fourth Scenario",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/rainfall_max_4.tif",
          "max_info": {
            "max": 119.83999633789062,
            "mean": 103.14449310302734,
            "min": 82.80000305175781
          }
        },
        {
          "cog_status": true,
          "layer_name": "Fifth Scenario",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/mean_of_max.tif",
          "max_info": {
            "max": 102.6445541381836,
            "mean": 89.0548095703125,
            "min": 78.9245376586914
          }
        }
      ],
      "mean_data": [
        {
          "cog_status": true,
          "layer_name": "First Scenario",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/rainfall_mean_1.tif",
          "mean_info": {
            "max": 20.523279190063477,
            "mean": 16.01266860961914,
            "min": 14.508031845092773
          }
        },
        {
          "cog_status": true,
          "layer_name": "Second Scenario",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/rainfall_mean_2.tif",
          "mean_info": {
            "max": 18.36328125,
            "mean": 14.45441722869873,
            "min": 12.63050651550293
          }
        },
        {
          "cog_status": true,
          "layer_name": "Third Scenario",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/rainfall_mean_3.tif",
          "mean_info": {
            "max": 17.087373733520508,
            "mean": 13.932294845581055,
            "min": 12.593360900878906
          }
        },
        {
          "cog_status": true,
          "layer_name": "Fourth Scenario",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/rainfall_mean_4.tif",
          "mean_info": {
            "max": 16.81450843811035,
            "mean": 13.651876449584961,
            "min": 11.553526878356934
          }
        },
        {
          "cog_status": true,
          "layer_name": "Fifth Scenario",
          "layer_url": "https://nextech-analytics.s3.ap-south-1.amazonaws.com/Datapipeline/cadb9338-270d-4186-9254-b21cd12dae64/COG/mean_of_mean.tif",
          "mean_info": {
            "max": 15.205960273742676,
            "mean": 12.42503547668457,
            "min": 10.805254936218262
          }
        }
      ]
    }
  },
  "message": "success",
  "status": 200
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const OverviewSection = () => {

  const dispatch = useDispatch()

  const currentSection = useSelector(selectCurrentSection)
  const savedJourneyInputData = useSelector(selectJourneyInputData)

  const selectedState = savedJourneyInputData?.location?.stateDistrictDropdowns?.selectedState
  const selectedDistrict = savedJourneyInputData?.location?.stateDistrictDropdowns?.selectedDistrict
  const selectedContextVariable = savedJourneyInputData?.contextVariable
  const selectedDate = savedJourneyInputData?.date

  const [districtFilters, setDistrictFilters] = useState({ state_id: selectedState?.stateCode })

  const {
    data: statesApiData,
    isFetching: isStatesApiDataFetching,
    refetch: stateApiDataRefetch,
  } = useGetStatesListQuery()

  const {
    data: districtsApiData,
    isFetching: isDistrictsApiDataFetching,
    refetch: districtsApiDataRefetch,
  } = useGetDistrictsByFiltersQuery(districtFilters)

  const handleStateSelection = (e) => {
    setDistrictFilters({ state_id: e.value })
    districtsApiDataRefetch({ state_id: e.value })

    dispatch(setJourneyInputLocationData({
      stateDistrictDropdowns: {
        selectedState: {
          stateName: e.label,
          stateCode: e.value,
        },
        selectedDistrict: {
          districtName: "",
          districtCode: "",
        },
      }
    }))
    dispatch(setJourneyMapIsLoading(true))

    const STATE_GEOJSON_URL = `https://pixstack.leadsnextech.com/get_states_geojson?state_code=${e.value}`
    fetch((STATE_GEOJSON_URL)).then((resp) => {
      resp.json().then((geoJson) => {
        dispatch(setJourneyMapIsLoading(false))
        dispatch(setJourneyMapGeoJson(geoJson))

      }).catch((err) => { })
    }).catch((err) => { })
  }

  const handleDistrictSelection = (e) => {

    dispatch(setJourneyInputLocationData({
      stateDistrictDropdowns: {
        selectedState: {
          stateName: selectedState?.stateName,
          stateCode: selectedState?.stateCode,
        },
        selectedDistrict: {
          districtName: e.label,
          districtCode: e.value,
        }
      }
    }))
    dispatch(setJourneyMapIsLoading(true))

    const DISTRICT_GEOJSON_URL = `https://pixstack.leadsnextech.com/get_districts_geojson?state_code=${selectedState?.stateCode}&district_code=${e.value}`
    fetch((DISTRICT_GEOJSON_URL)).then((resp) => {
      resp.json().then((geoJson) => {

        dispatch(setJourneyMapGeoJson(geoJson))
        dispatch(setJourneyMapIsLoading(false))

      }).catch((err) => { })
    }).catch((err) => { })
  }

  const handleContextVariableSelection = (e) => {
    if (!e) return dispatch(setContextVariable(null))

    dispatch(setContextVariable({
      name: e.label,
      value: e.value
    }))
  }

  return (
    <Box className="w-full h-full flex flex-col justify-between">
      <Box className='w-full h-full flex flex-col gap-4'>
        <Box className='w-full flex justify-between gap-4'>
          <Select
            className="bg-gray-50 rounded w-[50%] "
            onChange={(e) => handleStateSelection(e)}
            isDisabled={isStatesApiDataFetching || currentSection?.id !== 0}
            isLoading={isStatesApiDataFetching}
            value={selectedState.stateName ? { label: selectedState?.stateName, value: selectedState?.stateCode } : null}
            options={
              statesApiData && statesApiData.data?.map((state) => {
                return {
                  label: state.state_name,
                  value: state.state_code
                }
              }).sort((a, b) => (a.label > b.label) ? 1 : -1)
            }
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
                borderColor: "#cccccc",
                height: '45px',
                "&:hover": {
                  borderColor: "#cccccc"
                }
              })
            }}
            placeholder="State"
            isSearchable={true}
          />
          <Select
            className="bg-gray-50 rounded w-[50%] "
            onChange={(e) => handleDistrictSelection(e)}
            isDisabled={isDistrictsApiDataFetching || currentSection?.id !== 0}
            isLoading={isDistrictsApiDataFetching}
            value={selectedDistrict.districtName ? { label: selectedDistrict?.districtName, value: selectedDistrict?.districtCode } : null}
            options={
              districtsApiData && districtsApiData.data?.map((district) => {
                return {
                  label: district.district_name,
                  value: district.district_code
                }
              }).sort((a, b) => (a.label > b.label) ? 1 : -1)
            }
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
                borderColor: "#cccccc",
                height: '45px',
                "&:hover": {
                  borderColor: "#cccccc"
                }
              })
            }}
            placeholder="District"
            isSearchable={true}
          />
        </Box>
        <Box className='w-full flex justify-between gap-4'>
          <Select
            className="bg-gray-50 rounded w-full"
            isDisabled={false}
            // isClearable
            value={selectedContextVariable?.name ? { label: selectedContextVariable.name, value: selectedContextVariable.value } : null}
            onChange={(e) => { handleContextVariableSelection(e) }}
            isOptionDisabled={(option) => option.disabled}
            options={
              [
                { label: 'Meteorological', value: 'Precipitation_Flux' },
                { label: 'Innundation', value: 'Innundation' },
                { label: 'Atmospheric', value: 'Atmospheric', disabled: true },
                { label: 'Biophysical', value: 'Biophysical', disabled: true },
                { label: 'Topographical', value: 'Topographical', disabled: true },
                { label: 'Demographical', value: 'Demographical', disabled: true },
                { label: 'Cummulative', value: 'Cummulative', disabled: true }
              ]
            }
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
                borderColor: "#cccccc",
                height: '45px',
                "&:hover": {
                  borderColor: "#cccccc"
                }
              })
            }}
            placeholder="Context Variables"
            isSearchable={true}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className='w-full'
              sx={{ pointerEvents: "none" }}
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    '& .MuiOutlinedInput-notchedOutline': {
                      height: '50px',
                      borderColor: '#bbb !important',
                      borderWidth: "1px !important"
                    },
                    '& .MuiInputBase-input': {
                      padding: "10.5px 14px"
                    },
                  },
                  InputProps: {
                    readOnly: true,
                  },
                  className: "!bg-gray-50 !h-full"
                }
              }}
              value={dayjs(selectedDate)}
              maxDate={dayjs()}
              onChange={(e) => dispatch(setJourneyInputData({ date: e.format('YYYY-MM-DD') }))}
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <Box className="flex justify-center">
        <img src={LocationImg} className={`${false ? "w-0" : "w-[70%]"} !mix-blend-multiply !transition-all !duration-700 !ease-out`} />
      </Box>
    </Box>
  )
}

const SpaceTimeDynamicsSection = ({ layers = [], stats }) => {

  const dispatch = useDispatch()
  const LayersAddedToMap = useSelector(selectCogList)
  const JourneyData = useSelector(selectJourneyData)
  const JourneyMapGeoJson = useSelector(selectJourneyMapGeoJson)
  const [clipLayers, setClipLayers] = useState([])
  const [clipLayersEnabled, setClipLayersEnabled] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="w-full h-full">
      <Tabs
        className='w-full h-full overflow-hidden'
        items={[
          {
            key: "Reference - Max",
            label: "Reference - Max",
            className: "w-full h-[420px]",
            children: (
              <Card className="w-full h-full !bg-gray-300 p-4">
                <Box className="w-full h-full overflow-auto scroll-bar-hidden rounded">
                  {!layers || !layers.max_data ? (
                    (
                      <div className='p-[3px] space-y-[2px]'>
                        {
                          [1, 2, 3, 4, 5].map((item, index) => {
                            return (
                              <div key={index} className="p-3 bg-white shadow">
                                <div className="flex justify-between bg-white ">
                                  <div>
                                    <div className="w-24 h-2 mb-2 bg-gray-200 rounded-full"></div>
                                    <div className="w-32 h-1.5 bg-gray-100 rounded-full"></div>
                                  </div>
                                  <div className="w-12 h-2 bg-gray-200 rounded-full"></div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  ) : null}
                  {layers?.max_data?.map((layer, index) => {
                    return (
                      <Accordion key={index} className={`!border-[3px] !border-b-0 !border-gray-300 `}>
                        <AccordionSummary>
                          <div className="relative flex w-full justify-between gap-5">
                            <div className='flex gap-3 w-[90%]'>
                              <div className='lg:w-[90%] flex flex-col gap-1'>
                                <p className="relative text-sm font-medium text-gray-700 break-words overflow-hidden leading-[18px] flex items-center">
                                  {layer.layer_name} {!layer.cog_status === true ? <LoadingDots className={"!text-gray-400"} /> : null}
                                </p>
                              </div>
                            </div>
                            <Box onClick={(e) => e.stopPropagation()} className='flex justify-center gap-2 w-[30%]'>
                              <Tooltip title="Opacity" placement='top' arrow>
                                <DeblurRounded

                                  id={`basic-button-${index}`}
                                  aria-controls={open ? `basic-menu-${index}` : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  onClick={() => { handleClick(document.getElementById(`basic-button-${index}`)); }}

                                  className='text-gray-500 hover:text-gray-900' fontSize='small' />
                              </Tooltip>
                              <StyledMenu
                                id={`basic-menu-${index}`}
                                anchorEl={anchorEl}
                                open={false}
                                onClose={handleClose}
                                MenuListProps={{
                                  'aria-labelledby': `basic-button-${index}`,
                                }}
                              >
                                <Box className="w-[250px] flex flex-col justify-between items-center p-1 px-4">
                                  <Box className="w-full flex text-sm">Opacity: &nbsp; <strong>{layer.opacity || 0}%</strong></Box>
                                  <Box className="w-full flex items-center">
                                    <Slider
                                      onChange={(e) => {
                                        const newData = JourneyData.data.trend_analysis.max_data.map((item) => {
                                          if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                            dispatch(updateCogAddedToMap({ ...item, opacity: e.target.value }))
                                            return { ...item, opacity: e.target.value }
                                          }
                                          return item
                                        })
                                        dispatch(setJourneyData({
                                          ...JourneyData,
                                          data: {
                                            ...JourneyData.data,
                                            trend_analysis: {
                                              ...JourneyData.data.trend_analysis,
                                              max_data: newData
                                            }
                                          }
                                        }))

                                      }}
                                      className='!text-gray-500'
                                      size="small"
                                      value={layer.opacity}
                                      aria-label="Small"
                                      color="secondary"
                                      valueLabelDisplay="auto"
                                    />
                                  </Box>
                                </Box>
                              </StyledMenu>
                              <Tooltip title="Bring to Front" placement='top' arrow>
                                <FlipToFrontRounded onClick={() => {

                                  if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                                    return
                                  }

                                  LayersAddedToMap.map((item) => {
                                    if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                      dispatch(updateCogAddedToMap({ ...item, priority: 1 }))
                                    } else {
                                      dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                                    }
                                  })

                                  const newData = JourneyData.data.trend_analysis.max_data.map((item) => {
                                    if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                      return { ...item, priority: 1 }
                                    }
                                    return { ...item, priority: 0 }
                                  })
                                  dispatch(setJourneyData({
                                    ...JourneyData,
                                    data: {
                                      ...JourneyData.data,
                                      trend_analysis: {
                                        ...JourneyData.data.trend_analysis,
                                        max_data: newData
                                      }
                                    }
                                  }))

                                }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                              </Tooltip>
                              <Tooltip title="Move Back" placement='top' arrow>
                                <FlipToBackRounded onClick={() => {
                                  if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                                    return
                                  }
                                  const newData = JourneyData.data.trend_analysis.max_data.map((item) => {

                                    LayersAddedToMap.map((item) => {
                                      if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                        dispatch(updateCogAddedToMap({ ...item, priority: -1 }))
                                      } else {
                                        dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                                      }
                                    })

                                    if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                      return { ...item, priority: -1 }
                                    }
                                    return { ...item }
                                  })
                                  dispatch(setJourneyData({
                                    ...JourneyData,
                                    data: {
                                      ...JourneyData.data,
                                      trend_analysis: {
                                        ...JourneyData.data.trend_analysis,
                                        max_data: newData
                                      }
                                    }
                                  }))
                                }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                              </Tooltip>

                              <Tooltip title={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0 ? "Remove from map" : "Add to Map"} placement='top' arrow>
                                <Switch
                                  size='small' className='bg-gray-600'
                                  onChange={(e) => {
                                    if (layer.rasterLayerUrl) {
                                      e ? dispatch(addCogToMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Trend Max ${layer.layer_name}` })) : dispatch(removeCogFromMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Trend Max ${layer.layer_name}` }))
                                    }
                                  }}
                                  checked={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0}
                                />
                              </Tooltip>
                            </Box>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box className="flex flex-col justify-between w-full h-full items-center gap-6">

                            <Box className="w-full flex justify-between gap-2">
                              <Button onClick={() => dispatch(setJourneyMapBounds([...layer.bounds]))} variant='outlined' disableElevation className='w-full' startIcon={<LocationSearching fontSize='small' />}>Ideal&nbsp;Zoom</Button>
                              <Button onClick={() => setClipLayersEnabled(!clipLayersEnabled)} variant={clipLayersEnabled ? 'contained' : 'outlined'} disableElevation className='w-full' startIcon={<PhotoSizeSelectLarge fontSize='small' />}>Clip&nbsp;Layer</Button>
                            </Box>

                            {
                              clipLayersEnabled && layer?.summary_stats && layer.average_score !== 0 ? (
                                <>
                                  <Box className="w-full flex flex-col gap-2">
                                    <Box className='w-full flex justify-between items-baseline'>
                                      <p className='text-sm font-medium'>Clip Layers</p>
                                      <Box className="space-x-2">
                                        <Tooltip title="Upload file" placement='top' arrow>
                                          <IconButton component="label" size='small' centerRipple={false} className="!rounded !bg-gray-100 !text-gray-800" >
                                            <FileUploadOutlined fontSize='small' />
                                            <VisuallyHiddenInput accept='.geojson' onChange={(event) => {

                                              const fileReader = new FileReader();
                                              const { files } = event.target;
                                              if (!files || files.length === 0) return
                                              fileReader.readAsText(files[0], "UTF-8");
                                              fileReader.onload = e => {
                                                const content = e.target.result;
                                                let geoJSON = JSON.parse(content)

                                                var requestOptions = {
                                                  headers: { "Content-Type": "application/json" },
                                                  method: 'POST',
                                                  body: JSON.stringify(geoJSON),
                                                };

                                                fetch(`https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/statistics?url=${layer.layer_url}`, requestOptions)
                                                  .then((resp) => {
                                                    if (resp.status === 200) {
                                                      resp.json().then((response) => {
                                                        setClipLayers([...clipLayers, {
                                                          name: geoJSON.name,
                                                          geoJson: { ...response, name: geoJSON.name + " Trend Max " + layer.layer_name, category: "Trend Max Clip Layer" }
                                                        }])
                                                      })
                                                    }
                                                  }).catch((err) => console.log(err))
                                              };

                                            }} type="file" />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Draw on Map" placement='top' arrow>
                                          <IconButton size='small' centerRipple={false} className="!rounded !bg-gray-100 !text-gray-800" >
                                            <DrawRounded fontSize='small' />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Box>

                                    {[{
                                      name: "Administrative Boundaries",
                                      geoJson: { ...layer?.summary_stats, name: "Administrative Boundaries" + " Trend Max " + layer.layer_name, category: "Trend Clip Layer" }
                                    }, ...clipLayers].map((item, index) => {
                                      return (
                                        <Box className="w-full px-1" key={index}>
                                          <Box className="w-full border border-gray-300 p-3 rounded flex justify-between items-center">
                                            <Box className='flex flex-col gap-1'>
                                              <p className="relative text-sm font-medium break-words overflow-hidden leading-[18px]">
                                                {item.name}
                                              </p>
                                            </Box>
                                            <Box className='flex gap-2 justify-center'>
                                              <Tooltip title="Download" placement='top' arrow>
                                                <FileDownloadOutlined fontSize='small' className='text-primary-800 hover:text-primary-900 cursor-pointer' />
                                              </Tooltip>

                                              <Switch
                                                checked={(item.name + " Trend Max " + layer.layer_name === JourneyMapGeoJson?.name)}
                                                onChange={(e) => { e ? dispatch(setJourneyMapGeoJson(item.geoJson)) : dispatch(setJourneyMapGeoJson(JourneyData.data.geometry)) }}
                                                size='small'
                                                className='bg-gray-600 scale-75'
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      )
                                    })}
                                  </Box>
                                </>
                              ) : null
                            }

                            <Box className="w-full mt-2">
                              <Box className="w-full h-full flex justify-between">
                                {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)]?.map((color, index) => (
                                  <div key={index}> {color} </div>
                                ))}
                              </Box>
                              <Box className="w-full h-full flex justify-between ">
                                {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].reverse().map((color, index) => (
                                  <div
                                    key={index}
                                    className='w-[10%] h-[30px]'
                                    style={{
                                      backgroundColor: d3.interpolateMagma((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                                    }}
                                  />
                                ))}
                              </Box>
                            </Box>

                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </Box>
              </Card>
            )
          },
          {
            key: "Reference - Mean",
            label: "Reference - Mean",
            className: "w-full h-[420px]",
            children: (
              <Card className="w-full h-full !bg-gray-300 p-4">
                <Box className="w-full h-full overflow-auto scroll-bar-hidden rounded">
                  {!layers || !layers.mean_data ? (
                    (
                      <div className='p-[3px] space-y-[2px]'>
                        {
                          [1, 2, 3, 4, 5].map((item, index) => {
                            return (
                              <div className=" p-3 bg-white shadow" key={index}>
                                <div className="flex animate-pulse justify-between bg-white">
                                  <div>
                                    <div className="w-24 h-2 mb-2 bg-gray-200 rounded-full"></div>
                                    <div className="w-32 h-1.5 bg-gray-100 rounded-full"></div>
                                  </div>
                                  <div className="w-12 h-2 bg-gray-200 rounded-full"></div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  ) : null}

                  {layers?.mean_data?.map((layer, index) => {
                    return (
                      <Accordion key={index} className={`!border-[3px] !border-b-0 !border-gray-300 `}>
                        <AccordionSummary>
                          <div className="relative flex w-full justify-between gap-5">
                            <div className='flex gap-3 w-[90%]'>
                              <div className='lg:w-[90%] flex flex-col gap-1'>
                                <p className="relative text-sm font-medium text-gray-700 break-words overflow-hidden leading-[18px] flex items-center">
                                  {layer.layer_name} {!layer.cog_status === true ? <LoadingDots className={"!text-gray-400"} /> : null}
                                </p>
                              </div>
                            </div>
                            <Box onClick={(e) => e.stopPropagation()} className='flex justify-center gap-2 w-[30%]'>
                              <Tooltip title="Opacity" placement='top' arrow>
                                <DeblurRounded

                                  id={`basic-button-${index}`}
                                  aria-controls={open ? `basic-menu-${index}` : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  onClick={() => { handleClick(document.getElementById(`basic-button-${index}`)); }}

                                  className='text-gray-500 hover:text-gray-900' fontSize='small' />
                              </Tooltip>
                              <Tooltip title="Bring to Front" placement='top' arrow>
                                <FlipToFrontRounded onClick={() => {

                                  if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                                    return
                                  }

                                  LayersAddedToMap.map((item) => {
                                    if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                      dispatch(updateCogAddedToMap({ ...item, priority: 1 }))
                                    } else {
                                      dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                                    }
                                  })

                                  const newData = JourneyData.data.trend_analysis.mean_data.map((item) => {
                                    if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                      return { ...item, priority: 1 }
                                    }
                                    return { ...item, priority: 0 }
                                  })
                                  dispatch(setJourneyData({
                                    ...JourneyData,
                                    data: {
                                      ...JourneyData.data,
                                      trend_analysis: {
                                        ...JourneyData.data.trend_analysis,
                                        mean_data: newData
                                      }
                                    }
                                  }))

                                }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                              </Tooltip>
                              <Tooltip title="Move Back" placement='top' arrow>
                                <FlipToBackRounded onClick={() => {

                                  if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                                    return
                                  }

                                  LayersAddedToMap.map((item) => {
                                    if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                      dispatch(updateCogAddedToMap({ ...item, priority: -1 }))
                                    } else {
                                      dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                                    }
                                  })

                                  const newData = JourneyData.data.trend_analysis.mean_data.map((item) => {
                                    if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                      return { ...item, priority: -1 }
                                    }
                                    return { ...item }
                                  })
                                  dispatch(setJourneyData({
                                    ...JourneyData,
                                    data: {
                                      ...JourneyData.data,
                                      trend_analysis: {
                                        ...JourneyData.data.trend_analysis,
                                        mean_data: newData
                                      }
                                    }
                                  }))
                                }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                              </Tooltip>

                              <Tooltip title={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0 ? "Remove from map" : "Add to Map"} placement='top' arrow>
                                <Switch
                                  size='small' className='bg-gray-600'
                                  onChange={(e) => {
                                    if (layer.rasterLayerUrl) {
                                      e ? dispatch(addCogToMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Trend Mean ${layer.layer_name}` })) : dispatch(removeCogFromMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Trend Mean ${layer.layer_name}` }))
                                    }
                                  }}
                                  checked={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0}
                                />
                              </Tooltip>
                            </Box>
                          </div>
                        </AccordionSummary>
                        {/* <AccordionDetails>
                          <Box className="flex flex-col justify-between w-full h-full items-center">
                            <Box className="w-full h-full flex justify-between">
                              {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)]?.map((color, index) => (
                                <div key={index}> {color} </div>
                              ))}
                            </Box>
                            <Box className="w-full h-full flex justify-between ">
                              {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].reverse().map((color, index) => (
                                <div
                                  key={index}
                                  className='w-[10%] h-[30px]'
                                  style={{
                                    backgroundColor: d3.interpolateMagma((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                                  }}
                                />
                              ))}
                            </Box>
                          </Box>
                        </AccordionDetails> */}
                        <AccordionDetails>
                          <Box className="flex flex-col justify-between w-full h-full items-center gap-6">

                            <Box className="w-full flex justify-between gap-2">
                              <Button onClick={() => dispatch(setJourneyMapBounds([...layer.bounds]))} variant='outlined' disableElevation className='w-full' startIcon={<LocationSearching fontSize='small' />}>Ideal&nbsp;Zoom</Button>
                              <Button onClick={() => setClipLayersEnabled(!clipLayersEnabled)} variant={clipLayersEnabled ? 'contained' : 'outlined'} disableElevation className='w-full' startIcon={<PhotoSizeSelectLarge fontSize='small' />}>Clip&nbsp;Layer</Button>
                            </Box>

                            {
                              clipLayersEnabled && layer?.summary_stats && layer.average_score !== 0 ? (
                                <>
                                  <Box className="w-full flex flex-col gap-2">
                                    <Box className='w-full flex justify-between items-baseline'>
                                      <p className='text-sm font-medium'>Clip Layers</p>
                                      <Box className="space-x-2">
                                        <Tooltip title="Upload file" placement='top' arrow>
                                          <IconButton component="label" size='small' centerRipple={false} className="!rounded !bg-gray-100 !text-gray-800" >
                                            <FileUploadOutlined fontSize='small' />
                                            <VisuallyHiddenInput accept='.geojson' onChange={(event) => {

                                              const fileReader = new FileReader();
                                              const { files } = event.target;
                                              if (!files || files.length === 0) return
                                              fileReader.readAsText(files[0], "UTF-8");
                                              fileReader.onload = e => {
                                                const content = e.target.result;
                                                let geoJSON = JSON.parse(content)

                                                var requestOptions = {
                                                  headers: { "Content-Type": "application/json" },
                                                  method: 'POST',
                                                  body: JSON.stringify(geoJSON),
                                                };

                                                fetch(`https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/statistics?url=${layer.layer_url}`, requestOptions)
                                                  .then((resp) => {
                                                    if (resp.status === 200) {
                                                      resp.json().then((response) => {
                                                        setClipLayers([...clipLayers, {
                                                          name: geoJSON.name,
                                                          geoJson: { ...response, name: geoJSON.name + " Trend Mean " + layer.layer_name, category: "Trend Mean Clip Layer" }
                                                        }])
                                                      })
                                                    }
                                                  }).catch((err) => console.log(err))
                                              };

                                            }} type="file" />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Draw on Map" placement='top' arrow>
                                          <IconButton size='small' centerRipple={false} className="!rounded !bg-gray-100 !text-gray-800" >
                                            <DrawRounded fontSize='small' />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Box>

                                    {[{
                                      name: "Administrative Boundaries",
                                      geoJson: { ...layer?.summary_stats, name: "Administrative Boundaries" + " Trend Mean " + layer.layer_name, category: "Trend Clip Layer" }
                                    }, ...clipLayers].map((item, index) => {
                                      return (
                                        <Box className="w-full px-1" key={index}>
                                          <Box className="w-full border border-gray-300 p-3 rounded flex justify-between items-center">
                                            <Box className='flex flex-col gap-1'>
                                              <p className="relative text-sm font-medium break-words overflow-hidden leading-[18px]">
                                                {item.name}
                                              </p>
                                            </Box>
                                            <Box className='flex gap-2 justify-center'>
                                              <Tooltip title="Download" placement='top' arrow>
                                                <FileDownloadOutlined fontSize='small' className='text-primary-800 hover:text-primary-900 cursor-pointer' />
                                              </Tooltip>

                                              <Switch
                                                checked={(item.name + " Trend Mean " + layer.layer_name === JourneyMapGeoJson?.name)}
                                                onChange={(e) => { e ? dispatch(setJourneyMapGeoJson(item.geoJson)) : dispatch(setJourneyMapGeoJson(JourneyData.data.geometry)) }}
                                                size='small'
                                                className='bg-gray-600 scale-75'
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      )
                                    })}
                                  </Box>
                                </>
                              ) : null
                            }

                            <Box className="w-full">
                              <Box className="w-full h-full flex justify-between">
                                {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)]?.map((color, index) => (
                                  <div key={index}> {color} </div>
                                ))}
                              </Box>
                              <Box className="w-full h-full flex justify-between ">
                                {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].reverse().map((color, index) => (
                                  <div
                                    key={index}
                                    className='w-[10%] h-[30px]'
                                    style={{
                                      backgroundColor: d3.interpolateMagma((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                                    }}
                                  />
                                ))}
                              </Box>
                            </Box>

                            {/* <Box className="w-full flex justify-between items-center">
                              <Box className="w-[40%] flex">Opacity: &nbsp; <strong>{layer.opacity || 0}%</strong></Box>
                              <Box className="w-full flex items-center">
                                <Slider
                                  onChange={(e) => {
                                    const newData = JourneyData.data.trend_analysis.mean_data.map((item) => {
                                      if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                        dispatch(updateCogAddedToMap({ ...item, opacity: e.target.value }))
                                        return { ...item, opacity: e.target.value }
                                      }
                                      return item
                                    })
                                    dispatch(setJourneyData({
                                      ...JourneyData,
                                      data: {
                                        ...JourneyData.data,
                                        trend_analysis: {
                                          ...JourneyData.data.trend_analysis,
                                          mean_data: newData
                                        }
                                      }
                                    }))

                                  }}
                                  className='!text-gray-500'
                                  size="small"
                                  value={layer.opacity}
                                  aria-label="Small"
                                  color="secondary"
                                  valueLabelDisplay="auto"
                                />
                              </Box>
                            </Box> */}

                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </Box>
              </Card>
            )
          }
        ]}
      />
    </Box>
  )
}

const RiskIntensity = ({ layers = [] }) => {

  const dispatch = useDispatch()
  const LayersAddedToMap = useSelector(selectCogList)
  const JourneyData = useSelector(selectJourneyData)
  const JourneyMapGeoJson = useSelector(selectJourneyMapGeoJson)
  const [clipLayers, setClipLayers] = useState([])
  const [clipLayersEnabled, setClipLayersEnabled] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='w-full h-full flex flex-col gap-2'>
      <div className='flex justify-between items-center'>
        <p className='text-base font-medium pl-2'>Layers</p>
        <IconButton size='small' sx={{ border: "1px solid #ccc" }} centerRipple={false} className="!rounded hover:!bg-base-50 !bg-white !text-base-950" >
          <AddRounded fontSize='small' />
        </IconButton>
      </div>

      <Card className="w-full h-full !bg-gray-300 p-4">
        <Box className="w-full h-full overflow-auto scroll-bar-hidden rounded">

          {layers?.map((layer, index) => {
            return (
              <Accordion key={index} className={`!border-[3px] !border-b-0 !border-gray-300 `}>
                <AccordionSummary>
                  <div className="relative flex w-full justify-between gap-5">
                    <div className='flex gap-3 w-full'>
                      <div className='lg:w-[90%] flex flex-col gap-1'>
                        <p className="relative text-sm font-medium text-gray-700 break-words overflow-hidden leading-[18px] flex items-center">
                          {layer.layer_name} {!layer.cog_status === true ? <LoadingDots className={"!text-gray-400"} /> : null}
                        </p>
                      </div>
                    </div>
                    <Box onClick={(e) => e.stopPropagation()} className='flex justify-center gap-2 w-[30%]'>
                      <Tooltip title="Opacity" placement='top' arrow>
                        <DeblurRounded

                          id={`basic-button-${index}`}
                          aria-controls={open ? `basic-menu-${index}` : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={() => { handleClick(document.getElementById(`basic-button-${index}`)); }}

                          className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>
                      <Tooltip title="Bring to Front" placement='top' arrow>
                        <FlipToFrontRounded onClick={() => {

                          if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                            return
                          }

                          LayersAddedToMap.map((item) => {
                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              dispatch(updateCogAddedToMap({ ...item, priority: 1 }))
                            } else {
                              dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                            }
                          })

                          const newData = JourneyData.data.event_intensity.map((item) => {
                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              return { ...item, priority: 1 }
                            }
                            return { ...item, priority: 0 }
                          })
                          dispatch(setJourneyData({
                            ...JourneyData,
                            data: {
                              ...JourneyData.data,
                              event_intensity: newData
                            }
                          }))

                        }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>
                      <Tooltip title="Move Back" placement='top' arrow>
                        <FlipToBackRounded onClick={() => {

                          if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                            return
                          }

                          LayersAddedToMap.map((item) => {
                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              dispatch(updateCogAddedToMap({ ...item, priority: -1 }))
                            } else {
                              dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                            }
                          })

                          const newData = JourneyData.data.event_intensity.map((item) => {
                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              return { ...item, priority: -1 }
                            }
                            return { ...item }
                          })
                          dispatch(setJourneyData({
                            ...JourneyData,
                            data: {
                              ...JourneyData.data,
                              event_intensity: newData
                            }
                          }))
                        }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>

                      <Tooltip title={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0 ? "Remove from map" : "Add to Map"} placement='top' arrow>
                        <Switch
                          size='small' className='bg-gray-600'
                          onChange={(e) => {
                            if (layer.rasterLayerUrl) {
                              e ? dispatch(addCogToMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Risk ${layer.layer_name}` })) : dispatch(removeCogFromMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Risk ${layer.layer_name}` }))
                            }
                          }}
                          checked={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0}
                        />
                      </Tooltip>
                    </Box>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Box className="flex flex-col justify-between w-full h-full items-center">
                    <Box className="w-full h-full flex justify-between">
                      {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)]?.map((color, index) => (
                        <div key={index}> {color} </div>
                      ))}
                    </Box>
                    <Box className="w-full h-full flex justify-between ">
                      {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].reverse().map((color, index) => (
                        <div
                          key={index}
                          className='w-[10%] h-[30px]'
                          style={{
                            backgroundColor: d3.interpolateMagma((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                          }}
                        />
                      ))}
                    </Box>
                  </Box> */}
                  <Box className="flex flex-col justify-between w-full h-full items-center gap-6">

                    <Box className="w-full flex justify-between gap-2">
                      <Button onClick={() => dispatch(setJourneyMapBounds([...layer.bounds]))} variant='outlined' disableElevation className='w-full' startIcon={<LocationSearching fontSize='small' />}>Ideal&nbsp;Zoom</Button>
                      <Button onClick={() => setClipLayersEnabled(!clipLayersEnabled)} variant={clipLayersEnabled ? 'contained' : 'outlined'} disableElevation className='w-full' startIcon={<PhotoSizeSelectLarge fontSize='small' />}>Clip&nbsp;Layer</Button>
                    </Box>

                    {
                      clipLayersEnabled && layer?.summary_stats && layer.average_score !== 0 ? (
                        <>
                          <Box className="w-full flex flex-col gap-2">
                            <Box className='w-full flex justify-between items-baseline'>
                              <p className='text-sm font-medium'>Clip Layers</p>
                              <Box className="space-x-2">
                                <Tooltip title="Upload file" placement='top' arrow>
                                  <IconButton component="label" size='small' centerRipple={false} className="!rounded !bg-gray-100 !text-gray-800" >
                                    <FileUploadOutlined fontSize='small' />
                                    <VisuallyHiddenInput accept='.geojson' onChange={(event) => {

                                      const fileReader = new FileReader();
                                      const { files } = event.target;
                                      if (!files || files.length === 0) return
                                      fileReader.readAsText(files[0], "UTF-8");
                                      fileReader.onload = e => {
                                        const content = e.target.result;
                                        let geoJSON = JSON.parse(content)

                                        var requestOptions = {
                                          headers: { "Content-Type": "application/json" },
                                          method: 'POST',
                                          body: JSON.stringify(geoJSON),
                                        };

                                        fetch(`https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/statistics?url=${layer.layer_url}`, requestOptions)
                                          .then((resp) => {
                                            if (resp.status === 200) {
                                              resp.json().then((response) => {
                                                setClipLayers([...clipLayers, {
                                                  name: geoJSON.name,
                                                  geoJson: { ...response, name: geoJSON.name + " Risk " + layer.layer_name, category: "Risk Clip Layer" }
                                                }])
                                              })
                                            }
                                          }).catch((err) => console.log(err))
                                      };

                                    }} type="file" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Draw on Map" placement='top' arrow>
                                  <IconButton size='small' centerRipple={false} className="!rounded !bg-gray-100 !text-gray-800" >
                                    <DrawRounded fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>

                            {[{
                              name: "Administrative Boundaries",
                              geoJson: { ...layer?.summary_stats, name: "Administrative Boundaries" + " Risk " + layer.layer_name, category: "Risk Clip Layer" }
                            }, ...clipLayers].map((item, index) => {
                              return (
                                <Box className="w-full px-1" key={index}>
                                  <Box className="w-full border border-gray-300 p-3 rounded flex justify-between items-center">
                                    <Box className='flex flex-col gap-1'>
                                      <p className="relative text-sm font-medium break-words overflow-hidden leading-[18px]">
                                        {item.name}
                                      </p>
                                    </Box>
                                    <Box className='flex gap-2 justify-center'>
                                      <Tooltip title="Download" placement='top' arrow>
                                        <FileDownloadOutlined fontSize='small' className='text-primary-800 hover:text-primary-900 cursor-pointer' />
                                      </Tooltip>

                                      <Switch
                                        checked={(item.name + " Risk " + layer.layer_name === JourneyMapGeoJson?.name)}
                                        onChange={(e) => { e ? dispatch(setJourneyMapGeoJson(item.geoJson)) : dispatch(setJourneyMapGeoJson(JourneyData.data.geometry)) }}
                                        size='small'
                                        className='bg-gray-600 scale-75'
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              )
                            })}
                          </Box>
                        </>
                      ) : null
                    }

                    <Box className="w-full">
                      <Box className="w-full h-full flex justify-between">
                        {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)]?.map((color, index) => (
                          <div key={index}> {color} </div>
                        ))}
                      </Box>
                      <Box className="w-full h-full flex justify-between ">
                        {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].reverse().map((color, index) => (
                          <div
                            key={index}
                            className='w-[10%] h-[30px]'
                            style={{
                              backgroundColor: d3.interpolateMagma((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                    {/* <Box className="w-full flex justify-between items-center">
                      <Box className="w-[40%] flex">Opacity: &nbsp; <strong>{layer.opacity || 0}%</strong></Box>
                      <Box className="w-full flex items-center">
                        <Slider
                          onChange={(e) => {
                            const newData = JourneyData.data.event_intensity.map((item) => {
                              if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                dispatch(updateCogAddedToMap({ ...item, opacity: e.target.value }))
                                return { ...item, opacity: e.target.value }
                              }
                              return item
                            })
                            dispatch(setJourneyData({
                              ...JourneyData,
                              data: {
                                ...JourneyData.data,
                                event_intensity: newData
                              }
                            }))

                          }}
                          className='!text-gray-500'
                          size="small"
                          value={layer.opacity}
                          aria-label="Small"
                          color="secondary"
                          valueLabelDisplay="auto"
                        />
                      </Box>
                    </Box> */}

                  </Box>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Box>
      </Card>

    </div>
  )
}

const HazardOld = ({ layers = [] }) => {

  const dispatch = useDispatch()
  const LayersAddedToMap = useSelector(selectCogList)
  const JourneyData = useSelector(selectJourneyData)
  const JourneyMapGeoJson = useSelector(selectJourneyMapGeoJson)
  const [clipLayers, setClipLayers] = useState([])
  const [clipLayersEnabled, setClipLayersEnabled] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='w-full h-full flex flex-col gap-2'>
      <div className='flex justify-between items-center'>
        <p className='text-base font-medium pl-2'>Layers</p>
        <IconButton size='small' sx={{ border: "1px solid #ccc" }} centerRipple={false} className="!rounded hover:!bg-base-50 !bg-white !text-base-950" >
          <AddRounded fontSize='small' />
        </IconButton>
      </div>
      <Card className="w-full h-full !bg-gray-300 p-4">
        <Box className="w-full h-full overflow-auto scroll-bar-hidden rounded">
          {layers?.map((layer, index) => {
            return (
              <Accordion key={index} className={`!border-[3px] !border-b-0 !border-gray-300 w-full`}>
                <AccordionSummary>
                  <div className="relative flex w-full justify-between gap-5">
                    <div className='flex gap-3 w-full'>
                      <div className='lg:w-[90%] flex flex-col gap-1'>
                        <p className="relative text-sm font-medium text-gray-700 break-words overflow-hidden leading-[18px] flex items-center">
                          {layer.layer_name} {!layer.cog_status === true ? <LoadingDots className={"!text-gray-400"} /> : null}
                        </p>
                        <p className="text-gray-400 text-xs leading-[18px]">Hazard Score: {layer.average_score === 0 ? "Not Triggered" : layer.average_score?.toFixed(3)}</p>
                      </div>
                    </div>
                    <Box onClick={(e) => e.stopPropagation()} className='flex justify-center gap-2 w-[30%]'>
                      <Tooltip title="Opacity" placement='top' arrow>
                        <DeblurRounded

                          id={`basic-button-${index}`}
                          aria-controls={open ? `basic-menu-${index}` : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={() => { handleClick(document.getElementById(`basic-button-${index}`)); }}

                          className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>
                      <Tooltip title="Bring to Front" placement='top' arrow>
                        <FlipToFrontRounded onClick={() => {

                          if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                            return
                          }

                          LayersAddedToMap.map((item) => {
                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              dispatch(updateCogAddedToMap({ ...item, priority: 1 }))
                            } else {
                              dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                            }
                          })

                          const newData = JourneyData.data.impact_analysis.map((item) => {
                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              return { ...item, priority: 1 }
                            }
                            return { ...item, priority: 0 }
                          })
                          dispatch(setJourneyData({
                            ...JourneyData,
                            data: {
                              ...JourneyData.data,
                              impact_analysis: newData
                            }
                          }))

                        }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>
                      <Tooltip title="Move Back" placement='top' arrow>
                        <FlipToBackRounded onClick={() => {

                          if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                            return
                          }

                          LayersAddedToMap.map((item) => {
                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              dispatch(updateCogAddedToMap({ ...item, priority: -1 }))
                            } else {
                              dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                            }
                          })

                          const newData = JourneyData.data.impact_analysis.map((item) => {
                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              return { ...item, priority: -1 }
                            }
                            return { ...item }
                          })
                          dispatch(setJourneyData({
                            ...JourneyData,
                            data: {
                              ...JourneyData.data,
                              impact_analysis: newData
                            }
                          }))
                        }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>

                      <Tooltip title={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0 ? "Remove from map" : "Add to Map"} placement='top' arrow>
                        <Switch
                          size='small' className='bg-gray-600'
                          onChange={(e) => {
                            if (layer.rasterLayerUrl) {
                              e ? dispatch(addCogToMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Hazard ${layer.layer_name}` })) : dispatch(removeCogFromMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Hazard ${layer.layer_name}` }))
                            }
                          }}
                          checked={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0}
                        />
                      </Tooltip>
                    </Box>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Box className="flex flex-col justify-between w-full h-full items-center">
                    <Box className="w-full h-full flex justify-between">
                      {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)]?.map((color, index) => (
                        <div key={index}> {color} </div>
                      ))}
                    </Box>
                    <Box className="w-full h-full flex justify-between ">
                      {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].map((color, index) => (
                        <div
                          key={index}
                          className='w-[10%] h-[30px]'
                          style={{
                            backgroundColor: d3.interpolateYlOrRd((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                          }}
                        />
                      ))}
                    </Box>
                  </Box> */}
                  <Box className="flex flex-col justify-between w-full h-full items-center gap-6">

                    <Box className="w-full flex justify-between gap-2">
                      <Button onClick={() => dispatch(setJourneyMapBounds([...layer.bounds]))} variant='outlined' disableElevation className='w-full' startIcon={<LocationSearching fontSize='small' />}>Ideal&nbsp;Zoom</Button>
                      <Button onClick={() => setClipLayersEnabled(!clipLayersEnabled)} variant={clipLayersEnabled ? 'contained' : 'outlined'} disableElevation className='w-full' startIcon={<PhotoSizeSelectLarge fontSize='small' />}>Clip&nbsp;Layer</Button>
                    </Box>

                    {
                      clipLayersEnabled && layer?.summary_stats && layer.average_score !== 0 ? (
                        <>
                          <Box className="w-full flex flex-col gap-2">
                            <Box className='w-full flex justify-between items-baseline'>
                              <p className='text-sm font-medium'>Clip Layers</p>
                              <Box className="space-x-2">
                                <Tooltip title="Upload file" placement='top' arrow>
                                  <IconButton component="label" size='small' centerRipple={false} className="!rounded !bg-gray-100 !text-gray-800" >
                                    <FileUploadOutlined fontSize='small' />
                                    <VisuallyHiddenInput accept='.geojson' onChange={(event) => {

                                      const fileReader = new FileReader();
                                      const { files } = event.target;
                                      if (!files || files.length === 0) return
                                      fileReader.readAsText(files[0], "UTF-8");
                                      fileReader.onload = e => {
                                        const content = e.target.result;
                                        let geoJSON = JSON.parse(content)

                                        var requestOptions = {
                                          headers: { "Content-Type": "application/json" },
                                          method: 'POST',
                                          body: JSON.stringify(geoJSON),
                                        };

                                        fetch(`https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/statistics?url=${layer.layer_url}`, requestOptions)
                                          .then((resp) => {
                                            if (resp.status === 200) {
                                              resp.json().then((response) => {
                                                setClipLayers([...clipLayers, {
                                                  name: geoJSON.name,
                                                  geoJson: { ...response, name: geoJSON.name + " Hazard " + layer.layer_name, category: "Hazard Clip Layer" }
                                                }])
                                              })
                                            }
                                          }).catch((err) => console.log(err))
                                      };

                                    }} type="file" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Draw on Map" placement='top' arrow>
                                  <IconButton size='small' centerRipple={false} className="!rounded !bg-gray-100 !text-gray-800" >
                                    <DrawRounded fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>

                            {[{
                              name: "Administrative Boundaries",
                              geoJson: { ...layer?.summary_stats, name: "Administrative Boundaries" + " Hazard " + layer.layer_name, category: "Hazard Clip Layer" }
                            }, ...clipLayers].map((item, index) => {
                              return (
                                <Box className="w-full px-1" key={index}>
                                  <Box className="w-full border border-gray-300 p-3 rounded flex justify-between items-center">
                                    <Box className='flex flex-col gap-1'>
                                      <p className="relative text-sm font-medium break-words overflow-hidden leading-[18px]">
                                        {item.name}
                                      </p>
                                    </Box>
                                    <Box className='flex gap-2 justify-center'>
                                      <Tooltip title="Download" placement='top' arrow>
                                        <FileDownloadOutlined fontSize='small' className='text-primary-800 hover:text-primary-900 cursor-pointer' />
                                      </Tooltip>

                                      <Switch
                                        checked={(item.name + " Hazard " + layer.layer_name === JourneyMapGeoJson?.name)}
                                        onChange={(e) => { e ? dispatch(setJourneyMapGeoJson(item.geoJson)) : dispatch(setJourneyMapGeoJson(JourneyData.data.geometry)) }}
                                        size='small'
                                        className='bg-gray-600 scale-75'
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              )
                            })}
                          </Box>
                        </>
                      ) : null
                    }

                    <Box className="w-full">
                      <Box className="w-full h-full flex justify-between">
                        {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)]?.map((color, index) => (
                          <div key={index}> {color} </div>
                        ))}
                      </Box>
                      <Box className="w-full h-full flex justify-between ">
                        {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].map((color, index) => (
                          <div
                            key={index}
                            className='w-[10%] h-[30px]'
                            style={{
                              backgroundColor: d3.interpolateYlOrRd((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                    {/* <Box className="w-full flex justify-between items-center">
                      <Box className="w-[40%] flex">Opacity: &nbsp; <strong>{layer.opacity || 0}%</strong></Box>
                      <Box className="w-full flex items-center">
                        <Slider
                          onChange={(e) => {
                            const newData = JourneyData.data.impact_analysis.map((item) => {
                              if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                dispatch(updateCogAddedToMap({ ...item, opacity: e.target.value }))
                                return { ...item, opacity: e.target.value }
                              }
                              return item
                            })
                            dispatch(setJourneyData({
                              ...JourneyData,
                              data: {
                                ...JourneyData.data,
                                impact_analysis: newData
                              }
                            }))

                          }}
                          className='!text-gray-500'
                          size="small"
                          value={layer.opacity}
                          aria-label="Small"
                          color="secondary"
                          valueLabelDisplay="auto"
                        />
                      </Box>
                    </Box> */}

                  </Box>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Box>
      </Card>

    </div>
  )
}

const HazardSection = ({ layers = [] }) => {

  const dispatch = useDispatch()
  const LayersAddedToMap = useSelector(selectCogList)
  const JourneyData = useSelector(selectJourneyData)
  const JourneyMapGeoJson = useSelector(selectJourneyMapGeoJson)
  const JourneyMapDraw = useSelector(selectJourneyMapDraw)
  const [clipLayers, setClipLayers] = useState([])
  const [clipLayersEnabled, setClipLayersEnabled] = useState(false)

  const [drawButtonEnabled, setDrawButtonEnabled] = useState(JourneyMapDraw?.sectionName + JourneyMapDraw?.layerName || false)

  useEffect(() => {
    setDrawButtonEnabled(JourneyMapDraw?.sectionName + JourneyMapDraw?.layerName || false)
  }, [JourneyMapDraw])

  useEffect(() => {
    if (JourneyMapDraw?.status === 'CREATED') {
      dispatch(setJourneyMapIsLoading(true))
      setDrawButtonEnabled(false)

      let geoJSON = JourneyMapDraw?.geoJson

      var requestOptions = {
        headers: { "Content-Type": "application/json" },
        method: 'POST',
        body: JSON.stringify(geoJSON),
      };

      fetch(`https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/statistics?url=${JourneyMapDraw?.layer_url}`, requestOptions)
        .then((resp) => {
          if (resp.status === 200) {
            resp.json().then((response) => {
              setClipLayers([...clipLayers, {
                name: JourneyMapDraw.name,
                geoJson: response.type === 'Feature' ? { type: "FeatureCollection", features: [{ ...response }], name: JourneyMapDraw.geoJsonName, category: "Hazard Clip Layer" } : { ...response, name: JourneyMapDraw.geoJsonName, category: "Hazard Clip Layer" }
              }])
              dispatch(setJourneyMapIsLoading(false))
            })
          }
        }).catch((err) => dispatch(setJourneyMapIsLoading(false)))


      dispatch(setJourneyMapDraw(null))
    } else if (JourneyMapDraw?.status === 'INITIALIZED') {
      setDrawButtonEnabled(JourneyMapDraw?.sectionName + JourneyMapDraw?.layerName)
    }
  }, [{ ...JourneyMapDraw }])

  useEffect(() => {
    dispatch(setJourneyMapGeoJson(JourneyData.data.geometry))
  }, [])


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Box className='w-full h-full flex flex-col gap-2'>
      <Box className='flex justify-between items-center'>
        <p className='text-base font-medium pl-2'>Layers</p>
        <Button size='small' onClick={() => dispatch(openSustainabilityReport())} variant='outlined' startIcon={<Summarize fontSize='small' />}>Report</Button>
      </Box>
      <Card className="w-full h-full !bg-gray-300 p-4">
        <Box className="w-full h-full overflow-auto scroll-bar-hidden rounded">
          {layers?.map((layer, index) => {
            return (
              <Accordion defaultExpanded={Boolean(layer.average_score)} key={index} className={`w-full !border-[3px] !border-b-0 !border-gray-300 `}>
                <AccordionSummary>
                  <Box className="relative flex w-full justify-between gap-5">
                    <Box className='flex gap-3 w-[90%]'>
                      <Box className='lg:w-[90%] flex flex-col gap-1'>
                        <p className="relative text-sm font-medium text-gray-700 break-words overflow-hidden leading-[18px] flex items-center">
                          {layer.layer_name} {!layer.cog_status === true ? <LoadingDots className={"!text-gray-400"} /> : null}
                        </p>
                      </Box>
                    </Box>
                    <Box onClick={(e) => e.stopPropagation()} className='flex justify-center items-center gap-2 w-[30%]'>
                      <Tooltip title="Opacity" placement='top' arrow>
                        <DeblurRounded

                          id={`basic-button-${index}`}
                          aria-controls={open ? `basic-menu-${index}` : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={() => { handleClick(document.getElementById(`basic-button-${index}`)); }}

                          className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>
                      <Tooltip title="Bring to Front" placement='top' arrow>
                        <FlipToFrontRounded onClick={() => {

                          if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                            return
                          }

                          LayersAddedToMap.map((item) => {
                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              dispatch(updateCogAddedToMap({ ...item, priority: 1 }))
                            } else {
                              dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                            }
                          })

                          const newData = JourneyData.data.impact_analysis.map((item) => {

                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              return { ...item, priority: 1 }
                            }
                            return { ...item, priority: 0 }
                          })
                          dispatch(setJourneyData({
                            ...JourneyData,
                            data: {
                              ...JourneyData.data,
                              impact_analysis: newData,
                            }
                          }))

                        }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>
                      <Tooltip title="Move Back" placement='top' arrow>
                        <FlipToBackRounded onClick={() => {

                          if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                            return
                          }
                          const newData = JourneyData.data.impact_analysis.map((item) => {

                            LayersAddedToMap.map((item) => {
                              if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                dispatch(updateCogAddedToMap({ ...item, priority: -1 }))
                              } else {
                                dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                              }
                            })

                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              return { ...item, priority: -1 }
                            }
                            return { ...item }
                          })
                          dispatch(setJourneyData({
                            ...JourneyData,
                            data: {
                              ...JourneyData.data,
                              impact_analysis: newData,
                            }
                          }))
                        }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>

                      <Tooltip title={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0 ? "Remove from map" : "Add to Map"} placement='top' arrow>
                        <Switch
                          size='small' className='bg-gray-600'
                          onChange={(e) => {
                            if (layer.rasterLayerUrl) {
                              e ? dispatch(addCogToMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Hazard ${layer.layer_name}` })) : dispatch(removeCogFromMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Hazard ${layer.layer_name}` }))
                            }
                          }}
                          checked={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="flex flex-col justify-between w-full h-full items-center gap-6">

                    <Box className="w-full flex justify-between gap-2">
                      <Button onClick={() => dispatch(setJourneyMapBounds([...layer.bounds]))} variant='outlined' disableElevation className='w-full' startIcon={<LocationSearching fontSize='small' />}>Ideal&nbsp;Zoom</Button>
                      <Button onClick={() => setClipLayersEnabled(!clipLayersEnabled)} variant={clipLayersEnabled ? 'contained' : 'outlined'} disableElevation className='w-full' startIcon={<PhotoSizeSelectLarge fontSize='small' />}>Clip&nbsp;Layer</Button>
                    </Box>

                    {
                      clipLayersEnabled && layer?.summary_stats && layer.average_score !== 0 ? (
                        <>
                          <Box className="w-full flex flex-col gap-2">
                            <Box className='w-full flex justify-between items-baseline'>
                              <p className='text-sm font-medium'>Clip Layers</p>
                              <Box className="space-x-2">
                                <Tooltip title="Upload file" placement='top' arrow>
                                  <IconButton component="label" size='small' centerRipple={false} className="!rounded !bg-gray-100 !text-gray-800" >
                                    <FileUploadOutlined fontSize='small' />
                                    <VisuallyHiddenInput accept='.geojson' onChange={(event) => {

                                      const fileReader = new FileReader();
                                      const { files } = event.target;
                                      if (!files || files.length === 0) return

                                      fileReader.readAsText(files[0], "UTF-8");
                                      fileReader.onload = e => {
                                        const content = e.target.result;
                                        let geoJSON = null
                                        try {
                                          geoJSON = JSON.parse(content)
                                          L.geoJSON(geoJSON)
                                        } catch {
                                          alert("Not a valid geoJson")
                                          return
                                        }

                                        var requestOptions = {
                                          headers: { "Content-Type": "application/json" },
                                          method: 'POST',
                                          body: JSON.stringify(geoJSON),
                                        };

                                        fetch(`https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/statistics?url=${layer.layer_url}`, requestOptions)
                                          .then((resp) => {
                                            if (resp.status === 200) {
                                              resp.json().then((response) => {
                                                setClipLayers([...clipLayers, {
                                                  name: geoJSON.name,
                                                  geoJson: { ...response, name: geoJSON.name + " Hazard " + layer.layer_name, category: "Hazard Clip Layer" }
                                                }])
                                              })
                                            }
                                          }).catch((err) => console.log(err))
                                      };

                                    }} type="file" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Draw on Map" placement='top' arrow>
                                  <IconButton onClick={() => {
                                    let timestamp = dayjs().unix().toString()
                                    dispatch(setJourneyMapDraw({
                                      status: "INITIALIZED",
                                      layer_url: layer.layer_url,
                                      sectionName: "Hazard",
                                      layerName: layer.layer_name,
                                      name: "Custom Layer #" + timestamp,
                                      geoJsonName: "Custom Layer #" + timestamp + " Hazard " + layer.layer_name,
                                      category: "Hazard Clip Layer"
                                    }))
                                  }} size='small' centerRipple={false} className={`!rounded ${drawButtonEnabled === "Hazard" + layer?.layer_name ? "!bg-primary-600 !text-gray-100" : "!bg-gray-100 !text-gray-800"}`} >
                                    <DrawRounded fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>

                            {[{
                              name: "Administrative Boundaries",
                              geoJson: { ...layer?.summary_stats, name: "Administrative Boundaries" + " Hazard " + layer.layer_name, category: "Hazard Clip Layer" }
                            }, ...clipLayers].map((item, index) => {
                              return (
                                <Box className="w-full px-1" key={index}>
                                  <Box className="w-full border border-gray-300 p-3 rounded flex justify-between items-center">
                                    <Box className='flex flex-col gap-1'>
                                      <p className="relative text-sm font-medium break-words overflow-hidden leading-[18px]">
                                        {item.name}
                                      </p>
                                    </Box>
                                    <Box className='flex gap-2 justify-center'>
                                      <Tooltip title="Download" placement='top' arrow>
                                        <FileDownloadOutlined fontSize='small' className='text-primary-800 hover:text-primary-900 cursor-pointer' />
                                      </Tooltip>

                                      <Switch
                                        checked={(item.name + " Hazard " + layer.layer_name === JourneyMapGeoJson?.name)}
                                        onChange={(e) => { e ? dispatch(setJourneyMapGeoJson(item.geoJson)) : dispatch(setJourneyMapGeoJson(JourneyData.data.geometry)) }}
                                        size='small'
                                        className='bg-gray-600 scale-75'
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              )
                            })}
                          </Box>
                        </>
                      ) : null
                    }

                    <Box className="w-full">
                      <Box className="w-full h-full flex justify-between">
                        {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)]?.map((color, index) => (
                          <div key={index}> {color} </div>
                        ))}
                      </Box>
                      <Box className="w-full h-full flex justify-between ">
                        {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].map((color, index) => (
                          <div
                            key={index}
                            className='w-[10%] h-[30px]'
                            style={{
                              backgroundColor: d3.interpolateYlOrRd((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                            }}
                          />
                        ))}
                      </Box>
                    </Box>

                  </Box>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Box>
      </Card>
    </Box>
  )
}

const Sustainability = ({ layers = [] }) => {

  const dispatch = useDispatch()
  const LayersAddedToMap = useSelector(selectCogList)
  const JourneyData = useSelector(selectJourneyData)
  const JourneyMapGeoJson = useSelector(selectJourneyMapGeoJson)
  const JourneyMapDraw = useSelector(selectJourneyMapDraw)
  const [clipLayers, setClipLayers] = useState([])
  const [clipLayersEnabled, setClipLayersEnabled] = useState(false)

  const [drawButtonEnabled, setDrawButtonEnabled] = useState(JourneyMapDraw?.sectionName + JourneyMapDraw?.layerName || false)

  useEffect(() => {
    setDrawButtonEnabled(JourneyMapDraw?.sectionName + JourneyMapDraw?.layerName || false)
  }, [JourneyMapDraw])

  useEffect(() => {
    if (JourneyMapDraw?.status === 'CREATED') {
      dispatch(setJourneyMapIsLoading(true))
      setDrawButtonEnabled(false)

      let geoJSON = JourneyMapDraw?.geoJson

      var requestOptions = {
        headers: { "Content-Type": "application/json" },
        method: 'POST',
        body: JSON.stringify(geoJSON),
      };

      fetch(`https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/statistics?url=${JourneyMapDraw?.layer_url}`, requestOptions)
        .then((resp) => {
          if (resp.status === 200) {
            resp.json().then((response) => {
              setClipLayers([...clipLayers, {
                name: JourneyMapDraw.name,
                geoJson: response.type === 'Feature' ? { type: "FeatureCollection", features: [{ ...response }], name: JourneyMapDraw.geoJsonName, category: "Sustainability Clip Layer" } : { ...response, name: JourneyMapDraw.geoJsonName, category: "Sustainability Clip Layer" }
              }])
              dispatch(setJourneyMapIsLoading(false))
            })
          }
        }).catch((err) => dispatch(setJourneyMapIsLoading(false)))

      dispatch(setJourneyMapDraw(null))
    } else if (JourneyMapDraw?.status === 'INITIALIZED') {
      setDrawButtonEnabled(JourneyMapDraw?.sectionName + JourneyMapDraw?.layerName)
    }
  }, [{ ...JourneyMapDraw }])

  useEffect(() => {
    dispatch(setJourneyMapGeoJson(JourneyData.data.geometry))
  }, [])


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Box className='w-full h-full flex flex-col gap-2'>
      <Box className='flex justify-between items-center'>
        <p className='text-base font-medium pl-2'>Layers</p>
        {/* <IconButton size='small' sx={{ border: "1px solid #ccc" }} centerRipple={false} className="!rounded hover:!bg-base-50 !bg-white !text-base-950" >
          <AddRounded fontSize='small' />
        </IconButton> */}
        <Button size='small' onClick={() => dispatch(openSustainabilityReport())} variant='outlined' startIcon={<Summarize fontSize='small' />}>Report</Button>
      </Box>
      <Card className="w-full h-full !bg-gray-300 p-4">
        <Box className="w-full h-full overflow-auto scroll-bar-hidden rounded">
          {layers?.map((layer, index) => {
            return (
              <Accordion defaultExpanded={Boolean(layer.average_score)} key={index} className={`w-full !border-[3px] !border-b-0 !border-gray-300 `}>
                <AccordionSummary>
                  <Box className="relative flex w-full justify-between gap-5">
                    <Box className='flex gap-3 w-[90%]'>
                      <Box className='lg:w-[90%] flex flex-col gap-1'>
                        <p className="relative text-sm font-medium text-gray-700 break-words overflow-hidden leading-[18px] flex items-center">
                          {layer.layer_name} {!layer.cog_status === true ? <LoadingDots className={"!text-gray-400"} /> : null}
                        </p>
                      </Box>
                    </Box>
                    <Box onClick={(e) => e.stopPropagation()} className='flex justify-center items-center gap-2 w-[30%]'>
                      <Tooltip title="Opacity" placement='top' arrow>
                        <DeblurRounded

                          id={`basic-button-${index}`}
                          aria-controls={open ? `basic-menu-${index}` : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={() => { handleClick(document.getElementById(`basic-button-${index}`)); }}

                          className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>
                      <Tooltip title="Bring to Front" placement='top' arrow>
                        <FlipToFrontRounded onClick={() => {

                          if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                            return
                          }

                          LayersAddedToMap.map((item) => {
                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              dispatch(updateCogAddedToMap({ ...item, priority: 1 }))
                            } else {
                              dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                            }
                          })

                          const newData = JourneyData.data.sustainability.map((item) => {

                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              return { ...item, priority: 1 }
                            }
                            return { ...item, priority: 0 }
                          })
                          dispatch(setJourneyData({
                            ...JourneyData,
                            data: {
                              ...JourneyData.data,
                              sustainability: newData,
                            }
                          }))

                        }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>
                      <Tooltip title="Move Back" placement='top' arrow>
                        <FlipToBackRounded onClick={() => {

                          if ((LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length === 0) {
                            return
                          }
                          const newData = JourneyData.data.sustainability.map((item) => {

                            LayersAddedToMap.map((item) => {
                              if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                dispatch(updateCogAddedToMap({ ...item, priority: -1 }))
                              } else {
                                dispatch(updateCogAddedToMap({ ...item, priority: 0 }))
                              }
                            })

                            if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                              return { ...item, priority: -1 }
                            }
                            return { ...item }
                          })
                          dispatch(setJourneyData({
                            ...JourneyData,
                            data: {
                              ...JourneyData.data,
                              sustainability: newData,
                            }
                          }))
                        }} className='text-gray-500 hover:text-gray-900' fontSize='small' />
                      </Tooltip>

                      <Tooltip title={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0 ? "Remove from map" : "Add to Map"} placement='top' arrow>
                        <Switch
                          size='small' className='bg-gray-600'
                          onChange={(e) => {
                            if (layer.rasterLayerUrl) {
                              e ? dispatch(addCogToMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Sustainability ${layer.layer_name}` })) : dispatch(removeCogFromMap({ rasterLayerUrl: layer.rasterLayerUrl, opacity: layer.opacity, name: `Sustainability ${layer.layer_name}` }))
                            }
                          }}
                          checked={(LayersAddedToMap?.filter((item) => item.rasterLayerUrl === layer.rasterLayerUrl)).length !== 0}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </AccordionSummary>
                {/* <AccordionDetails className=''>
                  <Box className="h-full w-full flex items-center justify-center align-middle overflow-auto">
                    <Box className="h-full w-full ">
                      {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 &&
                        <Box className=" flex flex-col justify-center items-center align-middle">
                          <Box className="w-full p-4 flex justify-center items-center align-middle">
                            <GaugeChart min={layer?.stats?.b1?.min} max={layer?.stats?.b1?.max} value={layer.average_score} colors={[...layer?.stats?.b1?.histogram[1]].map((color) => (d3.interpolateRdYlGn((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min))))} />
                          </Box>
                          <p className="text-gray-400 text-center leading-[18px]">Sustainability Score</p>
                        </Box>
                      }
                    </Box>
                    <Box className="flex justify-between w-[30%] h-[210px] items-center">
                      <Box className="w-full h-full flex flex-col justify-between ">
                        {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)].reverse()?.map((color, index) => (
                          <div key={index} className="w-full flex flex-col justify-centertext border border-white">
                            {color}
                          </div>
                        ))}
                      </Box>
                      <Box className="w-full h-full flex flex-col justify-between ">
                        {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].reverse().map((color, index) => (
                          <div
                            key={index}
                            className='w-[30px] h-[30px]'
                            style={{
                              backgroundColor: d3.interpolateRdYlGn((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails> */}
                <AccordionDetails>
                  <Box className="flex flex-col justify-between w-full h-full items-center gap-6">

                    <Box className="w-full flex justify-between gap-2">
                      <Button onClick={() => dispatch(setJourneyMapBounds([...layer.bounds]))} variant='outlined' disableElevation className='w-full' startIcon={<LocationSearching fontSize='small' />}>Ideal&nbsp;Zoom</Button>
                      <Button onClick={() => setClipLayersEnabled(!clipLayersEnabled)} variant={clipLayersEnabled ? 'contained' : 'outlined'} disableElevation className='w-full' startIcon={<PhotoSizeSelectLarge fontSize='small' />}>Clip&nbsp;Layer</Button>
                    </Box>

                    {
                      clipLayersEnabled && layer?.summary_stats && layer.average_score !== 0 ? (
                        <>
                          <Box className="w-full flex flex-col gap-2">
                            <Box className='w-full flex justify-between items-baseline'>
                              <p className='text-sm font-medium'>Clip Layers</p>
                              <Box className="space-x-2">
                                <Tooltip title="Upload file" placement='top' arrow>
                                  <IconButton component="label" size='small' centerRipple={false} className="!rounded !bg-gray-100 !text-gray-800" >
                                    <FileUploadOutlined fontSize='small' />
                                    <VisuallyHiddenInput accept='.geojson' onChange={(event) => {

                                      const fileReader = new FileReader();
                                      const { files } = event.target;
                                      if (!files || files.length === 0) return

                                      fileReader.readAsText(files[0], "UTF-8");
                                      fileReader.onload = e => {
                                        const content = e.target.result;
                                        let geoJSON = null
                                        try {
                                          geoJSON = JSON.parse(content)
                                          L.geoJSON(geoJSON)
                                        } catch {
                                          alert("Not a valid geoJson")
                                          return
                                        }

                                        var requestOptions = {
                                          headers: { "Content-Type": "application/json" },
                                          method: 'POST',
                                          body: JSON.stringify(geoJSON),
                                        };

                                        fetch(`https://z0c3v6ezdg.execute-api.ap-south-1.amazonaws.com/cog/statistics?url=${layer.layer_url}`, requestOptions)
                                          .then((resp) => {
                                            if (resp.status === 200) {
                                              resp.json().then((response) => {
                                                setClipLayers([...clipLayers, {
                                                  name: geoJSON.name,
                                                  geoJson: { ...response, name: geoJSON.name + " Sustainability " + layer.layer_name, category: "Sustainability Clip Layer" }
                                                }])
                                              })
                                            }
                                          }).catch((err) => console.log(err))
                                      };

                                    }} type="file" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Draw on Map" placement='top' arrow>
                                  <IconButton onClick={() => {
                                    let timestamp = dayjs().unix().toString()
                                    dispatch(setJourneyMapDraw({
                                      status: "INITIALIZED",
                                      layer_url: layer.layer_url,
                                      sectionName: "Sustainability",
                                      layerName: layer.layer_name,
                                      name: "Custom Layer #" + timestamp,
                                      geoJsonName: "Custom Layer #" + timestamp + " Sustainability " + layer.layer_name,
                                      category: "Sustainability Clip Layer"
                                    }))
                                  }} size='small' centerRipple={false} className={`!rounded ${drawButtonEnabled === "Sustainability" + layer?.layer_name ? "!bg-primary-600 !text-gray-100" : "!bg-gray-100 !text-gray-800"}`} >
                                    <DrawRounded fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>

                            {[{
                              name: "Administrative Boundaries",
                              geoJson: { ...layer?.summary_stats, name: "Administrative Boundaries" + " Sustainability " + layer.layer_name, category: "Sustainability Clip Layer" }
                            }, ...clipLayers].map((item, index) => {
                              return (
                                <Box className="w-full px-1" key={index}>
                                  <Box className="w-full border border-gray-300 p-3 rounded flex justify-between items-center">
                                    <Box className='flex flex-col gap-1'>
                                      <p className="relative text-sm font-medium break-words overflow-hidden leading-[18px]">
                                        {item.name}
                                      </p>
                                    </Box>
                                    <Box className='flex gap-2 justify-center'>
                                      <Tooltip title="Download" placement='top' arrow>
                                        <FileDownloadOutlined fontSize='small' className='text-primary-800 hover:text-primary-900 cursor-pointer' />
                                      </Tooltip>

                                      {
                                        item?.type && item.type === 'raster' ? (
                                          <Switch
                                            checked={(LayersAddedToMap?.filter((layerItem) => layerItem.name === `Sustainability ${item?.name}`)).length !== 0}
                                            onChange={(e) => { e ? dispatch(addCogToMap({ rasterLayerUrl: item?.rasterLayerUrl, opacity: item?.opacity, name: `Sustainability ${item?.name}` })) : dispatch(removeCogFromMap({ rasterLayerUrl: item?.rasterLayerUrl, opacity: item?.opacity, name: `Sustainability ${item?.name}` })) }}
                                            size='small'
                                            className='bg-gray-600 scale-75'
                                          />
                                        ) : (
                                          <Switch
                                            checked={(item.name + " Sustainability " + layer.layer_name === JourneyMapGeoJson?.name)}
                                            onChange={(e) => { e ? dispatch(setJourneyMapGeoJson(item.geoJson)) : dispatch(setJourneyMapGeoJson(JourneyData.data.geometry)) }}
                                            size='small'
                                            className='bg-gray-600 scale-75'
                                          />
                                        )
                                      }
                                    </Box>
                                  </Box>
                                </Box>
                              )
                            })}
                          </Box>
                        </>
                      ) : null
                    }

                    <Box className="h-full w-full flex items-center justify-center align-middle overflow-auto">
                      <Box className="h-full w-full ">
                        {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 &&
                          <Box className=" flex flex-col justify-center items-center align-middle">
                            <Box className="w-full p-4 flex justify-center items-center align-middle">
                              <GaugeChart min={layer?.stats?.b1?.min} max={layer?.stats?.b1?.max} value={layer.average_score} colors={[...layer?.stats?.b1?.histogram[1]].map((color) => (d3.interpolateRdYlGn((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min))))} />
                            </Box>
                            <p className="text-gray-400 text-center leading-[18px]">Sustainability Score</p>
                          </Box>
                        }
                      </Box>
                      <Box className="flex justify-between w-[30%] h-[210px] items-center">
                        <Box className="w-full h-full flex flex-col justify-between ">
                          {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)].reverse()?.map((color, index) => (
                            <div key={index} className="w-full flex flex-col justify-centertext border border-white">
                              {color}
                            </div>
                          ))}
                        </Box>
                        <Box className="w-full h-full flex flex-col justify-between ">
                          {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].reverse().map((color, index) => (
                            <div
                              key={index}
                              className='w-[30px] h-[30px]'
                              style={{
                                backgroundColor: d3.interpolateRdYlGn((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Box>

                    {/* <Box className="w-full flex justify-between items-center">
                      <Box className="w-[40%] flex">Opacity: &nbsp; <strong>{layer.opacity || 0}%</strong></Box>
                      <Box className="w-full flex items-center">
                        <Slider
                          onChange={(e) => {
                            const newData = JourneyData.data.sustainability.map((item) => {

                              if (item.rasterLayerUrl === layer.rasterLayerUrl) {
                                dispatch(updateCogAddedToMap({ ...item, opacity: e.target.value }))
                                return { ...item, opacity: e.target.value }
                              }
                              return item
                            })
                            dispatch(setJourneyData({
                              ...JourneyData,
                              data: {
                                ...JourneyData.data,
                                sustainability: newData,
                              }
                            }))
                          }}
                          className='!text-gray-500'
                          size="small"
                          value={layer.opacity}
                          aria-label="Small"
                          color="secondary"
                          valueLabelDisplay="auto"
                        />
                      </Box>
                    </Box> */}
                  </Box>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Box>
      </Card>
    </Box>
  )
}

const ExtremeEventsList = ({ eventsList = [] }) => {

  const dispatch = useDispatch()
  const sections = useSelector(selectSections)

  const handleViewDetails = (event) => {
    dispatch(setJourneyMapIsLoading(true))
    dispatch(closeEventsListView())
    dispatch(setCurrentSection(sections[1]))

    fetch(`https://pixstack.leadsnextech.com/task_details?taskId=${event.task_id}`).then((resp) => {
      if (resp.status === 200) {

        resp.json().then((taskDetails) => {
          dispatch(setJourneyInputLocationData({
            stateDistrictDropdowns: {
              selectedState: {
                stateName: taskDetails.data.state,
                stateCode: taskDetails.data.state,
              },
              selectedDistrict: {
                districtName: taskDetails.data.district,
                districtCode: taskDetails.data.district_id,
              }
            }
          }))
          dispatch(setJourneyMapGeoJson(taskDetails.data.geometry))
          dispatch(setContextVariable({
            name: event.task_name.split(' - ')[1],
            value: event.task_name.split(' - ')[1]
          }))
          dispatch(setJourneyData(taskDetails))
          dispatch(setJourneyMapIsLoading(false))
        }).catch((err) => dispatch(setJourneyMapIsLoading(false)))
      }
      dispatch(setJourneyMapIsLoading(false))(false)
    }).catch((err) => dispatch(setJourneyMapIsLoading(false)))
  }

  return (
    <Box>
      {eventsList.map((event, index) => {
        return (
          <Card key={index} elevation={0} className={`p-3 border border-gray-300 hover:border-gray-400`}>
            <div className="relative flex gap-4 justify-between align-middle">
              <div className='flex gap-3 w-[90%]'>
                <div className='lg:w-[90%] flex flex-col gap-1'>
                  <p className="relative text-sm font-medium text-gray-700 break-words overflow-hidden leading-[18px] flex items-center">
                    {event.task_name}
                  </p>
                  <p className="text-gray-400 text-xs leading-[18px]">{event.status}</p>
                </div>
              </div>
              <div onClick={(e) => e.stopPropagation()} className='flex justify-center w-[10%]'>
                <IconButton size='small' onClick={() => handleViewDetails(event)} className='!h-fit'>
                  <VisibilityRounded fontSize='small' />
                </IconButton>
              </div>
            </div>
          </Card>
        )
      })}
    </Box>
  )
}

const Explore = () => {

  const dispatch = useDispatch()

  const sections = useSelector(selectSections)
  const currentSection = useSelector(selectCurrentSection)
  const savedJourneyInputData = useSelector(selectJourneyInputData)

  const [getTrendAnalysis, getTrendAnalysisResponse] = useGetTrendAnalysisMutation()

  const [taskFilters, setTaskFilters] = useState({
    page: 1,
    per_page: 10,
    q: undefined
  })

  const { data: eventsList, isFetching: isEventsListFetching, refetch: eventListRefetch } = useGetListOfTasksQuery(taskFilters)

  const selectedState = savedJourneyInputData?.location?.stateDistrictDropdowns?.selectedState
  const selectedDistrict = savedJourneyInputData?.location?.stateDistrictDropdowns?.selectedDistrict
  const selectedContextVariable = savedJourneyInputData?.contextVariable
  const selectedDate = savedJourneyInputData?.date

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (!currentSection?.id === 0) return
    if (!getTrendAnalysisResponse.isSuccess) return

    dispatch(setJourneyData(getTrendAnalysisResponse.data))
    dispatch(handleNextSection())
  }, [getTrendAnalysisResponse])



  const handleNext = () => {
    setAlert(null)
    if (currentSection?.id === sections?.length - 1) {
      setIsModalOpen(true)
      return
    }

    if (!selectedState.stateName || !selectedDistrict.districtName || !selectedContextVariable.name) {
      setAlert({
        type: "error",
        message: <p>Please select <strong className='font-bold'>State</strong>, <strong className='font-bold'>District</strong> and <strong className='font-bold'>Context Variables</strong> before proceeding.</p>
      })
      return
    }

    if (currentSection?.id === 0) {
      const payload = {
        taskName: `${selectedState.stateName} ${selectedDistrict.districtName} - ${selectedContextVariable.name}`,
        locationType: "geojson",
        stateName: selectedState.stateName,
        stateCode: selectedState.stateCode,
        districtName: selectedDistrict.districtName,
        district_id: selectedDistrict.districtCode,
        gjson: {},
        season: "rabi",
        weather: {
          indices: [selectedContextVariable.value]
        },
        years: [["2021", "01"], ["2024", "01"]],
        date: selectedDate
      }
      getTrendAnalysis(payload)
      return
    }

    dispatch(handleNextSection())
  }

  const handlePrev = () => {
    setAlert(null)
    if (currentSection?.id === 1) setIsModalOpen(true)
    else dispatch(handlePrevSection())
  }

  const handleStartAgain = () => {
    dispatch(resetJourney())
    setIsModalOpen(false)
    dispatch(setCurrentSection(sections[0]))

  }
  const JourneyData = useSelector(selectJourneyData)

  const taskListViewOpen = useSelector(selectIsEventsListViewOpen)

  const [taskFailedModalOpen, setTaskFailedModalOpen] = useState(false)

  useEffect(() => {
    if (!JourneyData?.data) return
    if (JourneyData?.data.current_status === 'Failed') {
      setTaskFailedModalOpen(true)
      return
    }
    if (JourneyData.data.current_status === 'In Progress') {
      const timeout = setTimeout(() => {

        fetch(`https://pixstack.leadsnextech.com/task_details?taskId=${JourneyData?.data.task_id}`).then((resp) => {
          if (resp.status !== 200) {
            dispatch(setJourneyData({ ...JourneyData }))
          }
          else {
            resp.json().then((taskDetailsData) => {
              dispatch(setJourneyData({ ...taskDetailsData }))
            })
          }
        })
      }, 6000)

      return () => {
        // clears timeout before running the new effect
        clearTimeout(timeout);
      };
    }
  })

  useEffect(() => {
    if (taskListViewOpen) {
      eventListRefetch()
    }
  }, [taskListViewOpen])

  const { toPDF, targetRef } = usePDF({ filename: 'report.pdf' });
  const isSustainabilityReportOpen = useSelector(selectIsSustainabilityReportOpen)

  return (
    <PixStackJourney
      onNext={handleNext}
      onPrev={handlePrev}
      isNextLoading={getTrendAnalysisResponse.isLoading}
    >
      <Collapse in={Boolean(alert)}>
        <Alert
          severity={alert?.type}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert(null);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {alert?.message}
        </Alert>
      </Collapse>
      <Dialog
        open={isModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => { }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you really want to Start Again ?"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-slide-description">
            You will not be able to see the current Journey data if you start again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button className='!text-white !bg-red-500' variant='contained' onClick={() => handleStartAgain()}>Start Anyway</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isSustainabilityReportOpen}
        fullWidth={true}
        maxWidth={"lg"}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => { dispatch(closeSustainabilityReport()) }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Sustainability Report"}</DialogTitle>
        <DialogContent dividers className='!p-0'>
          <div ref={targetRef} className='!w-full !h-full !p-10'>
            <JourneyReport reportData={{ ...JourneyData?.data, contextVariable: selectedContextVariable.name }} />
          </div>

        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => dispatch(closeSustainabilityReport())}>Cancel</Button>
          <Button className='!text-white !bg-primary-500' variant='contained' onClick={() => toPDF()}>Download</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={taskFailedModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => { handleStartAgain(); setTaskFailedModalOpen(false) }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Oops! Task Failed"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-slide-description" className='!text-gray-700'>
            Due to lack of current satellite data availability we couldn't  complete the analysis. please select a different location and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='!text-white !bg-red-500' variant='contained' onClick={() => { handleStartAgain(); setTaskFailedModalOpen(false) }}>Start Again</Button>
        </DialogActions>
      </Dialog>

      <Box className="md:w-[480px] lg:w-[480px] h-full">
        {/* {taskListViewOpen ? (
          <Box className="w-full h-full p-6 pb-12">
            <ExtremeEventsList eventsList={eventsList?.data} />
            <Box className="p-2 w-full sticky bottom-0 bg-gray-100">
              <Pagination count={3} variant="outlined" shape="rounded" />
            </Box>
          </Box>
        ) : null} */}

        {taskListViewOpen ? (
          <Box className="w-full h-full pb-12">
            <Box className="p-2 px-5  w-full h-[13%] top-0 flex justify-center bg-gray-100">
              <GoogleSearchBar onChange={(e) => e.target.value ? setTaskFilters((prev) => { return { ...prev, q: e.target.value } }) : setTaskFilters((prev) => { return { ...prev, q: undefined } })} />
            </Box>
            <Box className=" h-[86%] px-5 overflow-auto scroll-bar-hidden">
              {
                isEventsListFetching ? (
                  <Box className="w-full h-full flex justify-center items-center p-1 bg-gray-200 !rounded">
                    <CircularProgress />
                  </Box>
                ) : <Box className="p-2 bg-gray-200 rounded"><ExtremeEventsList eventsList={eventsList?.data} /></Box>
              }

            </Box>
            <Box className="p-2 w-full h-[11%] sticky bottom-0 flex justify-end items-end bg-gray-100">
              <Pagination size='small' onChange={(e, page) => setTaskFilters((prev) => { return { ...prev, page: page } })} count={eventsList?.total_pages || 5} />
            </Box>
          </Box>
        ) : null}

        {currentSection?.id === 0 && !taskListViewOpen ? (
          <Box className="w-full h-full p-6 pb-12">
            <OverviewSection />
          </Box>
        ) : null}
        {currentSection?.id === 1 ? (
          <Box className="w-full h-full px-6 py-3 bg-gray-100">
            <SpaceTimeDynamicsSection layers={JourneyData?.data?.trend_analysis} />
          </Box>
        ) : null}

        {currentSection?.id === 2 ? (
          <Box className="w-full h-full p-6 bg-gray-100">
            <RiskIntensity layers={JourneyData?.data?.event_intensity} />
          </Box>
        ) : null}

        {currentSection?.id === 3 ? (
          <Box className="w-full h-full p-6">
            <HazardSection layers={JourneyData?.data?.impact_analysis} />
          </Box>
        ) : null}

        {currentSection?.id === 4 ? (
          <Box className="w-full h-full p-6">
            <Sustainability layers={JourneyData?.data?.sustainability} />
          </Box>
        ) : null}

      </Box>

    </PixStackJourney>
  )
}

export default Explore