import { IconButton, Paper, Divider, Box } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

const ZoomControl = ({
  zoom = 4,
  minZoom = 0,
  maxZoom = 18,
  zoomIn = () => { },
  zoomOut = () => { }
}) => {

  return (
    <Box className="w-full flex justify-end">
      <Paper className='flex flex-col !w-fit !rounded-sm'>
        <IconButton disabled={zoom >= maxZoom} onClick={zoomIn} color="inherit" centerRipple={false} className="!rounded-sm">
          <AddRoundedIcon className='!text-[16px]' />
        </IconButton>
        <Divider />
        <IconButton disabled={zoom <= minZoom} onClick={zoomOut} color="inherit" centerRipple={false} className="!rounded-sm">
          <RemoveRoundedIcon className='!text-[16px]' />
        </IconButton>
      </Paper>
    </Box>
  )
}

export default ZoomControl