import React from "react"
import { arc } from "d3-shape"
import { scaleLinear } from "d3-scale"
import { interpolateRdYlGn } from "d3-scale-chromatic"

const GaugeChart = ({
  colors = [],
  value = 0.40433195044084635,
  min = -1.9064550399780273,
  max = 1.5256937742233276,
  colorSchema = interpolateRdYlGn,
}) => {

  const backgroundArc = arc()
    .innerRadius(0.90)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(1)
    ()

  const percentScale = scaleLinear()
    .domain([min, max])
    .range([0, 1])

  const percent = percentScale(value)

  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true)

  const angle = angleScale(percent)

  const filledArc = arc()
    .innerRadius(0.90)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(angle)
    .cornerRadius(1)
    ()

  const markerLocation = getCoordsOnArc(
    angle,
    1 - ((1 - 0.90) / 2),
  )

  return (
    <div className="flex flex-col justify-center items-center">
      <svg style={{ overflow: "visible" }}
        width="100%"
        viewBox={[
          -1, -1,
          2, 1,
        ].join(" ")}>
        <defs>
          <linearGradient
            id="Gauge__gradient"
            gradientUnits="userSpaceOnUse"
            x1="-1"
            x2="1"
            y2="0">
            {colors.map((color, index) => (
              <stop
                key={color}
                stopColor={color}
                offset={`${index
                  / (colors.length - 1)
                  }`}
              />
            ))}
          </linearGradient>
        </defs>
        <path
          d={backgroundArc}
          fill="#dbdbe7"
        />
        <path
          d={filledArc}
          fill="url(#Gauge__gradient)"
        />
        <circle
          cx={markerLocation[0]}
          cy={markerLocation[1]}
          r="0.1"
          stroke={colorSchema(percent)}
          strokeWidth="0.06"
          fill={"#ffffff"}
        />
      </svg>
      <div className="lg:-mt-8 -mt-6 font-bold lg:text-4xl text-2xl text-gray-700">
        {(value.toFixed(2))}
      </div>
    </div>
  )
}

const getCoordsOnArc = (angle, offset = 10) => [
  Math.cos(angle - (Math.PI / 2)) * offset,
  Math.sin(angle - (Math.PI / 2)) * offset,
]

export default GaugeChart;