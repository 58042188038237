import queryString from 'query-string';
import { apiSlice } from "./apiSlice"

export const MasterDataApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getStatesList: builder.query({
      query: () => "/location_master?level=State",
      keepUnusedDataFor: 1,
    }),
    getStatesByFilters: builder.query({
      query: (filters) => `/location_master?level=State&${queryString.stringify(filters)}`,
      keepUnusedDataFor: 1
    }),
    getDistrictssList: builder.query({
      query: () => "/location_master?level=District",
      keepUnusedDataFor: 1
    }),
    getDistrictsByFilters: builder.query({
      query: (filters) => `/location_master?level=District&${queryString.stringify(filters)}`,
      keepUnusedDataFor: 1
    }),
  })
})

export const {
  useGetStatesListQuery,
  useGetStatesByFiltersQuery,
  useGetDistrictssListQuery,
  useGetDistrictsByFiltersQuery
} = MasterDataApiSlice 