import React from 'react'
import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'

import Header from 'layout/Header'
import HeaderLogoSection from 'layout/Header/LogoSection'
import HeaderLinksSection from 'layout/Header/LinksSection'


const MainLayout = () => {

  return (
    <Layout className='w-[100dvw] h-[100dvh]'>
      <Header className={"sticky top-0"}>
        <HeaderLogoSection />
        <HeaderLinksSection />
      </Header>
      <Layout className='w-full h-[calc(100dvh-60px)]'>
        <Outlet />
      </Layout>
    </Layout>
  )
}

export default MainLayout