import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from 'pages/Home'
import RequireAuth from 'services/auth/RequireAuth'
import Dashboard from 'pages/Dashboard'
import MainLayout from 'layout/MainLayout'
import DataPipeline from 'pages/DataPipeline'
import ClimateSmartEvents from 'pages/ClimateSmartEvents'
import EventDetails from 'pages/EventDetails'
import Tasks from 'pages/DataPipeline/Tasks'
import Task from 'pages/DataPipeline/Task'
import PixstackJourney from 'pages/PixstackJourney'
import Explore from 'pages/Explore'
import UPDashboard from 'pages/UPDashboard'
import CropDashboard from 'pages/CropDashboard'

const App = () => {
  return (
    <Routes>
      <Route path='/'>
      <Route index element={<Home />} />
        <Route element={<RequireAuth />}>
          
          <Route element={<MainLayout />}>
            {/* <Route index element={<Dashboard />} /> */}
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/up-dashboard' element={<UPDashboard />} />
            <Route path='/crop-dashboard' element={<CropDashboard />} />
            <Route path='/explore' element={<Explore />} />
            <Route path='/climate-smart-events' element={<ClimateSmartEvents />} />
            <Route path='/pixstack-journey' element={<Explore />} />
            <Route path='/events/:id' element={<EventDetails />} />
            <Route path='/data-pipeline'>
              <Route index element={<DataPipeline />} />
              <Route path='tasks'>
                <Route index element={<Tasks />} />
                <Route path=':id' element={<Task />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default App