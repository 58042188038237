import PageLayout from 'layout/PageLayout'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const DataPipeline = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/data-pipeline/tasks')
  }, [])
  return (
    <PageLayout>
      Data Pipeline
    </PageLayout>
  )
}

export default DataPipeline