import { Box, Paper } from '@mui/material'
import { Divider } from 'antd'
import Map from 'components/Map'
import React, { useEffect, useState } from 'react'
import * as d3 from "d3-scale-chromatic"
import GaugeChart from 'components/GuageChart'


const JourneyReport = ({ reportData }) => {

  const [tableData, setTableData] = useState([])
  const [tempTableData, setTempTableData] = useState([])

  useEffect(() => {
    let data = {
      sustainability: {},
      impact_analysis: {}
    }
    reportData?.sustainability?.map((layer) => {
      layer?.summary_stats?.features?.map((sustainability_feature) => {
        data['sustainability'][sustainability_feature.properties.sdtname] = {
          min: sustainability_feature.properties.statistics.b1.min,
          mean: sustainability_feature.properties.statistics.b1.mean,
          max: sustainability_feature.properties.statistics.b1.max
        }
      })
    })
    reportData?.impact_analysis?.map((layer) => {
      layer?.summary_stats?.features?.map((impact_analysis_feature) => {
        data['impact_analysis'][impact_analysis_feature.properties.sdtname] = {
          min: impact_analysis_feature.properties.statistics.b1.min,
          mean: impact_analysis_feature.properties.statistics.b1.mean,
          max: impact_analysis_feature.properties.statistics.b1.max
        }
      })
    })

    let dt = Object.keys(data.sustainability)?.map((key) => {
      return {
        location: key,
        sustainability: {
          min: data.sustainability[key].min?.toFixed(6),
          mean: data.sustainability[key].mean?.toFixed(6),
          max: data.sustainability[key].max?.toFixed(6),
        },
        impact_analysis: {
          min: data.impact_analysis[key].min?.toFixed(6),
          mean: data.impact_analysis[key].mean?.toFixed(6),
          max: data.impact_analysis[key].max?.toFixed(6),
        }
      }
    })

    setTempTableData(data)
    setTableData(dt)

  }, [reportData])


  return (
    <Box className="w-full h-full">
      <Box className="w-full h-full flex justify-between space-x-4">
        <Box className="w-[40%] h-full p-2">
          <Box className="w-full flex">
            <p className="w-full text-sm px-3 py-1 border">State</p><p className="w-full text-sm px-3 py-1 border">{reportData?.state}</p>
          </Box>
          <Box className="w-full flex">
            <p className="w-full text-sm px-3 py-1 border">District</p><p className="w-full text-sm px-3 py-1 border">{reportData?.district}</p>
          </Box>
          <Box className="w-full flex">
            <p className="w-full text-sm px-3 py-1 border">Context Variable</p><p className="w-full text-sm px-3 py-1 border">{reportData?.contextVariable}</p>
          </Box>
        </Box>
        <Paper className="w-[60%] h-[300px]">
          <Map showControls={false} geoJson={reportData?.geometry} className={"rounded"} />
        </Paper>
      </Box>

      <Divider orientation='left' orientationMargin={0}>
        <p className='text-lg font-bold'>Sustainability</p>
      </Divider>

      <Box className="w-full h-full flex justify-between gap-8 px-4 py-2">
        {
          reportData?.sustainability?.map((layer, index) => {
            if (!layer?.average_score) return null
            return (
              <Paper key={index} className="w-full h-full p-4">
                <Box className="h-full w-full flex items-center justify-center align-middle">
                  <Box className="h-full w-full ">
                    {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 &&
                      <Box className=" flex flex-col justify-center items-center align-middle">
                        <Box className="w-full !p-4 scale-90 flex justify-center items-center align-middle">
                          <GaugeChart min={layer?.stats?.b1?.min} max={layer?.stats?.b1?.max} value={layer?.average_score} colors={[...layer?.stats?.b1?.histogram[1]]?.map((color) => (d3.interpolateRdYlGn((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min))))} />
                        </Box>
                        <p className="text-gray-400 text-center leading-[18px]">{layer?.layer_name} Score</p>
                      </Box>
                    }
                  </Box>
                  <Box className="flex justify-between w-[30%] h-[210px] items-center">
                    <Box className="w-full h-full flex flex-col justify-between ">
                      {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)].reverse()?.map((color, index) => (
                        <div key={index} className="w-full flex flex-col justify-centertext border border-white">
                          {color}
                        </div>
                      ))}
                    </Box>
                    <Box className="w-full h-full flex flex-col justify-between ">
                      {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].reverse()?.map((color, index) => (
                        <div
                          key={index}
                          className='w-[30px] h-[30px]'
                          style={{
                            backgroundColor: d3.interpolateRdYlGn((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Paper>
            )
          })
        }
      </Box>

      <Divider orientation='left' orientationMargin={0}>
        <p className='text-lg font-bold'>Hazard</p>
      </Divider>

      <Box className="w-full h-full flex justify-between gap-8 px-4 py-2">
        {
          reportData?.impact_analysis?.map((layer, index) => {
            if (!layer?.average_score) return null
            return (
              <Paper key={index} className="w-full p-4">
                <Box className="h-full w-full flex items-center justify-center align-middle">
                  <Box className="h-full w-full ">
                    {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 &&
                      <Box className=" flex flex-col justify-center items-center align-middle">
                        <Box className="w-full !p-4 scale-90 flex justify-center items-center align-middle">
                          <GaugeChart min={layer?.stats?.b1?.min} max={layer?.stats?.b1?.max} value={layer?.average_score} colors={[...layer?.stats?.b1?.histogram[1]]?.map((color) => (d3.interpolateRdYlGn((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min))))} />
                        </Box>
                        <p className="text-gray-400 text-center leading-[18px]">{layer?.layer_name} Score</p>
                      </Box>
                    }
                  </Box>
                  <Box className="flex justify-between w-[30%] h-[210px] items-center">
                    <Box className="w-full h-full flex flex-col justify-between ">
                      {[layer?.stats?.b1?.min?.toFixed(2), layer?.stats?.b1?.mean?.toFixed(2), layer?.stats?.b1?.max?.toFixed(2)].reverse()?.map((color, index) => (
                        <div key={index} className="w-full flex flex-col justify-centertext border border-white">
                          {color}
                        </div>
                      ))}
                    </Box>
                    <Box className="w-full h-full flex flex-col justify-between ">
                      {layer?.stats?.b1?.valid_pixels !== 0 && layer?.stats?.b1?.histogram && layer?.stats?.b1?.histogram?.length === 2 && [...layer?.stats?.b1?.histogram[1]].reverse()?.map((color, index) => (
                        <div
                          key={index}
                          className='w-[30px] h-[30px]'
                          style={{
                            backgroundColor: d3.interpolateYlOrRd((color - layer?.stats?.b1?.min) / (layer?.stats?.b1?.max - layer?.stats?.b1?.min)),
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Paper>
            )
          })
        }
      </Box>

      <Divider orientation='left' orientationMargin={0}>
        <p className='text-lg font-bold'>Administrative Analytics</p>
      </Divider>

      <Box className="w-full h-full ">
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700 table-responsive table">
                  <thead>
                    <tr>
                      <th scope="col" className="px-6 py-3 text-centre text-xs font-medium text-gray-500 uppercase border" rowSpan="2">Location</th>
                      <th scope="col" className="px-6 py-3 text-centre text-xs font-medium text-gray-500 uppercase border" colSpan="3">Sustainability</th>
                      <th scope="col" className="px-6 py-3 text-centre text-xs font-medium text-gray-500 uppercase border" colSpan="3">Hazard</th>
                    </tr>
                    <tr className=''>

                      <th scope="col" className="p-1 text-centre text-xs font-medium text-gray-500 uppercase border">Min</th>
                      <th scope="col" className="p-1 text-centre text-xs font-medium text-gray-500 uppercase border">Mean</th>
                      <th scope="col" className="p-1 text-centre text-xs font-medium text-gray-500 uppercase border">Max</th>

                      <th scope="col" className="p-1 text-centre text-xs font-medium text-gray-500 uppercase border">Min</th>
                      <th scope="col" className="p-1 text-centre text-xs font-medium text-gray-500 uppercase border">Mean</th>
                      <th scope="col" className="p-1 text-centre text-xs font-medium text-gray-500 uppercase border">Max</th>
                    </tr>
                  </thead>
                  <tbody className="divide divide-gray-200 dark:divide-gray-700">
                    {
                      tableData?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="px-2 py-4 text-center whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200 border">{item.location}</td>
                            <td className="px-2 py-4 text-center whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 border">{item.sustainability.min}</td>
                            <td className="px-2 py-4 text-center whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 border">{item.sustainability.mean}</td>
                            <td className="px-2 py-4 text-center whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 border">{item.sustainability.max}</td>
                            <td className="px-2 py-4 text-center whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 border">{item.impact_analysis.min}</td>
                            <td className="px-2 py-4 text-center whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 border">{item.impact_analysis.mean}</td>
                            <td className="px-2 py-4 text-center whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 border">{item.impact_analysis.max}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </Box>
    </Box>
  )
}

export default JourneyReport