import { useSelector } from 'react-redux'
import { InsightsRounded, LocationOn, NavigateBeforeRounded, NavigateNextRounded } from '@mui/icons-material'
import {
  Button,
  Box,
  Divider,
  Typography,
} from '@mui/material'

import MapDashboard from 'components/MapDashboard'
import {
  selectCurrentSection,
  selectSections,
  selectJourneyMapGeoJson,
  selectJourneyMapIsLoading,
  selectJourneyInputData,
  selectCogList,
  toggleEventsListView,
  selectIsEventsListViewOpen,
  resetJourney,
  setCurrentSection,
  closeEventsListView,
  openEventsListView,
  setDemoJourney,
  selectJourneyMapBounds
} from './pixStackJourneySlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'


const PixStackJourney = ({
  children,
  onNext = () => { },
  onPrev = () => { },
  isNextLoading = false
}) => {

  const currentSection = useSelector(selectCurrentSection)
  const sections = useSelector(selectSections)
  const savedJourneyInputData = useSelector(selectJourneyInputData)

  const selectedState = savedJourneyInputData?.location?.stateDistrictDropdowns?.selectedState
  const selectedDistrict = savedJourneyInputData?.location?.stateDistrictDropdowns?.selectedDistrict
  const selectedContextVariable = savedJourneyInputData?.contextVariable

  const geoJson = useSelector(selectJourneyMapGeoJson)
  const journeyMapBounds = useSelector(selectJourneyMapBounds)
  const isMapLoading = useSelector(selectJourneyMapIsLoading)
  const CogList = useSelector(selectCogList)
  const dispatch = useDispatch()

  const isEventsListViewOpen = useSelector(selectIsEventsListViewOpen)

  return (
    <MapDashboard
      geoJson={geoJson}
      isMapLoading={isMapLoading}
      rasterList={CogList}
      initialBounds={journeyMapBounds}
    >
      <Box className="w-full h-[10%] flex flex-col justify-end px-5 pt-[4%]">
        <Box className="w-full h-full flex justify-between align-middle items-center">
          <Box className="w-full h-full flex flex-col justify-end">
            <Typography className='text-gray-700 lg:!text-[20px] !font-semibold !leading-[15px] py-1'>{currentSection?.title}</Typography>
            {
              currentSection.id !== 0 ? (
                <Typography className='text-gray-500 !text-[10px] lg:!text-[12px] !font-medium'>
                  <LocationOn className='!text-[12px] -mt-1' /> {selectedState.stateName}, {selectedDistrict.districtName}
                  &nbsp;
                  <InsightsRounded className='!text-[12px] text-gray-600 -mt-1' /> {selectedContextVariable.name}
                </Typography>
              ) : (
                <Typography className='text-gray-500 !text-[12px] !font-medium'>
                  <LocationOn className='!text-[12px] -mt-1' /> Select a location and start Exploring
                </Typography>
              )
            }
          </Box>
          {
            (currentSection.id === 0 || isEventsListViewOpen) ? (
              
              <Button variant='outlined' className='!h-fit' onClick={() => {
                if (isEventsListViewOpen){
                  dispatch(resetJourney())
                  dispatch(setCurrentSection(sections[0]))
                  dispatch(closeEventsListView())
                }else{
                  dispatch(openEventsListView())
                  // dispatch(setDemoJourney())
                }
              }}>
                {isEventsListViewOpen ? "New" : "Tasks"}
              </Button>
            ) : null
          }
        </Box>
      </Box>
      <Divider variant="fullWidth" flexItem />

      <Box className="w-full h-[80%] overflow-auto scroll-bar-hidden">
        {children}
      </Box>

      <Box className="w-full h-[10%] flex justify-evenly items-center border">
        <Button
          disabled={currentSection?.id === 0}
          disableElevation
          variant='outlined'
          size='large'
          onClick={onPrev}
          className='!px-8 !py-2.5 !w-[40%] !h-fit'
        >
          <NavigateBeforeRounded fontSize='medium' className='-mt-[3px]' /> Back
        </Button>

        <Button
          disableElevation
          variant='contained'
          size='large'
          onClick={onNext}
          className='!px-8 !py-2.5 !w-[40%] truncate'
        >
          {currentSection?.id === 0 ? "Start" : ((currentSection?.id === sections?.length - 1) ? "Start Again" : "Next")}
          <Box>
            {isNextLoading ?
              (
                <div className="!-mr-3 -mt-[3px] LoadingDotContainer">
                  <div className="LoadingDotOne"></div>
                  <div className="LoadingDotTwo"></div>
                  <div className="LoadingDotThree"></div>
                </div>
              ) : <NavigateNextRounded fontSize='medium' className='-mt-[3px]' />}
          </Box>
        </Button>
      </Box>
    </MapDashboard>
  )
}

export default PixStackJourney