import { Suspense } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { AuthProvider } from 'react-oidc-context';

import App from 'App'
import { store } from 'services/redux/store'
import AntDProvider from 'services/context/antd/AntDProvider'
import MuiProvider from 'services/context/mui/MuiProvider'
import { OIDC_AUTH_SERVER_URL, OIDC_CLIENT_ID } from 'config';


const AppContainer = () => {

  const onSigninCallback = (_user) => {
    window.history.replaceState( {}, document.title, window.location.pathname )
  }

  const oidcConfig = {
    authority: OIDC_AUTH_SERVER_URL,
    client_id: OIDC_CLIENT_ID,
    redirect_uri: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/`,
    onSigninCallback: async (_user) => {
      window.history.replaceState( {}, document.title, window.location.pathname )
    }
  };

  return (
    <ReduxProvider store={store}>
      <AuthProvider {...oidcConfig}>
        <AntDProvider>
          <MuiProvider>
            <Suspense fallback={<div>loading ...</div>}>
              <App />
            </Suspense>
          </MuiProvider>
        </AntDProvider>
      </AuthProvider>
    </ReduxProvider>
  )
}

export default AppContainer