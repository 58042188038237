import React, { useRef, useEffect } from "react";
import logo from "assets/images/logo/LC_PIXSTACK_LOGO.png";
import Wlogo from "assets/images/logo/White_PIXSTACK_LOGO.png";
import RightImage from "assets/images/bg/homebg2-mobile.jpg";
import MapLoader from "components/Loader/loader";

const HeroBanner = () => {
  // const videoRef = useRef(null);

  // useEffect(() => {
  //   const video = videoRef.current;

  //   const handleVideoEnd = () => {
  //     // Restart the video when it ends
  //     video.currentTime = 0;
  //     video.play();
  //   };

  //   video.addEventListener("ended", handleVideoEnd);

  //   // Cleanup event listener when component unmounts
  //   return () => {
  //     video.removeEventListener("ended", handleVideoEnd);
  //   };
  // }, []);
  return (
    <div
      className="relative isolate px-6 pt-14 lg:px-8 overflow-hidden"
      // style={{ background: `url(${RightImage})` }}
    >
      <div
        className="absolute inset-x-0 -top-50 z-[1] transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="">
        <div className=" w-[70%] max-sm:w-full  max-w-2xl py-15 sm:py-10 lg:py-15 z-[99] text-shdw">
          <div className="hidden sm:mb-8 sm:flex sm:justify-left">
            <div className="relative text-left rounded-full px-3 text-sm leading-5 text-black-800 ">
              <img src={Wlogo} className="w-[32%]"></img>
              {/* <a href="#" className="font-semibold text-indigo-600">
            <span className="absolute inset-0" aria-hidden="true" />
            Read more <span aria-hidden="true">&rarr;</span>
          </a> */}
          {/* <div></div> */}
            </div>
          </div>
          <div className="text-left">
            {/* <h1 className="text-[55px] font-bold tracking-tight text-white">
              <p className="text-[20px]">A Causal and Actionable AI platform for</p> <br /> Resilience <mark className="">&</mark>{" "}
              Sustainability
            </h1> */}
            <p className="text-[55px] max-xl:text-[78px] font-bold tracking-tight text-white">A Causal and Actionable <br/> AI platform for Resilience <mark className="">& </mark>Sustainability</p>
            {/* <b className=" text-white">A Causal and Actionable AI platform for Resilience & Sustainability</b> */}
            {/* <p className="mt-2 text-l-white">
            Stack of Pixels where each pixel is an insight derived from climatic, ecological and socioeconomic-cognitive events for forecasting cumulative impact at user level.
            </p> */}
            <div className="mt-10 flex items-left justify-left gap-x-6">
              <a
                href="/dashboard"
                type="button"
                className="mr-auto hover:bg-white hover:text-red-600 bg-red-600 text-white rounded shadow hover:shadow-lg py-2 px-4 border border-red-600 hover:border-transparent h5"
              >
                Explore Now
              </a>
            </div>
          </div>
        </div>
        <div className="w-[30%] max-sm:w-full ">
          <div className="videoSection text-left" style={{background:`url(${RightImage})`}}>
            {/* <img src={RightImage} className="w-full" /> */}
            {/* <video ref={videoRef} autoPlay loop>
             <source
              src="https://assets-global.website-files.com/6072644b22bcca3aacb3fc07/635043d0a9c098b28e218cc4_header_1-transcode.webm"
              type="video/mp4"
            />
          </video> */}
          </div>
        </div>
      </div>

      {/* <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div> */}
    </div>
  );
};

export default HeroBanner;
