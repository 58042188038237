import { Box, IconButton } from '@mui/material'
import { ArrowBackRounded, Menu } from '@mui/icons-material'

import { useDispatch, useSelector } from 'react-redux'
import { closeMenu, openMenu, selectMenuOpen } from './mapDashboardSlice'


const MenuOpen = ({ children, className }) => {

  const dispatch = useDispatch()

  return (
    <Box className={`w-full h-full flex ${className}`}>
      <Box className="w-full md:w-fit lg:w-fit h-full bg-gray-100">
        {children}
      </Box>
      <Box className="p-2 pl-0 h-fit">
        <IconButton
          centerRipple={false}
          onClick={() => dispatch(closeMenu())}
          className="!text-black h-fit !rounded-none !bg-primary-950 w-fit "
        >
          <ArrowBackRounded fontSize="small" className="text-secondary-400" />
        </IconButton>
      </Box>
    </Box>
  )
}

const MenuClosed = ({ className }) => {

  const dispatch = useDispatch()

  return (
    <Box className={`bg-gray-950 p-2 bg-opacity-50 duration-700 ease-in-out ${className}`}>
      <IconButton
        centerRipple={false}
        onClick={() => dispatch(openMenu())}
        className="!text-black h-fit !rounded !bg-primary-950 w-fit"
      >
        <Menu fontSize="small" className="text-secondary-400" />
      </IconButton>
    </Box>
  )
}

const MapSider = ({ children, className }) => {

  const menuOpen = useSelector(selectMenuOpen)

  return (
    <Box className={`${className}`}>
      <MenuOpen className={`w-full h-full absolute z-[460] ${menuOpen ? "left-0" : "-left-[150rem]"} duration-500 ease-in-out`} >
        {children}
      </MenuOpen>
      <MenuClosed className={`w-fit h-full absolute z-[450] ${!menuOpen ? "left-0" : "-left-[150rem]"} duration-500 ease-in-out`} />
    </Box>
  )
}

export default MapSider