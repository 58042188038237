
import { Switch } from 'antd';
import {
  Divider,
  IconButton,
  Accordion, AccordionDetails, AccordionSummary, Paper,
} from '@mui/material'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CrisisAlertRoundedIcon from '@mui/icons-material/CrisisAlertRounded';
import AllInclusiveRoundedIcon from '@mui/icons-material/AllInclusiveRounded';

import { useDispatch, useSelector } from 'react-redux';
import {
  addEventToMap,
  removeEventFromMap,
  selectEventsAddedToMap,
  setSelectedEvent
} from './eventSlice';

const EventCard = ({
  className,
  event = {
    name: "",
    date: "",
    thumbnailUrl: "",
  },
}) => {

  const dispatch = useDispatch()

  const eventsAddedToMap = useSelector(selectEventsAddedToMap)
  const eventAddedToMap = eventsAddedToMap.some(obj => JSON.stringify(obj) === JSON.stringify(event));

  const handleAddToMap = (checked) => {
    if (checked) {
      if (eventAddedToMap) return
      dispatch(addEventToMap(event))

    } else {
      if (!eventAddedToMap) return
      dispatch(removeEventFromMap(event))
    }
  }

  const iconButtonStyleClasses = "!rounded !text-base-950 hover:!bg-transparent flex flex-col gap-1"

  return (
    <Paper elevation={1} className='w-[300px] lg:w-full border-t border-gray-200 bg-white flex flex-col gap-3 p-3'>
      <div className="relative flex w-full justify-between">
        <div className='flex gap-3 w-[90%]'>
          <div className="w-[35%] h-16 rounded-sm overflow-hidden border border-gray-300">
            <img
              src={event.thumbnailUrl} alt="" loading="lazy"
              className="relative bg-white bg-cover w-full h-full"
            />
          </div>
          <div className='lg:w-[60%] flex flex-col gap-1'>
            <p className="relative text-sm font-medium text-gray-700 break-words overflow-hidden leading-[18px]">{event.name}</p>
            <p className="text-gray-400 text-xs leading-[18px]">{event.date}</p>
          </div>
        </div>
        <div className='flex justify-center w-[10%]'>
          <Switch
            size='small' className='bg-gray-500'
            onChange={(checked) => handleAddToMap(checked)}
            checked={eventAddedToMap}
          />
        </div>
      </div>
      <Divider className="!border-dashed" />
      <div className="flex justify-around h-12">

        <IconButton
          href={`/events/${event.name}`}
          centerRipple={false}
          onClick={() => dispatch(setSelectedEvent({ ...event, tabId: 'Summery' }))}
          className={`${iconButtonStyleClasses}`}
        >
          <InfoOutlinedIcon fontSize="small" className="" />
          <p className='text-xs text-gray-400'>Info</p>
        </IconButton>

        <IconButton
          href={`/events/${event.name}`}
          centerRipple={false}
          onClick={() => dispatch(setSelectedEvent({ ...event, tabId: 'Risk' }))}
          className={`${iconButtonStyleClasses}`}
        >
          <WarningRoundedIcon fontSize="small" className="" />
          <p className='text-xs text-gray-400'>Risk</p>
        </IconButton>

        <IconButton
          href={`/events/${event.name}`}
          centerRipple={false}
          onClick={() => dispatch(setSelectedEvent({ ...event, tabId: 'Impact' }))}
          className={`${iconButtonStyleClasses}`}
        >
          <CrisisAlertRoundedIcon fontSize="small" className="" />
          <p className='text-xs text-gray-400'>Impact</p>
        </IconButton>

        <IconButton
          href={`/events/${event.name}`}
          centerRipple={false}
          onClick={() => dispatch(setSelectedEvent({ ...event, tabId: 'Sustainability' }))}
          className={`${iconButtonStyleClasses}`}
        >
          <AllInclusiveRoundedIcon fontSize="small" className="" />
          <p className='text-xs text-gray-400'>Sustainability</p>
        </IconButton>

      </div>
    </Paper>
  )

  return (
    <Paper elevation={1} className='border-t border-gray-200 bg-white flex flex-col gap-3 p-3'>
      <div className="relative flex w-full justify-between gap-5">
        <div className='flex gap-3 w-[90%]'>
          <div className="w-[35%] h-16 rounded-sm overflow-hidden border border-gray-300">
            <img
              src={event.thumbnailUrl} alt="" loading="lazy"
              className="relative bg-white bg-cover w-full h-full"
            />
          </div>
          <div className='lg:w-[60%] flex flex-col gap-1'>
            <p className="relative text-sm font-medium text-gray-700 break-words overflow-hidden leading-[18px]">{event.name}</p>
            <p className="text-gray-400 text-xs leading-[18px]">{event.date}</p>
          </div>
        </div>
        <div className='flex justify-center w-[10%]'>
          <Switch
            size='small' className='bg-gray-500'
            onChange={(checked) => handleAddToMap(checked)}
            checked={eventAddedToMap}
          />
        </div>
      </div>
      <Divider className=" !border-dashed" />
      <div className=" flex justify-around">

        <IconButton
          centerRipple={false}
          onClick={() => dispatch(setSelectedEvent({ event: event, tabId: 'Summery' }))}
          className={`!rounded hover:!bg-base-100 !text-base-950 flex flex-col gap-1`}
        >
          <InfoOutlinedIcon fontSize="small" className="" />
          <p className='text-xs text-base-950'>Info</p>
        </IconButton>

        <IconButton
          centerRipple={false}
          onClick={() => dispatch(setSelectedEvent({ event: event, tabId: 'Risk' }))}
          className={`!rounded hover:!bg-base-100 !text-base-950 flex flex-col gap-1`}
        >
          <WarningRoundedIcon fontSize="small" className="" />
          <p className='text-xs text-base-950'>Risk</p>
        </IconButton>

        <IconButton
          centerRipple={false}
          onClick={() => dispatch(setSelectedEvent({ event: event, tabId: 'Impact' }))}
          className={`!rounded hover:!bg-base-100 !text-base-950 flex flex-col gap-1`}
        >
          <CrisisAlertRoundedIcon fontSize="small" className="" />
          <p className='text-xs text-base-950'>Impact</p>
        </IconButton>

        <IconButton
          centerRipple={false}
          onClick={() => dispatch(setSelectedEvent({ event: event, tabId: 'Sustainability' }))}
          className={`!rounded hover:!bg-base-100 !text-base-950 flex flex-col gap-1`}
        >
          <AllInclusiveRoundedIcon fontSize="small" className="" />
          <p className='text-xs text-base-950'>Sustainability</p>
        </IconButton>

      </div>
    </Paper>
  )

  return (
    <Accordion className={`!border-[3px] !border-b-0 !border-gray-200  ${className}`}>
      <AccordionSummary>
        <div className="relative flex w-full justify-between gap-5">
          <div className='flex gap-3 w-[90%]'>
            <div className="w-[40%] h-20  rounded-md overflow-hidden border border-gray-300">
              <img
                src={event.thumbnailUrl} alt="" loading="lazy"
                className="relative bg-white rounded-md bg-cover w-full h-full"
              />
            </div>
            <div className='lg:w-[60%] flex flex-col gap-1'>
              <p className="relative text-sm font-medium text-gray-700 break-words overflow-hidden leading-[18px]">{event.name}</p>
              <p className="text-gray-400 text-xs leading-[18px]">{event.date}</p>
            </div>
          </div>
          <div className='flex justify-center w-[10%]'>
            <Switch
              size='small' className='bg-gray-600'
              onChange={(checked) => handleAddToMap(checked)}
              checked={eventAddedToMap}
            />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Divider className="!mb-4 !-mt-2" />
        <div className=" flex justify-around">

          <IconButton
            centerRipple={false}
            onClick={() => dispatch(setSelectedEvent({ ...event, tabId: 'Summery' }))}
            className={`!rounded hover:!bg-base-100 !text-base-950 flex flex-col gap-1`}
          >
            <InfoOutlinedIcon fontSize="small" className="" />
            <p className='text-xs text-base-950'>Info</p>
          </IconButton>

          <IconButton
            centerRipple={false}
            onClick={() => dispatch(setSelectedEvent({ ...event, tabId: 'Risk' }))}
            className={`!rounded hover:!bg-base-100 !text-base-950 flex flex-col gap-1`}
          >
            <WarningRoundedIcon fontSize="small" className="" />
            <p className='text-xs text-base-950'>Risk</p>
          </IconButton>

          <IconButton
            centerRipple={false}
            onClick={() => dispatch(setSelectedEvent({ ...event, tabId: 'Impact' }))}
            className={`!rounded hover:!bg-base-100 !text-base-950 flex flex-col gap-1`}
          >
            <CrisisAlertRoundedIcon fontSize="small" className="" />
            <p className='text-xs text-base-950'>Impact</p>
          </IconButton>

          <IconButton
            centerRipple={false}
            onClick={() => dispatch(setSelectedEvent({ ...event, tabId: 'Sustainability' }))}
            className={`!rounded hover:!bg-base-100 !text-base-950 flex flex-col gap-1`}
          >
            <AllInclusiveRoundedIcon fontSize="small" className="" />
            <p className='text-xs text-base-950'>Sustainability</p>
          </IconButton>

        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default EventCard