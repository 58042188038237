import MapDashboard from 'components/MapDashboard'
import EventInfo from 'components/MapDashboard/Events/EventInfo'
import React from 'react'

const EventDetails = () => {
  return (
    <MapDashboard>
      <EventInfo />
    </MapDashboard>
  )
}

export default EventDetails