import React, { useEffect, useState } from 'react'
import TasksTable from './TasksTable'
import { Box, Button, Card, CardContent, CircularProgress, Divider } from '@mui/material'
import { useGetListOfTasksQuery } from 'services/api/tasksApiSlice'
import { AddRounded, RefreshRounded } from '@mui/icons-material'
import CreateTask from './CreateTask'
import Drawer from 'components/ui/Drawer'

const TasksList = () => {

  const {
    data: tasksData,
    isLoading: isTasksDataLoading,
    isFetching: isTaskDataFetching,
    // isSuccess: isTasksDataSuccess,
    // isError: isTasksDataError,
    refetch: taskListRefetch,
    // error: taskDataError
  } = useGetListOfTasksQuery()


  const [tasks, setTasks] = useState([])

  useEffect(() => {
    if (tasksData && tasksData.data && tasksData.data.length > 0) {
      setTasks(tasksData && tasksData.data)
    }
  }, [tasksData])

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)


  return (
    <Box className="h-full w-full space-y-5">
      <Drawer
        title="Create a task"
        width={400}
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
      >
        <CreateTask />
      </Drawer>

      <Card className='w-full h-full'>
        <CardContent className='w-full h-full space-y-3'>
          <Box className="flex justify-end h-[40px] space-x-4">
            <Button size='small' onClick={() => taskListRefetch()} variant='outlined' className='!rounded-sm !h-fit !px-6 !py-2'>
              {isTaskDataFetching ? <CircularProgress className='!text-primary-700' size={"14px"} sx={{ margin: "3px" }} /> : <RefreshRounded fontSize='small' />}
            </Button>
            <Button size='small' disableElevation onClick={() => setIsDrawerOpen(true)} variant='contained' className='!rounded-sm !h-fit !px-6 !py-2'>
              <AddRounded fontSize='small' />&nbsp; New Task
            </Button>
          </Box>
          <Divider />
          <TasksTable tasks={tasks} />
        </CardContent>
      </Card>
    </Box>
  )
}

export default TasksList