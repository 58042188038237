import React from 'react'
import { Box } from '@mui/material'
import HeaderLinks from './HeaderLinks'
import HeaderProfile from './HeaderProfile'

const HeaderLinksSection = () => {
  return (
    <Box className="flex justify-center items-center md:space-x-12 lg:space-x-8">
      <HeaderLinks />
      <HeaderProfile />
    </Box>
  )
}

export default HeaderLinksSection