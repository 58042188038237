import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_API_URL } from 'config'

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  // credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().auth?.accessToken
    headers.set("authorization", `Bearer ${accessToken}`)
    return headers
  }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({})
})