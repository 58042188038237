import React from 'react'
import { Layout } from 'antd'

const Content = ({children, className, style}) => {
  return (
    <Layout.Content className={`overflow-auto h-full ${className}`} style={style}>
      {children}
    </Layout.Content>
  )
}

export default Content