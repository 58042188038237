import React from 'react'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import CustomColorConfig from '../theme/colors';

const colors = CustomColorConfig.colors

const MuiProvider = ({ children }) => {
  let theme = createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          autoCapitalize: "false",

        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        variants: [
          {
            props: { variant: 'text' },
            style: {
              color: colors.primary[700],
              ":hover": {
                background: colors.primary[50],
              },
              ":active": {
                background: colors.primary[100],
              }
            }
          },
          {
            props: { variant: 'outlined' },
            style: {
              color: colors.primary[700],
              border: `1px solid ${colors.primary[700]}`,
              ":hover": {
                background: colors.primary[50],
                border: `1px solid ${colors.primary[700]}`,
              },
              ":active": {
                background: colors.primary[100],
                border: `1px solid ${colors.primary[700]}`,
              }
            }
          },
          {
            props: { variant: 'contained' },
            style: {
              background: colors.primary[600],
              // padding: "10px 24px",
              ":hover": {
                background: colors.primary[700],
              },
              ":active": {
                background: colors.primary[800],
              }
            }
          }
        ]
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: false,
          centerRipple: false,
        },
        variants: [
          {
            props: { variant: 'text' },
            style: {
              color: colors.primary[700],
              ":hover": {
                background: colors.primary[50],
              },
              ":active": {
                background: colors.primary[100],
              }
            }
          },
          {
            props: { variant: 'outlined' },
            style: {
              color: colors.primary[700],
              border: `1px solid ${colors.primary[700]}`,
              ":hover": {
                background: colors.primary[50],
                border: `1px solid ${colors.primary[700]}`,
              },
              ":active": {
                background: colors.primary[100],
                border: `1px solid ${colors.primary[700]}`,
              }
            }
          },
          {
            props: { variant: 'contained' },
            style: {
              background: colors.primary[600],
              // padding: "10px 24px",
              ":hover": {
                background: colors.primary[700],
              },
              ":active": {
                background: colors.primary[800],
              }
            }
          }
        ]
      },
      MuiTextField: {
        variants: [
          {
            props: { variant: "outlined" },
            style: {
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#bbb',
                borderWidth: "1px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#bbb',
                borderWidth: "1px"
              },
              '&:hover .MuiInputLabel-root': {
                color: '#bbb',
              },
              '&:hover .MuiInputLabel-root': {
                color: '#bbb',
              },
            }
          }
        ],
        
      },


    },
    typography: {
      button: {
        textTransform: "none",
      }
    },
  })
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default MuiProvider